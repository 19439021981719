import { Input } from "antd";

import { makeHandleFocus } from "../../core/utils";

export default function Email(props) {
  function handleChange(e) {
    props.setValue(e.target.value);
  }
  return (
    <Input
      {...props}
      onFocus={makeHandleFocus(props.isMobile, 2.2)}
      onChange={handleChange}
      inputMode="email"
    />
  );
}
