import { useState } from "react";
import { Typography, Col, Card, Row, Button, Input } from "antd";
import { useOutletContext } from "react-router-dom";
import { COLORS, headers } from "../../../core/constants";
import PhoneCallButton from "../../../components/CustomerComponents/PhoneCallButton";
import DocumentUpload from "../../../components/CustomerComponents/DocumentUpload";
import { DownloadOutlined, CloseOutlined } from "@ant-design/icons";
import {
  finishUpload,
  getSignedUploadLink,
  removeFile,
  getS3FileUrl,
} from "./services";
import lodash from "lodash";
import { useEffect } from "react";
import { Skeleton } from "antd";

const { cloneDeep } = lodash;

const DocumentsView = () => {
  const {
    data,
    isMobile,
    applicationData,
    isSidebarVisible,
    setApplicationData,
  } = useOutletContext();
  const [uploadingFiles, setUploadingFiles] = useState([]);
  useEffect(() => {
    document.title = "LoanTube: Your Documents";
  }, []);
  const documents = [...applicationData?.company?.documents, ...uploadingFiles];

  const putFileToS3 = async (newFile) => {
    const uploadLink = await getSignedUploadLink(
      applicationData?.document_upload_code?.code,
      newFile
    );

    const formData = new FormData();
    formData.append(newFile.name, newFile);

    const myHeaders = new Headers({ "Content-Type": newFile.type, "Content-Disposition" : `inline; filename="${newFile.name}"`});
    const response = await fetch(uploadLink.url, {
      method: "PUT",
      headers: myHeaders,
      body: newFile,
    });
    return [newFile, uploadLink];
  };

  const handleFilesUpload = async (newFiles) => {
    const promises = [];
    setUploadingFiles(
      [...newFiles].map((file) => ({
        name: file.name,
        key: crypto.randomUUID(),
        uploading: true,
      }))
    );

    for (let i = 0; i < newFiles.length; i++) {
      promises.push(putFileToS3(newFiles[i]));
    }

    const uploadedFiles = await Promise.all(promises);

    const newData = await (
      await finishUpload(applicationData, uploadedFiles)
    ).json();

    if (newData) {
      setApplicationData(newData.data);
      setUploadingFiles([]);
    }
  };

  const handleDeleteDocument = async (file) => {
    const newData = await (await removeFile(applicationData, file)).json();
    if (newData) setApplicationData(newData.data);
  };

  const handleDownloadClick = async (file) => {
    const data = await getS3FileUrl(applicationData, file);
    const docs = data?.data;

    if (typeof docs === "object" && Object.hasOwn(docs, file.key)) {
      const url = docs[file.key];
      window.open(url, "_self");
    }
  };

  return (
    <Col
      className="dashboard-layout"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "20px",
        maxWidth: !isMobile && "calc(100% - 290px)",
      }}
    >
      <div>
        <Typography.Title
          style={{
            fontSize: isMobile ? "24px" : "30px",
            marginBottom: isMobile ? "10px" : "0px",
            marginTop: isMobile && "14px",
            paddingLeft: isMobile && "64px",
            opacity: !isSidebarVisible ? "0" : "1",
            transition: "opacity 100ms ease",
          }}
        >
          Your documents
        </Typography.Title>

        <Typography.Title
          style={{
            fontSize: isMobile ? "16px" : "20px",
            marginBottom: isMobile ? "24px" : "74px",
            marginTop: isMobile && "6px",
            paddingLeft: isMobile && "64px",
            opacity: !isSidebarVisible ? "0" : "1",
            transition: "opacity 100ms ease",
            marginTop:"8px"
          }}
        >
          {applicationData.application.company.name}
        </Typography.Title>
        <Row justify={"center"} style={{ marginBottom: "24px" }}>
          <DocumentUpload
            isMobile={isMobile}
            onFilesUpload={handleFilesUpload}
            applicationId={applicationData?.application?.id}
            applicationData={applicationData}
          />
        </Row>
      </div>
      <div>
        <Row style={{ gap: '12px' }}>
          <div
            style={{
              background: isMobile ? "#f5f5f5" : COLORS.offwhite,
              padding: "10px",
              borderRadius: "10px",
              width: isMobile ? 'calc(100% - 20px)' : 'calc(50% - 28px)'
            }}
          >
            <Row style={{ marginBottom: "12px" }}>
              <Typography.Text style={{ fontSize: "18px", fontWeight: 600 }}>
                Documents uploaded:
              </Typography.Text>
            </Row>

            {documents?.length === 0 && (
              <Row style={{ marginBottom: "12px" }}>
                <Typography.Text
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    margin: "20px 8px",
                  }}
                >
                  You have no uploaded documents
                </Typography.Text>
              </Row>
            )}
            {documents?.map((orgFile, index) => {
              //clone so that we don't merge with orignal applicationData
              let file = cloneDeep(orgFile);
              let maxChars = isMobile ? 32 : 85;
              if (file.name.length > maxChars) {
                file.name = file.name.substring(0, maxChars);
                let lastDot = file.name.lastIndexOf(".");

                if (lastDot) {
                  file.name += "..." + file.name.substring(lastDot);
                }
              }
              return (
                <Row
                  style={{ marginBottom: isMobile ? "12px" : "8px" }}
                  align={"middle"}
                  justify={"space-between"}
                  wrap={false}
                  key={file.id}
                >
                  <Typography.Text
                    style={{
                      fontSize: isMobile ? "14px" : "16px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "block",
                      maxWidth: isMobile
                        ? "calc(100% - 60px)"
                        : "calc(100% - 300px)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file.name}
                  </Typography.Text>

                  {file.uploading ? (
                    <Col>
                      <Skeleton.Node
                        style={
                          isMobile
                            ? { height: "16px", width: "44px" }
                            : { height: "43.5px", width: "283.16px" }
                        }
                        active={true}
                      >
                        <div />
                      </Skeleton.Node>
                    </Col>
                  ) : (
                    <Col>
                      <Row
                        align={"middle"}
                        style={{
                          gap: "12px",
                          minWidth: isMobile ? "44px" : "283.16px",
                        }}
                      >
                        {isMobile ? (
                          <>
                            <DownloadOutlined
                              style={{ color: COLORS.red, cursor: "pointer" }}
                              onClick={() => handleDownloadClick(file)}
                            />
                            <CloseOutlined
                              onClick={() => handleDeleteDocument(file)}
                              style={{ cursor: "pointer" }}
                            ></CloseOutlined>
                          </>
                        ) : (
                          <>
                            <Button
                              className="button-with-hover"
                              size="small"
                              style={{
                                padding: "10px 40px",
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 500,
                                borderColor: COLORS.red,
                                color: COLORS.red,
                                borderWidth: 1.5,
                              }}
                              onClick={() => handleDownloadClick(file)}
                            >
                              Download
                            </Button>
                            <div
                              className="delete-text-button"
                              role="button"
                              onClick={() => handleDeleteDocument(file)}
                            >
                              Delete
                            </div>
                          </>
                        )}
                      </Row>
                    </Col>
                  )}
                </Row>
              );
            })}
          </div>
          <div
            style={{
              background: isMobile ? "#f5f5f5" : COLORS.offwhite,
              padding: "10px",
              borderRadius: "10px",
              width: isMobile ? 'calc(100% - 20px)' : 'calc(50% - 28px)'
            }}
          >
            <Row style={{ marginBottom: "12px" }}>
              <Typography.Text style={{ fontSize: "18px", fontWeight: 600 }}>
                Documents Required:
              </Typography.Text>
            </Row>
            <div>
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  margin: "20px 8px 20px 5px",
                }}
              >
                <span>1.</span>{" "}
                Bank statement for last 6 months.
              </Typography.Text>
            </div>

            <div>
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  margin: "20px 8px 20px 5px",
                }}
              >
                <span>2.</span>{" "}
                Last filed Annual Accounts copy.
              </Typography.Text>
            </div>
          </div>
        </Row>
        <Row
          align={"middle"}
          justify={isMobile ? "center" : "space-between"}
          style={{
            border: `1px solid ${COLORS.red}`,
            borderRadius: "10px",
            padding: isMobile ? "12px" : "14px 60px",
            marginTop: "24px",
            gap: isMobile && "16px",
            textAlign: isMobile && "center",
          }}
        >
          <Typography.Text style={{ fontSize: "18px", fontWeight: 600 }}>
            Looking for support? Speak to a loan officer
          </Typography.Text>
          <PhoneCallButton
            size={"40px"}
            borderRadius={"6px"}
            isMobile={isMobile}
          ></PhoneCallButton>
        </Row>
      </div>
    </Col>
  );
};

export default DocumentsView;
