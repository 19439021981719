import Customer from "../../routes/Customer/Customer";
import FormPopup from "./FormPopup";

const CustomerFormPopup = ({
  startFrom,
  finishAt,
  onFinish,
  onClose,
  visible,
  isMobile,
  companyId,
  submitText
}) => {
  return (
    <FormPopup visible={visible} isMobile={isMobile} onClose={onClose}>
      <Customer
        embeded={true}
        startFrom={startFrom}
        finishAt={finishAt}
        onFinish={onFinish}
        onEmbededClose={onClose}
        companyId={companyId}
        submitText={submitText}
      />
    </FormPopup>
  );
};

export default CustomerFormPopup;
