import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";

const FormPopup = ({ children, onClose, persistent, visible, isMobile, desktopOnly, className }) => {
  const handlers = useSwipeable({
    onSwiped: () => null,
    onSwipedDown: () => { 
      isMobile && !persistent &&  onClose()
    },
    preventScrollOnSwipe: !persistent,
    trackMouse: true,
  });

  return (
    <>
      <AnimatePresence>
        {visible && (
          <motion.div className="form-popup-overlay" onClick={() => !persistent && onClose()}>
            <motion.div
              key={isMobile}
              {...handlers}
              onClick={(e) => e.stopPropagation()}
              className={["form-popup", desktopOnly ? 'forceDesktop' : '', className].join(' ')}
              initial={isMobile ? { y: "110%" } : {}}
              animate={isMobile ? { y: "0%" } : {}}
              exit={isMobile ? { y: "110%" } : {}}
              transition={{
                duration: 0.25,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: isMobile ? "0.7em" : "1em",
                  top: isMobile ? "-1.40em" : "1em",
                  cursor: "pointer",
                  zIndex: "10000",
                }}
                role="button"
                onClick={onClose}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1.3em"
                  width="1.3em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke={isMobile ? "#7a7a7a" : "#000"}
                    strokeWidth="2"
                    d="M3,3 L21,21 M3,21 L21,3"
                  ></path>
                </svg>
                {/* <CloseOutlined style={{ fontSize: isMobile ? "20px" : "22px", color: COLORS.darkGrey }} /> */}
              </div>
              <div className="form-popup-mobile-border"></div>
              <div className="form-popup-content">{children}</div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FormPopup;
