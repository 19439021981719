import { COLORS } from "../../core/constants";
import { List } from "antd";

const data = [
  {
    description: (
      <div>
        You confirm you agree to and accept our{" "}
        <a
          href="https://www.loantube.com/terms-and-conditions/"
          target="_blank"
          style={{ color: COLORS.red }}
        >
          Terms and Conditions
        </a>
        ,{" "}
        <a
          href="https://www.loantube.com/privacy-policy/"
          target="_blank"
          style={{ color: COLORS.red }}
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://www.loantube.com/cookies-policy/"
          target="_blank"
          style={{ color: COLORS.red }}
        >
          Cookies Policy
        </a>
        .
      </div>
    ),
  },
  {
    description: (
      <div>
        You consent to receive information from LoanTube & its{" "}
        <a
          href="https://www.loantube.com/our-partners/"
          target="_blank"
          style={{ color: COLORS.red }}
        >
          providers & partners
        </a>{" "}
        about this loan application, your experience with us and about other
        latest products and services via email, SMS, call and post.
      </div>
    ),
  },
  {
    description: (
      <div>
        You confirm you understand that the information you have provided will
        be shared with our{" "}
        <a
          href="https://www.loantube.com/our-partners/"
          target="_blank"
          style={{ color: COLORS.red }}
        >
          partners
        </a>{" "}
        who will use it to assess your loan application. They may share your
        information with credit reference and fraud prevention agencies, and in
        case they spot any fraud you or associated people could be refused
        services, finance or employment. The assessment will also involve a soft
        credit and identity search. This search will not affect your credit
        rating.
      </div>
    ),
  }
];

export default function AcceptTerms({ isMobile, noPadding }) {
  const defaultPadding = isMobile ? "4px 0" : "8px";

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        bordered={false}
        split={false}
        renderItem={(item, index) => (
          <List.Item
            style={{
              padding: noPadding ? "0" : defaultPadding,
              marginBottom: "8px",
            }}
          >
            <List.Item.Meta
              avatar={
                <span
                  style={{
                    color: "#7a7a7a",
                    fontWeight: 500,
                    fontSize: "12px",
                    position: "relative",
                    top: "-4px",
                  }}
                >{`${index + 1}.`}</span>
              }
              description={
                <div
                  style={{
                    fontSize: isMobile
                      ? "clamp(13px, 1.658vh, 14px)"
                      : "clamp(16px, 1.6666vh, 18px)",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  {item.description}
                </div>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}
