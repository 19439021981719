export const externalBaseURL = 'https://bs.loantube.com/embed';
export const affiliateStyleSheetMap = {
  
}
export const  URLS = {
  director: "/api/data/?cid=",
  postcode: "/api/data/?postcode=",
  company: "/api/data/?q=",
  otp_send: "/api/otp/send",
  otp_verify: "/api/otp/verify",
  otp_validate: "/api/otp/validate",
  create_lead: "/api/leads",
};

export const COLORS = {
  red: "#C60029",
  white: "#FFFFFF",
  offwhite: "#FBFBFB",
  grey: "#E6E6E6",
  darkGrey: "#7A7A7A",
  black: "#242424",
};

export const defaultDateFormat = "DD/MM/YYYY";

export const I_AM_NOT_ONE_OF_THESE_PEOPLE = "I am not one of these people";

export const BLOCK_ZOOM = false;

export const isMobileMediaQuery = "(max-width: 68.75em)";

export const THEME = {
  token: {
    fontFamily: "Montserrat",
    fontSize: 16,
    colorBorder: "#242424",
    colorError: COLORS.red,
    colorPrimary: COLORS.red,
    colorPrimaryBorderHover: COLORS.red,
    colorPrimaryHover: COLORS.red,
    colorBgContainerDisabled: COLORS.grey,
    colorTextDisabled: COLORS.white,
    boxShadow: "0px 34px 40px rgba(238, 179, 191, 0.6)",

    controlHeight: 48,
    borderRadius: 12,
    // radioButtonFontWeight: 600,
  },

  Components: {
    Progress: {
      progressStepMinWidth: "1",
    },
    Typography: {
      Title: {},
    },
    Button: {},
    Input: {},
    Select: {
      colorBgElevated: COLORS.offwhite,
      colorTextDescription: COLORS.grey,
    },
    Radio: {
      colorText: COLORS.black,
      colorBgContainer: COLORS.grey,
      colorPrimaryHover: COLORS.red,
      colorPrimaryActive: COLORS.red,
      colorTextDisabled: COLORS.grey,
      radioSolidCheckedColor: COLORS.red,
      lineWidth: "0",
      fontSize: "20px",
      radioButtonFontWeight: 500,
    },
  },
};

export const headers = {
  "x-pass": "sKpBH6HZYx*g!mD*tfa5A27C",
};
