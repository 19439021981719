import { Input } from "antd";
import { COLORS } from "../../core/constants";
import { useState } from "react";

const SuffixedInput = ({
  Suffix,
  style,
  value,
  inputStyle,
  disabled,
  handleClick,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div style={{ position: "relative", style }} onClick={handleClick}>
      <Input
        style={{ paddingRight: "44px", ...inputStyle }}
        disabled={disabled}
        value={value}
      />
      <div
        style={{ position: "absolute", inset: 0, cursor: "pointer" }}
        onClick={handleClick}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      ></div>
      <div className="suffix-container">
        <Suffix
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: hover && COLORS.red,
          }}
        />
      </div>
    </div>
  );
};

export default SuffixedInput;
