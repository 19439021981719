import { Typography, Col, Card, Row, Button } from "antd";
import { COLORS } from "../../core/constants";
import { formatCurrency, formatDate } from "../../core/utils";

import { getLoanPurpose } from "../../core/utils";

import PhoneCallButton from "../../components/CustomerComponents/PhoneCallButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import lodash from "lodash";
import { useEffect } from "react";

const { cloneDeep } = lodash;

const DocumentIndexRenderer = ({ children }) => {
  return (
    <span
      style={{
        borderColor: COLORS.red,
        borderWidth: "2px",
        border: "solid",
        borderRadius: "50%",
        display: "inline-block",
        width: "14px",
        height: "13px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "11px",
        color: COLORS.red,
        position: "relative",
        top: "-2px",
        marginRight: "6px",
      }}
    >
      {children}
    </span>
  );
};

const DashboardView = () => {
  const { data, isMobile, applicationData, isSidebarVisible } =
    useOutletContext();
  const navigate = useNavigate();

  const goToDocuments = () => navigate("/dashboard/your-documents");
  useEffect(() => {
    document.title = "LoanTube: Dashboard";
  }, []);
  return (
    <Col className="dashboard-layout">
      <Typography.Title
        style={{
          fontSize: isMobile ? "24px" : "30px",
          marginBottom: isMobile ? "46px" : "74px",
          marginTop: isMobile && "14px",
          paddingLeft: isMobile && "60px",
          opacity: !isSidebarVisible ? "0" : "1",
          transition: "opacity 100ms ease",
        }}
      >
        Dashboard
      </Typography.Title>
      <Typography.Text
        style={{
          fontSize: isMobile ? "16px" : "20px",
          fontWeight: 500,
          marginBottom: isMobile ? "46px" : "66px",
          display: "block",
          opacity: !isSidebarVisible ? "0" : "1",
          transition: "opacity 100ms ease",
        }}
      >
        Business name:{" "}
        <span style={{ color: COLORS.red, fontWeight: 500 }}>
          {applicationData?.application?.business_name}
        </span>
      </Typography.Text>
      <Row style={{ width: "100%", gap: "30px", alignItems: "stretch" }}>
        <Card
          title="Your recent application details:"
          bordered={false}
          bodyStyle={{
            background: isMobile ? "#f5f5f5" : COLORS.offwhite,
            paddingTop: isMobile ? "0" : "16px",
            paddingLeft: isMobile && "16px",
            paddingRight: isMobile && "16px",
          }}
          style={{
            boxShadow: "none",
            borderRadius: "10px",
            flex: 1,
            minWidth: !isMobile && "380px",
          }}
          headStyle={{
            background: isMobile ? "#f5f5f5" : COLORS.offwhite,
            fontSize: isMobile ? "18px" : "20px",
            paddingLeft: isMobile && "16px",
            paddingRight: isMobile && "16px",
          }}
        >
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ height: "50px", fontSize: isMobile ? "15px" : "16px" }}
          >
            <p className="reset-paragraph">Loan Amount</p>
            <p className="reset-paragraph">
              {formatCurrency(applicationData?.application?.loan_amount)}
            </p>
          </Row>
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ height: "50px", fontSize: isMobile ? "15px" : "16px" }}
          >
            <p className="reset-paragraph">Loan Purpose</p>
            <p className="reset-paragraph">
              {getLoanPurpose(applicationData?.application?.purpose)}
            </p>
          </Row>
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ height: "50px", fontSize: isMobile ? "15px" : "16px" }}
          >
            <p className="reset-paragraph">Application Date</p>
            <p className="reset-paragraph">
              {formatDate(applicationData?.createdAt)}
            </p>
          </Row>
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ height: "50px", fontSize: isMobile ? "15px" : "16px" }}
          >
            <p className="reset-paragraph">Loan Agreement Number</p>
            <p className="reset-paragraph">
              {applicationData?.application?.id}
            </p>
          </Row>
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ height: "50px", fontSize: isMobile ? "15px" : "16px" }}
          >
            <p className="reset-paragraph">Loan Officer Contact No.</p>
            <p className="reset-paragraph">020 8088 5001</p>
          </Row>
          <Row justify={"center"} style={{ marginTop: "37px" }}>
            <PhoneCallButton
              isMobile={isMobile}
              size={"48px"}
              borderRadius={"12px"}
              fontWeight={500}
            />
          </Row>
        </Card>
        <Card
          title="Your documents:"
          bordered={false}
          bodyStyle={{
            background: isMobile ? "#f5f5f5" : COLORS.offwhite,
            flex: !isMobile && "1",
            display: "flex",
            flexDirection: "column",
            paddingTop: isMobile ? "6px" : "18px",
            paddingLeft: isMobile && "16px",
            paddingRight: isMobile && "16px",
          }}
          style={{
            boxShadow: "none",
            borderRadius: "10px",
            minWidth: !isMobile && "380px",
            flex: 1,
            minHeight: "428.25px",
            display: "flex",
            flexDirection: "column",
          }}
          headStyle={{
            background: isMobile ? "#f5f5f5" : COLORS.offwhite,
            fontSize: isMobile ? "18px" : "20px",
            fontSize: isMobile ? "18px" : "20px",
            paddingLeft: isMobile && "16px",
            paddingRight: isMobile && "16px",
          }}
        >
          {applicationData?.company?.documents?.slice(0, 4).map((orgDoc) => {
            let doc = cloneDeep(orgDoc);
            let maxChars = isMobile ? 32 : 85;
            if (doc.name.length > maxChars) {
              doc.name = doc.name.substring(0, maxChars);
              let lastDot = doc.name.lastIndexOf(".");
              if (lastDot) {
                doc.name += "..." + doc.name.substring(lastDot);
              }
            }
            return (
              <Row
                justify={"space-between"}
                align={"middle"}
                style={{
                  marginBottom: "30px",
                  fontSize: isMobile ? "15px" : "16px",
                  width: "100%",
                }}
              >
                <p
                  className="reset-paragraph"
                  style={{
                    position: "relative",
                    flex: 1
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      whiteSpace: "nowrap",
                      maxWidth: "95%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      transform: 'translateY(-50%)'
                    }}
                  >
                    {doc.name}
                  </span>
                </p>
                <Button
                  className="button-with-hover"
                  size="small"
                  style={{
                    padding: isMobile ? "8px 24px" : "10px 40px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                    borderColor: COLORS.red,
                    color: COLORS.red,
                    borderWidth: 1.5,
                    fontSize: isMobile ? "15px" : "16px",
                  }}
                  onClick={goToDocuments}
                >
                  view
                </Button>
              </Row>
            );
          })}
        </Card>
      </Row>
    </Col>
  );
};

export default DashboardView;
