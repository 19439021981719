export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.165 13.6148C19.1632 12.763 19.8763 11.6256 20.2081 10.3559C20.5399 9.08623 20.4745 7.74536 20.0205 6.51406C19.5666 5.28276 18.7461 4.22024 17.6695 3.46975C16.593 2.71926 15.3123 2.31689 14 2.31689C12.6876 2.31689 11.4069 2.71926 10.3304 3.46975C9.25383 4.22024 8.43329 5.28276 7.97937 6.51406C7.52544 7.74536 7.45997 9.08623 7.79176 10.3559C8.12355 11.6256 8.83666 12.763 9.83495 13.6148C7.63026 14.4574 5.73298 15.9489 4.39364 17.8923C3.0543 19.8357 2.33589 22.1396 2.33328 24.4998C2.33328 24.8092 2.4562 25.106 2.67499 25.3248C2.89378 25.5435 3.19053 25.6665 3.49995 25.6665C3.80937 25.6665 4.10611 25.5435 4.32491 25.3248C4.5437 25.106 4.66662 24.8092 4.66662 24.4998C4.66662 22.0244 5.64995 19.6505 7.40029 17.9001C9.15063 16.1498 11.5246 15.1665 14 15.1665C16.4753 15.1665 18.8493 16.1498 20.5996 17.9001C22.35 19.6505 23.3333 22.0244 23.3333 24.4998C23.3333 24.8092 23.4562 25.106 23.675 25.3248C23.8938 25.5435 24.1905 25.6665 24.5 25.6665C24.8094 25.6665 25.1061 25.5435 25.3249 25.3248C25.5437 25.106 25.6666 24.8092 25.6666 24.4998C25.664 22.1396 24.9456 19.8357 23.6063 17.8923C22.2669 15.9489 20.3696 14.4574 18.165 13.6148ZM14 12.8331C13.1923 12.8331 12.4029 12.5936 11.7314 12.145C11.0599 11.6963 10.5365 11.0585 10.2274 10.3124C9.91838 9.56628 9.83752 8.74526 9.99508 7.95317C10.1526 7.16108 10.5415 6.4335 11.1126 5.86244C11.6837 5.29137 12.4112 4.90247 13.2033 4.74492C13.9954 4.58736 14.8164 4.66822 15.5626 4.97728C16.3087 5.28634 16.9464 5.80971 17.3951 6.48121C17.8438 7.15271 18.0833 7.94218 18.0833 8.74979C18.0833 9.83276 17.6531 10.8714 16.8873 11.6371C16.1215 12.4029 15.0829 12.8331 14 12.8331Z"
      fill="currentColor"
    />
  </svg>
);
