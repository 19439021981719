import FormPopup from "../../../../components/CustomerComponents/FormPopup";
import FormPopupLayout from "../../../../components/CustomerComponents/FormPopupLayout";
import { useNavigate } from "react-router-dom";

const FormDocumentsPopup = ({
  isMobile,
  visible,
  onClose,
  documentsRequired,
}) => {
  const navigate = useNavigate();

  return (
    <FormPopup isMobile={isMobile} visible={visible} onClose={onClose}>
      <FormPopupLayout
        onClose={onClose}
        isMobile={isMobile}
        onCancel={() => null}
        submitEnabled={true}
        transparent={true}
        title={"Documents required:"}
        submitText={"Upload documents"}
        hasIcon={false}
        onSubmit={() => {
          onClose();
          navigate("/dashboard/your-documents");
        }}
        hideCancel={true}
        submitStyle={
          isMobile && {
            top: "18px",
          }
        }
        actionsJustify={isMobile && 'center'}
      >
        <div
          style={{
            maxHeight: isMobile && "390px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {documentsRequired?.map((item, index) => (
            <div key={index} style={{ marginBottom: "8px", color: "#777" }}>
              {item}
            </div>
          ))}
        </div>
      </FormPopupLayout>
    </FormPopup>
  );
};

export default FormDocumentsPopup;
