import { useState } from "react";
import { Typography, Row, Input } from "antd";

import FormPopup from "./FormPopup";
import FormPopupLayout from "./FormPopupLayout";
import Label from "../../components/CustomerComponents/Label";

import { COLORS } from "../../core/constants";

const validateEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
};

const SendEmailAddressPopup = ({
  visible,
  isMobile,
  onClose,
  email,
  onSubmit,
}) => {
  const [emails, setEmails] = useState([email || ""]);

  const handleClose = () => {
    setEmails([""]);
    onClose();
  };

  const handleInputChange = (event, index) => {
    setEmails([
      ...emails.slice(0, index),
      event.target.value,
      ...emails.slice(index + 1, emails.length),
    ]);
  };

  const handleAddRemoveEmail = () => {
    if (emails.length === 1) setEmails([...emails, ""]);
    else setEmails([emails[0]]);
  };

  return (
    <FormPopup visible={visible} onClose={onClose} isMobile={isMobile}>
      <FormPopupLayout
        onClose={onClose} 
        isMobile={isMobile}
        onCancel={handleClose}
        submitEnabled={
          Array.isArray(emails) && emails.every((email) => validateEmail(email))
        }
        title={"Upload documents from email"}
        subtitles={[
          "We'll send you an email where you can upload your documents either by replying to that email or by uploading from your device.",
        ]}
        submitText={"Send email"}
        hasIcon={true}
        onSubmit={() => { 
          onSubmit(emails)
          onClose();
        }}
      >
        {emails.map((_, index) => (
          <Row>
            <Label label={"Email"} isMobile={isMobile} />
            <Input
              style={{ width: "100%" }}
              onChange={(e) => handleInputChange(e, index)}
              defaultValue={index === 0 ? email : ""}
            ></Input>
          </Row>
        ))}

        <Typography.Text
          style={{
            fontSize: "14px",
            fontWeight: 500,
            color: COLORS.red,
            cursor: "pointer",
            marginTop: "6.5px",
            display: "inline-block",
          }}
          onClick={handleAddRemoveEmail}
        >
          {emails.length === 1
            ? "+ add email address"
            : "- remove email address"}
        </Typography.Text>
      </FormPopupLayout>
    </FormPopup>
  );
};

export default SendEmailAddressPopup;
