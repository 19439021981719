import { Typography, Col, Row, Input, ConfigProvider, Button } from "antd";
import { COLORS } from "../../../core/constants";
import styles from "../style.module.css";

const Link = ({ children, to }) => (
  <a className={styles.footerLink} href={to} target="_blank">
    {children}
  </a>
);

const Footer = ({ isMobile }) => {
  const gap = "8px";

  return (
    <div
      style={{
        background: "#f5f5f5",
        marginTop: "156px",
        borderTop: `3px solid ${COLORS.darkGrey}`,
      }}
    >
      <div
        style={{
          color: "#34454e",
          width: !isMobile && "80%",
          margin: "0 auto",
          paddingTop: "42px",
          paddingBottom: "6px",
          paddingLeft: isMobile && "16px",
          paddingRight: isMobile && "16px",
          fontSize: "14px",
        }}
      >
        <Typography.Text
          style={{
            display: "block",
            marginBottom: gap,
            color: "#34454e",
            fontSize: "14px",
          }}
        >
          LoanTube is a trading name of Tiger Lion Financial Limited.
        </Typography.Text>
        <Typography.Text
          style={{
            display: "block",
            marginBottom: gap,
            color: "#34454e",
            fontSize: "14px",
          }}
        >
          Tiger Lion Financial Limited is Registered in England & Wales under
          company number:{" "}
          <Link to="https://find-and-update.company-information.service.gov.uk/company/10189367">
            <span style={{ textDecoration: "underline" }}>10189367</span>
          </Link>
          <br></br> Registered Office: 71-75 Shelton Street, Covent Garden,
          London, WC2H 9JQ
        </Typography.Text>
        <Typography.Text style={{ color: "#34454e", fontSize: "14px" }}>
          Tiger Lion Financial Limited is authorised and regulated by Financial
          Conduct Authority (FCA).<br></br>
          FCA Firm Reference Number:{" "}
          <Link to="https://register.fca.org.uk/s/firm?id=001b000003RFLpvAAH">
            <span style={{ textDecoration: "underline" }}>753151</span>
          </Link>
          <br></br> Information Commissioners Office Registration Number:{" "}
          <Link to="https://ico.org.uk/ESDWebPages/Entry/ZA185613">
            <span style={{ textDecoration: "underline" }}>ZA185613</span>
          </Link>
        </Typography.Text>
        <Typography.Text
          style={{
            display: "block",
            fontWeight: "bold",
            margin: `${gap} 0`,
            color: "#34454e",
            fontSize: "14px",
          }}
        >
          LoanTube functions as a credit broker, not a lender.{" "}
        </Typography.Text>
        <Typography.Text style={{ color: "#34454e", fontSize: "14px" }}>
          LoanTube connects applicants with various finance providers based on
          their requirements and circumstances. Our broker service is provided
          to customers without charging a fee; however, we receive a commission
          from lenders for facilitating these connections. With certain lenders,
          we may have the ability to influence the interest rate, potentially
          affecting the total amount payable by you, the customer.
          <Typography.Text
            style={{
              display: "block",
              marginBottom: gap,
              color: "#34454e",
              marginTop: gap,
              fontSize: "14px",
            }}
          >
            Businesses must be based in the UK, and applicants must be aged 18
            and over.
          </Typography.Text>
          Offered quotes are subject to final checks by the lender, and they are
          for illustrative purposes only. Final rates may differ from the quoted
          rates. Personal guarantees and indemnities may also be required.
        </Typography.Text>

        <Typography.Text
          style={{
            display: "block",
            fontWeight: "bold",
            margin: "12px 0",
            color: "#34454e",
            fontSize: "14px",
          }}
        >
          Think carefully before securing debts against your home or assets.
          Your home and assets may be repossessed if you do not keep up
          repayments on any debt secured against them.
        </Typography.Text>
        <div style={{ marginTop: "48px", textAlign: "center" }}>
          <Typography.Text
            style={{ fontSize: "14px", color: "#34454e", fontSize: "14px" }}
          >
            LoanTube ® 2023 All Rights Reserved. {!isMobile && "|"}{" "}
            {isMobile && <br></br>}
            <Link to="https://www.loantube.com/about-us/">About Us</Link> |{" "}
            <Link to="https://www.loantube.com/cookies-policy/">
              Cookies Policy
            </Link>{" "}
            |{" "}
            <Link to="https://www.loantube.com/privacy-policy/">
              Privacy Policy
            </Link>{" "}
            {!isMobile && "|"} {isMobile && <br/>}
            <Link to="https://www.loantube.com/terms-and-conditions/">
              Terms and Conditions
            </Link>{" "}
            |{" "}
            <Link to="https://www.loantube.com/complaints-policy/">
              Complaints Policy
            </Link>{" "}
            {!isMobile && "|"} {isMobile && <br></br>}
            <Link to="https://www.loantube.com/treating-customers-fairly/">
              Treating Customers Fairly
            </Link>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default Footer;
