import { ConfigProvider, Col, Row } from "antd";
import { THEME, isMobileMediaQuery, headers } from "../../../core/constants";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "../../../core/hooks/useMediaQuery";
import MobileNumberStep from "../../../components/MobileNumberStep";
import OTPStep from "../../../components/OTPStep";
import { useEffect, useState } from "react";
import PhoneNumberInfo from "../../../components/CustomerComponents/PhoneNumberInfo";
import styles from "../style.module.css";
import { handleOtpAutoFocus } from "../../../core/utils";

const getOtpToken = async (mobile, linkId) => {
  const response = await fetch(
    `/api/link/otp`,
    {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        mobile: String(mobile),
        linkId
      }),
    }
  );
  const data = await response.json();
  return data;
};

const otpVerify = async (mobile, token, otp, linkId) => {
  const response = await fetch(`/api/link/verify`, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    method: "POST",
    // credentials: 'include',
    body: JSON.stringify({
      mobile: mobile,
      token: token,
      otp: otp,
      linkId
    }),
  });
  const data = await response.json();

  return data.data.valid;
}

export const otpResend = async (mobile, token, linkId) => {
  const response = await fetch(`/api/link/otp`, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    // credentials: "include",
    method: "POST",
    body: JSON.stringify({
      mobile: String(mobile),
      token,
      linkId
    }),
  });
  const data = await response.json();

  return data?.data?.token;
};

export default function SinglePageFormAuthView({ }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(isMobileMediaQuery);
  const [step, setStep] = useState("mobile_number");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [errors, setErrors] = useState([""]);
  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    document.title = "LoanTube: Login";

    const applicantName = location?.state?.applicantName;
    const leadId = location?.state?.leadId;
    const shortLinkId = location?.state?.shortLinkId;
    if (!applicantName || !leadId || !shortLinkId) {
      return navigate('/');
    }

    setCustomerName(applicantName);
  }, []);

  const onSubmitPhoneNumber = async (phoneNumber) => {
    const res = await getOtpToken(phoneNumber, location.state.shortLinkId);
    if (res.code === 404) {
      return setErrors(["Phone number doesn't match our records"])
    }
    if (res.errors) {
      setErrors(res.errors);
    }
    if (res.data?.token) {
      setErrors([""]);
      let m_token = res.data.token;
      setPhoneNumber(phoneNumber);
      setToken(m_token);
      setStep("otp");
    }
  };

  const handleOtpResend = async () => {
    const m_token = await otpResend(phoneNumber, token, location.state.shortLinkId);
    if (m_token) {
      setToken(m_token);
    }
  };

  const onSubmitOtp = async () => {
    if (otp) {
      const verified = await otpVerify(phoneNumber, token, otp, location.state.shortLinkId);
      let url = "/application";
      if (location.state.qs) {
        url += location.state.qs;
      }
      if (verified){
        return navigate(url + "&pre=true", {
          state: {
            ...location.state,
            verified
          }
        });
      }
      else
        return setErrors([
          "The code you entered is invalid. Please enter the correct code.",
        ]);
    }
  };

  return (
    <ConfigProvider theme={THEME}>
      <Row
        justify={"space-between"}
        align={"middle"}
        className={styles.imageContainer}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => (window.location = "https://www.loantube.com/")}
        >
          <img
            className="logo"
            src="/assets/logo.svg"
            alt="loantube logo"
            style={{ height: isMobile ? "28px" : "clamp(31px, 4.5vh, 48px)" }}
          />
        </div>

        <PhoneNumberInfo
          isMobile={isMobile}
          style={{ fontSize: "14px", position: "relative", top: "-0.15em" }}
          iconStyle={{
            width: "12px",
            top: "0.5px",
            marginRight: "6px",
            position: "relative",
            top: "-0.15em",
          }}
        />
      </Row>

      <Col
        style={{
          width: isMobile ? "100vw" : "600px",
          margin: "0 auto",
          textAlign: "center",
          position: "static",
          padding: isMobile ? "0 12px" : "0 4px",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: "12vh"
        }}
      >
        {step === "mobile_number" && (
          <MobileNumberStep
            onContinue={onSubmitPhoneNumber}
            onCancel={() => (window.location = "https://www.loantube.com/")}
            isMobile={isMobile}
            errors={errors}
            title={`Hi ${customerName},`}
            subtitle={"Verify your mobile number to continue"}
            to={"/application"}
            decreaseMargin={isMobile}
            subtitleStyle={{
              fontWeight: 500,
              fontSize: isMobile ? "18px" : "21px",
              marginTop: isMobile ? "32px" : "48px",
            }}
          />
        )}
        {step === "otp" && (
          <OTPStep
            data={{ mobile_number: phoneNumber }}
            setValue={(o) => {
              setOtp(o);
            }}
            errors={errors}
            value={otp}
            isMobile={isMobile}
            onContinue={onSubmitOtp}
            onCancel={() => setStep("mobile_number")}
            onResend={handleOtpResend}
            isLoginEnabled={otp.length === 4}
            title={'Verify your mobile number'}
          />
        )}
      </Col>
    </ConfigProvider>
  );
}
