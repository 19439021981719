import { Typography, Col, Card, Row, Button, Input } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import Label from "../../components/CustomerComponents/Label";
import EditIcon from "../../components/icons/Edit";
import SuffixedInput from "../../components/CustomerComponents/SuffixedInput";
import { useRef, useState } from "react";
import CustomerFormPopup from "../../components/CustomerComponents/CustomerFormPopup";
import { getResidentialStatus, formatCurrency, getPostcode} from "../../core/utils";
import { updateLead } from "../../core/services";
import {useEffect} from 'react';

const DashboardView = () => {
  const {
    data,
    isMobile,
    applicationData,
    isSidebarVisible,
    setApplicationData,
  } = useOutletContext();
  const navigate = useNavigate();
  const [popup, setPop] = useState(false);
  const [customerForm, setCustomerForm] = useState({
    startFrom: null,
    finishAt: null,
  });
  useEffect(() => {
    document.title = "LoanTube: Your Details"
  }, [])
  const handleClick = (startFrom, finishAt) => {
    setCustomerForm({
      startFrom,
      finishAt,
    });
    setPop(true);
  };

  const onFinish = async (data) => {
    const lead = await updateLead(applicationData.application.id, data);
    setApplicationData(lead.data);
    setPop(false);
  };

  return (
    <Col className="dashboard-layout">
      <CustomerFormPopup
        onClose={() => setPop(false)}
        onFinish={onFinish}
        startFrom={customerForm.startFrom}
        finishAt={customerForm.finishAt}
        visible={popup}
        isMobile={isMobile}
        companyId={applicationData?.application?.company?.id}
      />
      <Typography.Title
        style={{
          fontSize: isMobile ? "24px" : "30px",
          marginBottom: isMobile ? "24px" : "74px",
          marginTop: isMobile && "14px",
          paddingLeft: isMobile && "64px",
          opacity: !isSidebarVisible ? "0" : "1",
          transition: "opacity 100ms ease",
        }}
      >
        Your details
      </Typography.Title>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Full name" disabled isMobile />
          <SuffixedInput
            disabled
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            handleClick={() => handleClick("director_name", "dob")}
            value={applicationData?.application?.director_name}
          />
        </Col>
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Date of birth" disabled isMobile />
          <SuffixedInput
            disabled
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            handleClick={() => handleClick("dob", "residential_postcode")}
            value={applicationData?.application?.dob}
          />
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label disabled label="Mobile number" isMobile />
          <SuffixedInput
            disabled
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            handleClick={() => handleClick("mobile_number", "revenue")}
            value={applicationData?.application?.mobile_number}
          />
        </Col>
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Email address" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            handleClick={() => handleClick("email", "accept_terms")}
            value={applicationData?.application?.email}
          />
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Residential address" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            handleClick={() =>
              handleClick("residential_postcode", "residential_status")
            }
            value={getPostcode(
              applicationData?.application?.residential_postcode?.address
                ?.full_address,
              applicationData?.application?.residential_postcode_manual
            )}
          />
        </Col>
        <Col
          style={{
            flex: !isMobile && 1,
            width: isMobile && "100%",
            visibility: "hidden",
            opacity: 0,
            display: isMobile && "none",
          }}
        >
          <Label label="Business address" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            handleClick={() => handleClick("residential_status", "email")}
          />
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Residential status" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            handleClick={() => handleClick("residential_status", "email")}
            value={getResidentialStatus(
              applicationData?.application?.residential_status
            )}
          />
        </Col>
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          {applicationData?.application?.residential_status !==
            "Non Home Owner" && (
            <>
              <Label label="Property value" disabled isMobile />
              <SuffixedInput
                inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
                Suffix={EditIcon}
                disabled
                value={formatCurrency(
                  applicationData?.application?.property_value
                )}
                handleClick={() => handleClick("property_value", "email")}
              />
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardView;
