import { headers } from "../../../core/constants";

export const fetchData = async (appId) => {
  const response = await fetch(`/api/offers?leadId=${appId}`, {
    headers,
    credentials: "include",
  });

  return response.json();
};

export const fetchStatus = async (appId) => {
  const response = await fetch(
    `/api/offers/status?leadId=${appId}&type=vendorOffers`,
    {
      headers,
      credentials: "include",
    }
  );

  return response.json();
};

export const acceptOffer = async (offerId) => {
  const response = await fetch(`/api/offers/accept?offerId=${offerId}`, {
    method: 'POST',
    headers,
    credentials: "include"
  });

  return response.json();
}

export const submitShareholderDetails = async (body, leadId, existingShareholderId) => {
  if (!body.pscId) return;

  const includeUrl = existingShareholderId ? `/${existingShareholderId}` : ''

  const response = await fetch(`/api/leads/shareholders${includeUrl}?leadId=${leadId}`, {
    method: existingShareholderId ? 'PATCH' :'POST',
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  return response.json();

}

export const deleteShareholder = async (shareholderId, leadId) => {
  const response = await fetch(`/api/leads/shareholders/${shareholderId}?leadId=${leadId}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  return response.json();

}