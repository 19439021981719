import { ConfigProvider, Col } from "antd";
import { THEME, isMobileMediaQuery } from "../../../core/constants";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../../core/hooks/useMediaQuery";
import MobileNumberStep from "../../../components/MobileNumberStep";
import OTPStep from "../../../components/OTPStep";
import { memo, useEffect, useRef, useState } from "react";
import { whoami,login, otpVerify, otpResend } from "./services";

export default function LoginView({ }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(isMobileMediaQuery);
  const [step, setStep] = useState("mobile_number");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState('');
  const [token, setToken] = useState("");
  const [errors, setErrors] = useState([""]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    document.title = "LoanTube: Login"
    async function checkIfLoggedIn(){
      let res = await whoami();
      if(res.user){
        navigate("/dashboard/finance-options");
      } 
      setLoaded(true);
    }

    checkIfLoggedIn();
  }, [])

  const onSubmitPhoneNumber = async (phoneNumber) => {
    const res = await login(phoneNumber);
    if (res.errors) {
      setErrors(res.errors)
    }
    if (res.data?.token) {
      setErrors([""]);
      let m_token = res.data.token;
      setPhoneNumber(phoneNumber);
      setToken(m_token);
      setStep("otp");
    }
  };

  const handleOtpResend = async () => {
    const m_token = await otpResend(phoneNumber, token);
    if (m_token) {
      setToken(m_token);
    }
  };

  const onSubmitOtp = async () => {
    if (otp) {
      const verified = await otpVerify(phoneNumber, token, otp);
      if (verified) return navigate("/dashboard");
      else return setErrors(["The code you entered is invalid. Please enter the correct code."])
    }
  };

  return loaded ? (
    <ConfigProvider theme={THEME}>
      <div
        style={{
          position: "absolute",
          padding: isMobile ? "16px 10px 0" : "3.62vh 36px 0",
          cursor: "pointer",
        }}
        onClick={() => (window.location = "https://www.loantube.com/")}
      >
        <img
          className="logo"
          src="/assets/logo.svg"
          alt="loantube logo"
          style={{ height: "clamp(31px, 4.5vh, 48px)" }}
        />
      </div>
      <Col
        style={{
          width: isMobile ? "100vw" : "600px",
          margin: "0 auto",
          textAlign: "center",
          position: "static",
          padding: isMobile ? "0 12px" : "0 4px",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: isMobile ? "18vh" : "20vh",
        }}
      >
        {step === "mobile_number" && (
          <MobileNumberStep
            onContinue={onSubmitPhoneNumber}
            onCancel={() => (window.location = "https://www.loantube.com/")}
            isMobile={isMobile}
            errors={errors}
          />
        )}
        {step === "otp" && (
          <OTPStep
            data={{ mobile_number: phoneNumber }}
            setValue={(o) => {
              setOtp(o);
            }}
            errors={errors}
            value={otp}
            isMobile={isMobile}
            onContinue={onSubmitOtp}
            onCancel={() => setStep("mobile_number")}
            onResend={handleOtpResend}
            isLoginEnabled={otp.length === 4}
          />
        )}
      </Col>
    </ConfigProvider>
  ) : "";
}
