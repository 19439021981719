import { Typography, Col, Space } from "antd";
import { COLORS } from "../../core/constants";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function StepSubTitle({
  children,
  style,
  fontSize,
  lineHeight,
  textStyle,
  hideIcon,
  isMobile,
  error,
  iconFontSize,
  gap
}) {
  const spaceGap = gap || '8px'
  
  return (
    children && (
      <div style={{ ...style, color: error ? COLORS.red : "#777" }}>
        <Col>
          <Space style={{ alignItems: "flex-start", gap: spaceGap }}>
            {!hideIcon && (
              <InfoCircleOutlined
                style={{
                  fontSize: iconFontSize ? iconFontSize : isMobile ? "14px" : "15px",
                  marginTop: "3.5px",
                }}
              />
            )}
            <Typography.Text
              style={{
                fontSize: fontSize,
                lineHeight: lineHeight,
                color: error ? COLORS.red : "#777",
                fontWeight: 500,
                ...textStyle,
              }}
            >
              {children}
            </Typography.Text>
          </Space>
        </Col>
      </div>
    )
  );
}
