import { Col, Row, Button } from "antd";
import StepForwardButton from "../../../components/CustomerComponents/StepForwardButton";
import styles from "./style.module.css";
import { COLORS } from "../../../core/constants";
import { useState } from "react";
import { Skeleton } from "antd";
import LoanVendorBanner from "../../../components/CustomerComponents/LoanVendorBanner/LoanVendorBanner";
import lodash from "lodash";

const { lowerCase, replace } = lodash;

const LoanVendorRenderer = ({
  isMobile,
  vendorDetails,
  offerType,
  variation,
  isLoading,
  bannerType,
  highlight,
  onAccept,
  jobDone,
  focusedOfferId,
  directorName
}) => {
  const { mainOffers, usp, notes, vendor, moreOffers, documents_required } =
    vendorDetails;
  const [showMoreOffers, setShowMoreOffers] = useState(false);
  const [acceptedOffers, setAcceptedOffers] = useState([]);
  const [pendingAcceptOffer, setPendingAcceptOffer] = useState(null);
  const [isDissableTriver, setDissableTriver] = useState(false);
  const vendorName = replace(lowerCase(vendor), " ", "");
  const imageSrc = `/assets/logos/${vendorName}.png`;
  const logoStyle = vendorName === "capitalon tap" ? { marginLeft: "-0.5em" } : { "foo": "bar" };

  const hideOffers = mainOffers.every((offer) => offer.hideOffer);

  const getButtonText = (accepted, offer) => {
    if (accepted)
      return "Processing";

    if (offer?.buttonType === "cot_popup") {
      return "Get this Card";
    }
    if (vendorName === 'fundingcircle') {
      return "Get a quote"
    }

    if (vendorName === 'triver') {
      if (usp[0] == "Pre-approved") {
        return "Get this loan"
      }
      else {
        return "Continue"
      }
    }

    if (offerType === "indicative") {
      return "Continue";
    }

    //default
    return "Get this loan";

    let defaultText = offer.buttonType === 'cot_popup' ? "Get this Card" : "Get this loan";

    return accepted
      ? "Processing"
      : offerType === "indicative"
        ? "Continue"
        : defaultText
  };

  const filteredOffersData = isLoading
    ? mainOffers.slice(0, 1)
    : [...mainOffers.filter(o => o), ...(showMoreOffers ? moreOffers.filter(o => o) : [])];

  const handleViewMore = () => setShowMoreOffers(!showMoreOffers);

  const handleAcceptOffer = (offer) => {
    onAccept(offer);
  };

  const filteredOffers = [];
  if (filteredOffersData.length > 0) {
    filteredOffers.push(filteredOffersData[0]);
  }

  const isOfferAccepted = (offer) => {
    return offer?.accepted || acceptedOffers.includes(offer?.offerId);
  };

  

  return (
    <>
      {!hideOffers &&
        filteredOffers.map((offer, index) => (
          offer.buttonType && (

            <Col
              className={[
                styles.vendor,
                highlight ? styles.redVendor : styles.bordered,
              ].join(" ")}
            >
              <Row className={styles.vendorHeading}>
                {highlight && <LoanVendorBanner bannerType={bannerType} />}
                <div className={styles.image}>
                  {!isLoading ? (
                    <img src={imageSrc} style={logoStyle} />
                  ) : (
                    <Skeleton.Node
                      style={
                        isMobile
                          ? { height: "66px", width: "220px" }
                          : { height: "72px", width: "220px" }
                      }
                      active={true}
                    >
                      <div />
                    </Skeleton.Node>
                  )}
                </div>
                <div
                  className={styles.uspContainer}
                  style={
                    isLoading && isMobile
                      ? {
                        width: "100%",
                        maxHeight: isLoading && "25px",
                        paddingTop: isLoading && "12px",
                      }
                      : {}
                  }
                >
                  <Skeleton
                    paragraph={{
                      rows: isMobile ? 0 : 1,
                      style: { margin: isMobile && "0", height: "10px" },
                    }}
                    loading={isLoading}
                    active={true}
                  >
                    {usp.map((usp, index) => (
                      <div key={index}>{usp}</div>
                    ))}
                  </Skeleton>
                </div>
              </Row>
              {!hideOffers &&
                filteredOffersData.map((offer, index) => (

                  <Row
                    className={styles.fullyApprovedLoan}
                    align={"middle"}
                    justify={"space-between"}
                    key={index}
                  >
                    <Row
                      className={styles.loanRow}
                      justify={"space-between"}
                      style={{ flex: 1 }}
                    >
                      <Col className={styles.loanInfo}>
                        <Skeleton
                          paragraph={{
                            rows: 0,
                            style: { margin: isMobile ? "8px" : "0px" },
                          }}
                          loading={isLoading}
                          active={true}
                          title={{
                            width: isMobile ? "86%" : "38%",
                            style: {
                              margin: "0",
                              marginTop: isMobile ? "12px" : "0",
                            },
                          }}
                          className="center"
                        >
                          {`${offer.amount}${offer.tenure ? " " + offer.tenure : ""}`}
                        </Skeleton>
                      </Col>

                      <Col className={styles.loanInfo}>
                        <Skeleton
                          paragraph={{
                            rows: 0,
                            style: { margin: isMobile ? "8px" : "0px" },
                          }}
                          loading={isLoading}
                          active={true}
                          title={{
                            width: isMobile ? "65%" : "38%",
                            style: { margin: "0" },
                          }}
                          className="center"
                        >
                          <span style={{ position: "relative", top: "-0.08em" }}>
                            {offer?.cost?.indexOf("@") > -1 ? "@" : ""}
                          </span>
                          <span>{offer?.cost?.split("@")[1] ?? offer?.cost}</span>
                        </Skeleton>
                      </Col>
                      <Col className={styles.loanInfo}>
                        <Skeleton
                          paragraph={{
                            rows: 0,
                            style: { margin: isMobile ? "8px" : "0px" },
                          }}
                          loading={isLoading}
                          active={true}
                          title={{
                            width: isMobile ? "72%" : "38%",
                            style: {
                              margin: "0",
                              marginBottom: isMobile ? "12px" : "0",
                            },
                          }}
                          className="center"
                        >
                          <span style={{ fontWeight: 600 }}>{offer.emi}</span>
                        </Skeleton>
                      </Col>
                    </Row>
                    <Col>
                      {variation === "fully-approved" && !isLoading && vendorName === 'fundingcircle' ? (
                        <StepForwardButton
                          enabled
                          justify={isMobile ? "center" : "space-between"}
                          borderRadius={"10px"}
                          hasIcon={!isMobile}
                          fontWeight={isMobile && 500}
                          loading={pendingAcceptOffer === offer.offerId || (offer.offerId === focusedOfferId && !jobDone)}
                          wrappable={false}
                          className={isOfferAccepted(offer) ? 'isOfferAccepted' : ''}
                          style={
                            isMobile
                              ? {
                                fontSize: "16px",
                                padding: isOfferAccepted(offer)
                                  ? "7px 24px"
                                  : "7px 12px",
                                width: "120px",
                              }
                              : {
                                width: "215.88px",
                                fontSize: "clamp(18px, 2.1vh, 20px)",
                              }
                          }
                          onClick={() => {
                            if (!isOfferAccepted(offer)) handleAcceptOffer(offer)
                          }}
                        >
                          {getButtonText(isOfferAccepted(offer), offer)}
                        </StepForwardButton>
                      ) :
                        (
                          <>

                            {!isLoading && vendorName === 'triver' ? (
                              <StepForwardButton
                                enabled={!offer?.disableButton && !isDissableTriver}
                                justify={isMobile ? "center" : "space-between"}
                                borderRadius={"10px"}
                                hasIcon={!isMobile}
                                fontWeight={isMobile && 500}
                                loading={pendingAcceptOffer === offer.offerId || (offer.offerId === focusedOfferId && !jobDone)}
                                wrappable={false}
                                style={{
                                  background: offer?.disableButton || isDissableTriver ? "#c6002985" : "#C60029",
                                  fontSize: isMobile ? "16px" : "clamp(18px, 2.1vh, 20px)",
                                  padding: isMobile ? "7px 12px" : undefined,
                                  width: isMobile ? "120px" : "215.88px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                                }
                                onClick={() => {
                                  handleAcceptOffer(offer);
                                  setDissableTriver(true);
                                }}
                              >
                                {getButtonText(isOfferAccepted(offer), offer)}
                              </StepForwardButton>
                            ) : (
                              <StepForwardButton
                                enabled
                                justify={isMobile ? "center" : "space-between"}
                                borderRadius={"10px"}
                                hasIcon={!isMobile}
                                fontWeight={isMobile && 500}
                                loading={pendingAcceptOffer === offer.offerId || (offer.offerId === focusedOfferId && !jobDone)}
                                wrappable={false}
                                style={
                                  isMobile
                                    ? {
                                      fontSize: "16px",
                                      padding: "7px 12px",
                                      width: "120px",
                                    }
                                    : {
                                      width: "215.88px",
                                      fontSize: "clamp(18px, 2.1vh, 20px)",
                                    }
                                }
                                onClick={() => {
                                  handleAcceptOffer(offer)
                                }}
                              >
                                {getButtonText(isOfferAccepted(offer), offer)}
                              </StepForwardButton>
                            )}


                          </>
                        )
                      }
                      {variation === "other-available" && (
                        <div style={{ width: "215px" }} />
                      )}
                    </Col>
                  </Row>
                ))}
              <Row
                className={styles.notes}
                justify={"space-between"}
                wrap={false}
                align={"middle"}
                style={
                  isLoading && {
                    height: isMobile ? "47px" : "70px",
                    paddingTop: isMobile ? "0px" : "20px",
                    paddingBottom: isMobile ? "8px" : "0px",
                  }
                }
              >
                <Skeleton
                  loading={isLoading}
                  paragraph={{ rows: 0, style: { margin: isMobile && "0" } }}
                  active={true}
                >
                  <Col>
                    {notes.map((note, index) => (
                      <div className={styles.note} key={index}>
                        <span
                          style={{
                            display: "inline-block",
                            marginRight: "8px",
                            fontSize: "24px",
                            position: "absolute",
                            top: "-0.035em",
                            height: 0,
                            width: 0,
                          }}
                        >
                          *
                        </span>
                        <span style={{ marginLeft: "16px", display: "inline-block" }}>
                          {note}
                        </span>
                      </div>
                    ))}
                    {documents_required?.length > 0 && (
                      <div className={styles.note}>
                        <div style={{ marginBottom: "4px" }}>
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "8px",
                              fontSize: "24px",
                              position: "absolute",
                              top: "-0.035em",
                              width: 0,
                              height: 0,
                            }}
                          >
                            *
                          </span>
                          <span
                            style={{ marginLeft: "16px", display: "inline-block" }}
                          >
                            Documents required:
                          </span>
                        </div>
                        {documents_required.map((n, index) => (
                          <div
                            key={index}
                            style={{
                              marginLeft: isMobile ? "18px" : "42px",
                              marginBottom: "4px",
                              textIndent: "-13px",
                              paddingLeft: "14px",
                            }}
                          >
                            {`${n}`}
                          </div>
                        ))}
                      </div>
                    )}
                  </Col>
                </Skeleton>
                {hideOffers && !isMobile && !isLoading && (
                  <Col style={{ marginLeft: "20px" }}>
                    <StepForwardButton
                      hasIcon
                      wrappable={false}
                      enabled
                      borderRadius={"10px"}
                      justify={"space-between"}
                      style={{
                        fontSize: "clamp(18px, 2.1vh, 20px)",
                      }}
                      onClick={() => handleAcceptOffer(mainOffers[0])}
                    >
                      {getButtonText(isOfferAccepted(mainOffers[0]), mainOffers[0])}
                    </StepForwardButton>
                  </Col>
                )}
              </Row>
              {hideOffers && isMobile && !isLoading && (
                <Row justify={"center"} style={{ margin: "10px 0 10px" }}>
                  <StepForwardButton
                    enabled
                    borderRadius={"10px"}
                    style={{
                      fontSize: "16px",
                    }}
                    justify={"center"}
                    wrappable={false}
                    fontWeight={500}
                    onClick={() => handleAcceptOffer(mainOffers[0])}
                  >
                    {getButtonText(isOfferAccepted(mainOffers[0]), mainOffers[0])}
                  </StepForwardButton>
                </Row>
              )}
              {!isLoading && moreOffers.length > 0 && (
                <Row justify={"center"} style={{ margin: "10px 0 10px" }}>
                  <Button
                    style={{
                      color: COLORS.red,
                      padding: 0,
                      height: "auto",
                      lineHeight: 1,
                      fontSize: isMobile ? "18px" : "20px",
                      display: "flex",
                    }}
                    fontWeight={isMobile && 500}
                    type="text"
                    onClick={handleViewMore}
                  >
                    <span
                      style={{
                        display: "block",
                        marginRight: "0.2em",
                        marginTop: "0.07em",
                      }}
                    >
                      {showMoreOffers ? "-" : "+"}
                    </span>{" "}
                    {showMoreOffers ? (
                      <span>less offers</span>
                    ) : (
                      <span>more offers</span>
                    )}
                  </Button>
                </Row>
              )}
            </Col>

          )))}
    </>
  );
};

export default LoanVendorRenderer;
