import { range } from "lodash";
import { Typography, Col, Row, Card, List } from "antd";
import { StarFilled } from "@ant-design/icons";

import HandIcon from "../../components/CustomerComponents/HandIcon";

import { COLORS } from "../../core/constants";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const { Text, Title } = Typography;

const reviews = [
  {
    name: "Laura Treimane-Freimane",
    stars: 5,
    comment:
      "Very fast, very nice. Will make sure that you get money. Perfect thanks",
  },
  {
    name: "Zoe Mardel",
    stars: 5,
    comment: "Was made so easy … kind and caring staff",
  },
  {
    name: "Math Littlefair",
    stars: 5,
    comment:
      "Easy to apply for a loan, the staff are polite and friendly. Highly recommended",
  },
];

const CustomerSidebar = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <Col
      className="main-col"
      style={{
        backgroundColor: "#F6F6F6",
      }}
    >
      <Col span={24} style={{ marginBottom: "5.4vh" }}>
        <a href={'https://www.loantube.com/business-loans/?' + searchParams.toString()} target="_blank">
        <img
          className="logo"
          src="/assets/logo.svg"
          alt="loantube logo"
          style={{ height: "clamp(31px, 4.5vh, 48px)" }}
        />
        </a>
      </Col>
      <Row>
        <Col style={{ width: "100%" }}>
          <Card
            className="pledge-card"
            style={{
              border: "1.5px solid #242424",
              borderRadius: "10px",
            }}
          >
            <Row justify={"space-between"} align="middle" wrap={false}>
              <Col style={{ display: "flex" }}>
                <HandIcon />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Typography.Title
                      level={3}
                      className="pledge"
                      style={{
                        color: "#C60029",
                        textAlign: "left",
                        marginTop: "0px",
                        fontWeight: 600,
                        lineHeight: "34px",
                        marginBottom: "clamp(4px, 0.5555vh, 6px)",
                      }}
                    >
                      Pledge of Integrity
                    </Typography.Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text
                      className="pledge-sub"
                      style={{
                        color: COLORS.darkGrey,
                        lineHeight: "24px",
                        fontWeight: "400",
                        maxWidth: "min(95%, 280px)",
                        display: "inline-block",
                      }}
                    >
                      We do not sell your data, unsolicited, to any third party
                      firms.
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row justify="center" className="nav-header-row">
        <Col
          style={{
            textAlign: "center",
            fontSize: "clamp(16px, 2.037vh, 22px)",
          }}
        >
          <Text strong style={{ fontSize: "clamp(16px, 2.037vh, 22px)" }}>
            Find your loan with{" "}
          </Text>
          <Text
            strong
            style={{
              fontSize: "clamp(16px, 2.037vh, 22px)",
              color: "#C60029",
            }}
          >
            confidence
          </Text>
        </Col>
      </Row>
      <Row justify="center">
        <Col span="24" className="testimonial-title">
          <Text strong style={{ fontSize: "clamp(16px, 2.037vh, 24px)" }}>
            Here's what our{" "}
          </Text>
          <Text
            strong
            style={{
              color: "#C60029",
              fontSize: "clamp(16px, 2.037vh, 24px)",
            }}
          >
            customers{" "}
          </Text>
          <Text strong style={{ fontSize: "clamp(16px, 2.037vh, 24px)" }}>
            have to{" "}
          </Text>
          <Text
            strong
            style={{
              color: "#C60029",
              fontSize: "clamp(16px, 2.037vh, 24px)",
            }}
          >
            say
          </Text>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <List
            dataSource={reviews}
            renderItem={(item, index) => {
              return (
                <List.Item style={{ width: "100%", padding: "0" }}>
                  <Card
                    key={index}
                    bordered={false}
                    className="testimonial-card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: "none",
                      background: "transparent",
                      width: "100%",
                    }}
                  >
                    <Text
                      level={3}
                      strong
                      style={{
                        color: "#C60029",
                        fontSize: "16px",
                        fontSize: "clamp(14px, 1.95vh, 22px)",
                        fontWeight: "600",
                        marginTop: "16px",
                        marginBottom: "10px",
                      }}
                    >
                      {item.name}
                    </Text>
                    <Row
                      justify={"center"}
                      style={{
                        margin: "0.92vh 0 0.46vh",
                      }}
                    >
                      <Col>
                        {range(0, parseInt(item.stars)).map((star, index) => {
                          return (
                            <StarFilled
                              key={index}
                              style={{
                                color: "#f9b536",
                                marginRight: "8px",
                                fontSize: "clamp(19px, 2.314vh, 25px)",
                              }}
                            />
                          );
                        })}
                      </Col>
                    </Row>
                    <Text
                      style={{
                        fontWeight: 500,
                        color: COLORS.darkGrey,
                        fontSize: "clamp(11.6px, 1.574vh, 17px)",
                        padding: "0 20px",
                        display: "inline-block",
                      }}
                    >
                      "{item.comment}"
                    </Text>
                  </Card>
                </List.Item>
              );
            }}
          ></List>
        </Col>
      </Row>
    </Col>
  );
};

export default CustomerSidebar;
