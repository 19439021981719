import { useEffect } from "react";
import { BLOCK_ZOOM } from "../constants";

function useBlockZoom() {
  useEffect(() => {
    if (!BLOCK_ZOOM) return;

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
      document.body.style.zoom = 1;
    });

    document.addEventListener("gesturechange", function (e) {
      e.preventDefault();

      document.body.style.zoom = 1;
    });
    document.addEventListener("gestureend", function (e) {
      e.preventDefault();
      document.body.style.zoom = 1;
    });

    document.addEventListener(
      "wheel",
      (event) => {
        const { ctrlKey } = event;
        if (ctrlKey) {
          event.preventDefault();
          return;
        }
      },
      { passive: false }
    );

    document.addEventListener("keydown", (e) => {
      const keyCodes = [61, 107, 173, 109, 187, 189];
      if ((e.ctrlKey || e.metaKey) && keyCodes.indexOf(e.which) != -1) {
        document.body.style.zoom = 1;
        e.preventDefault();
      }
    });
  }, []);
}

export default useBlockZoom;
