import { snakeCase } from "lodash";
import { Radio, Space } from "antd";
import { useState, useEffect } from "react";

import { getName } from "../../core/utils";

export default function RadioList({
  label,
  name,
  list,
  setValue,
  defaultValue,
  fieldProps,
  isMobile,
  ...props
}) {
  name = name || snakeCase(label);
  const [items, setItems] = useState([]);

  name = getName({ label, name });

  props.onChange = function (e) {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!list[0]?.value) {
      let formatted = list.map((item) => {
        return {
          value: snakeCase(item),
          label: item,
        };
      });

      setItems(formatted);
    } else {
      setItems(list);
    }
  }, [list]);

  const ItemList = items.map((item, index) => {
    return (
      <Radio.Button
        style={{
          fontWeight: 500,
          fontSize: isMobile ? "14px" : "clamp(15px, 1.76vh, 19px)",
          border: "none",
          padding: isMobile ? "7.8px 14px" : "0px 18.5px",
          height: isMobile ? "auto" : "clamp(41px, 5.092vh, 55px)",
          minHeight: isMobile ? "38px" : undefined,
          fontFamily: "Montserrat",
          display: "inline-flex",
          alignItems: "center"
        }}
        value={item.value}
        key={index}
        onClick={() => null}
      >
        {item.label}
      </Radio.Button>
    );
  });

  return (
    <Radio.Group
      value={defaultValue}
      optionType="button"
      buttonStyle="solid"
      size="middle"
      name={name}
      {...props}
      placeholder="Select"
    >
      <Space direction="vertical" style={{ gap: "min(1.85vh, 20px)" }}>
        {ItemList}
      </Space>
    </Radio.Group>
  );
}
