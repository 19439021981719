import "../../shared.css"

import Customer from "../Customer/Customer";

export default function Embed() {

  function onFinish(values) {
    console.log(values)
  }

  function onClose() {
    console.log("closed")
  }

  return <Customer
    embeded={true}
    external={true}
    onFinish={onFinish}
    onEmbededClose={onClose}
    companyId={null}
  />
}