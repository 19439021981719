import { Spin, Divider, Typography, Input, Space } from "antd";
import { COLORS } from "../../../core/constants";
import { RightOutlined } from "@ant-design/icons";
import { makeHandleFocus } from "../../../core/utils";
import useClickOutside from "../../../core/hooks/useClickOutside";
import { useEffect, useRef } from "react";
import styles from "../style.module.css";

import { SearchOutlined } from "@ant-design/icons";

export default function SelectInput({
  loading,
  onSearch,
  onSelect,
  titleColor,
  children,
  options,
  selectedOptionId,
  defaultValue,
  maxHeight,
  placeholder,
  name,
  hideOptions,
  value,
  isMobile,
  onKeyDown,
  focusMultiplier,
  infiniteHeight,
  onFocus,
  onBlur,
  suffix,
  hasBoxShadow,
  inputStyle,
  darkText,
  appendOptions,
  className,
  readOnlyInput,
  optionsStyle,
  emptyOptionVisible,
  noGap,
  scrollIntoView,
}) {
  const ref = useRef(null);
  useClickOutside(ref, onBlur, true);
  const scrollIntoViewRef = useRef(null);

  const hasHideOptionsClass = () => {
    if (emptyOptionVisible) {
      return false;
    }

    return hideOptions || options.length === 0;
  };

  const hideGap = noGap && hasHideOptionsClass();

  useEffect(() => {
    if (!hideOptions && scrollIntoView) {
      if (scrollIntoViewRef.current) {
        // scrollIntoViewRef.current.scrollIntoViewIfNeeded({
        //   block: "end",
        //   behavior: "smooth",
        // });
      }
    }
  }, [hideOptions]);

  return (
    <Space
      direction={"vertical"}
      size={12}
      style={{ width: "100%", gap: "0px !important" }}
      className={`select-space ${className || ""} ${hideGap ? "no-gap" : ""}`}
      ref={ref}
    >
      <Input
        value={value}
        onChange={onSearch}
        name={name}
        className={hasHideOptionsClass() && "hide-options"}
        style={{
          width: "100%",
          padding: "0 20px",
          overflow: "hidden",
          ...inputStyle,
        }}
        suffix={
          suffix || (
            <SearchOutlined
              style={{ color: hasBoxShadow ? COLORS.darkGrey : COLORS.red }}
            />
          )
        }
        defaultValue={defaultValue}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onFocus={makeHandleFocus(isMobile, focusMultiplier, onFocus)}
        readOnly={readOnlyInput}
      />
      <Spin spinning={loading}>
        {children}
        {!hideOptions && (
          <div
            style={{
              backgroundColor: hasBoxShadow ? COLORS.white : COLORS.offwhite,
              maxHeight:
                !infiniteHeight &&
                (maxHeight || isMobile
                  ? "clamp(198.66px, 24vh, 225px)"
                  : "clamp(220px, 25.25vh, 272.71px)"),
              overflowY: "auto",
              cursor: "pointer",
              position: "relative",
              top: "-12.5px",
              border: "1.5px solid #212121",
              borderRadius: "10px",
              borderTop: "0px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              boxShadow: hasBoxShadow && "0 25px 50px -12px rgb(0 0 0 / 0.25)",
              opacity: options.length === 0 ? 0 : 1,
              ...optionsStyle,
            }}
          >
            {options.map((item, index) => (
              <div key={item.id || index}>
                <div
                  style={{
                    cursor: "pointer",
                    padding:
                      "clamp(12px,1.574vh, 17px) 20px clamp(12px, 1.574vh, 17px) 20px",
                    position: "relative",
                  }}
                  onClick={() => onSelect(item.id)}
                  className={styles.selectItemContainer}
                >
                  <Typography.Title
                    level={5}
                    className={styles.selectItemText}
                    style={{
                      margin: item.text ? "0 0 4px" : "0",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "block",
                      fontSize: isMobile
                        ? "14px"
                        : "clamp(16px, 1.8518vh, 20px)",
                      fontWeigth: "500",
                    }}
                  >
                    {item.header}
                  </Typography.Title>
                  <Typography.Text
                    className={styles.selectItemText}
                    style={{
                      fontWeight: darkText ? "500" : "400",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "block",
                      fontSize: isMobile
                        ? "12px"
                        : "clamp(16px, 1.622vh, 18px)",
                    }}
                  >
                    {item.text}
                  </Typography.Text>
                </div>
                {index !== options.length - 1 && (
                  <div style={{ overflow: "hidden" }}>
                    <Divider
                      plain
                      style={{
                        margin: 0,
                        padding: "0 20px",
                        height: "1.5px",
                        backgroundColor: "#212121",
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
            {!hideOptions && scrollIntoView && (
              <div
                ref={scrollIntoViewRef}
                style={{
                  position: "relative",
                  bottom: "-40px",
                  pointerEvents: "none",
                }}
              ></div>
            )}
          </div>
        )}
      </Spin>
    </Space>
  );
}
