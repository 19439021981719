import { Spin, Divider, Typography, Input, Space } from "antd";
import { COLORS } from "../../core/constants";
import { RightOutlined } from "@ant-design/icons";
import { makeHandleFocus } from "../../core/utils";
import useClickOutside from "../../core/hooks/useClickOutside";
import { useRef } from "react";

import { SearchOutlined } from "@ant-design/icons";

export default function SelectInput({
  loading,
  onSearch,
  onSelect,
  titleColor,
  children,
  options,
  selectedOptionId,
  hasIcon,
  defaultValue,
  maxHeight,
  placeholder,
  hideOptions,
  value,
  isMobile,
  onKeyDown,
  focusMultiplier,
  infiniteHeight,
  onFocus,
  onBlur,
  suffix,
  hasBoxShadow,
  inputStyle,
  darkText,
  appendOptions,
  className,
  readOnlyInput
}) {
  const ref = useRef(null);
  useClickOutside(ref, onBlur);

  const borderRadius = appendOptions
    ? {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    }
    : { borderRadius: "10px" };

  return (
    <Space
      direction={"vertical"}
      size={12}
      style={{ width: "100%" }}
      className={`select-space ${className || ''}`}
      ref={ref}
    >
      <Input
        value={value}
        onChange={onSearch}
        style={{
          width: "100%",
          padding: "0 20px",
          overflow: "hidden",
          ...inputStyle,
        }}
        suffix={suffix || <SearchOutlined style={{ color: hasBoxShadow ? COLORS.darkGrey : COLORS.red }} />}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onFocus={makeHandleFocus(isMobile, focusMultiplier, onFocus)}
        readOnly={readOnlyInput}
      />
      <Spin spinning={loading} style={{ paddingTop: "30px" }}>
        {children}
        {!hideOptions && (
          <div
            style={{
              backgroundColor: hasBoxShadow ? COLORS.white : COLORS.offwhite,
              maxHeight:
                !infiniteHeight &&
                (maxHeight || isMobile
                  ? "clamp(198.66px, 24vh, 225px)"
                  : "clamp(220px, 25.25vh, 272.71px)"),
              overflowY: "auto",
              cursor: "pointer",
              boxShadow:
                hasBoxShadow &&
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
              ...borderRadius,
            }}
          >
            {options.map((item) => (
              <>
                <div
                  key={item.id}
                  style={{
                    cursor: "pointer",
                    padding: hasIcon
                      ? "clamp(12px,1.574vh, 17px) 50px clamp(12px, 1.574vh, 17px) 20px"
                      : "clamp(12px,1.574vh, 17px) 20px clamp(12px, 1.574vh, 17px) 20px",
                    position: "relative",
                  }}
                  onClick={() => onSelect(item.id)}
                >
                  <Typography.Title
                    level={5}
                    style={{
                      margin: item.text ? "0 0 4px" : "0",
                      color:
                        selectedOptionId === item.id
                          ? COLORS.red
                          : titleColor || COLORS.darkGrey,
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "block",
                      fontSize: isMobile
                        ? "14px"
                        : "clamp(16px, 1.8518vh, 20px)",
                      fontWeigth: "500",
                    }}
                  >
                    {item.header}
                  </Typography.Title>
                  <Typography.Text
                    style={{
                      color: darkText ? COLORS.darkGrey : "#8E8E8E",
                      fontWeight: darkText ? "500" : "400",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "block",
                      fontSize: isMobile
                        ? "12px"
                        : "clamp(14px, 1.482vh, 16px)",
                    }}
                  >
                    {item.text}
                  </Typography.Text>
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "48%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {hasIcon && (
                      <RightOutlined
                        style={{
                          fontSize: "clamp(22px, 2.407vh, 26px)",
                          color: COLORS.darkGrey,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div style={{ overflow: "hidden" }}>
                  <Divider plain style={{ margin: 0, padding: "0 20px" }} />
                </div>
              </>
            ))}
          </div>
        )}
      </Spin>
    </Space>
  );
}
