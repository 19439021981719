import React, { useState, useEffect } from "react";
import { getName, makeHandleFocus } from "../../core/utils";
import { Input } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

function MobileNumber({
  data,
  defaultValue,
  isMobile,
  googleReCaptchaProps,
  setValue,
  ...props
}) {
  let { name, label } = props;
  name = getName({ label, name });

  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [error, setError] = useState('');

  useEffect(() => {
    setInputValue(defaultValue || '');
  }, [defaultValue]);

  useEffect(() => {
    if (inputValue.length === 11 && inputValue.startsWith('07')) {
      handleVerifyCaptcha();
    }
  }, [inputValue]);

  function handleChange(e) {
    const val = e.target.value;

    if (val.length >= 3) {
      if (!val.startsWith('07')) {
        setError("Please provide a valid mobile number starting with 07");
      } else if (val.length !== 11) {
        setError("Please provide a valid mobile number");
      } else {
        setError('');
      }
    } else {
      setError('');
    }
    setInputValue(val);
  }

  async function handleVerifyCaptcha() {
    if (!inputValue || error) return;

    if (googleReCaptchaProps.executeRecaptcha) {
      try {
        const token = await googleReCaptchaProps.executeRecaptcha('mobile_number_submit');

        const response = await fetch('/api/captcha/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ captchaToken: token }),
        });

        const data = await response.json();

        if (response.ok && data.success) {
          setValue(inputValue);
        } else {
          // message.error(`CAPTCHA verification failed: ${data.message || 'Unknown error'}`);
        }
      } catch (error) {
        // message.error('An error occurred while verifying CAPTCHA.');
      }
    }
  }

  return (
    <div>
      <Input
        value={inputValue}
        maxLength="11"
        name={name}
        onChange={handleChange}
        onFocus={makeHandleFocus(isMobile)}
        onBlur={() => setError(inputValue.length >= 3 && inputValue.length !== 11 ? "Please provide a valid mobile number" : '')}
        inputMode="numeric"
        type="tel"
        status={error ? "error" : ""}
      />
      {error &&
        <p
          style={{
            color: 'rgb(198, 0, 41)',
            opacity: 0.8,
            fontSize: 'clamp(13.6px, 1.388vh, 15px)',
            marginTop: '10px',
            fontWeight: 500,
            display: 'flex',
            alignItems: 'flex-start',
            paddingLeft: '0.4px',
          }}
        >
          <span
            role="img"
            aria-label="info-circle"
            className="anticon anticon-info-circle"
            style={{
              fontSize: '15px',
              marginRight: '6px',
              marginTop: '1px',
            }}
          >
            <InfoCircleOutlined />
          </span>
          {error}
        </p>
      }
    </div>
  );
}

export default withGoogleReCaptcha(MobileNumber);
