import { Row, Typography } from "antd";
import { COLORS } from "../core/constants";
import StepForwardButton from "../components/CustomerComponents/StepForwardButton";
import CTA from "../components/CustomerComponents/Cta";
import MobileNumber from "../components/CustomerComponents/MobileNumber";
import Label from "../components/CustomerComponents/Label";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from "react";
import Error from "../components/CustomerComponents/Error";

const MobileNumberStep = ({ isMobile, onContinue, onCancel, errors, title, subtitle, to, subtitleStyle, decreaseMargin }) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if(errors){
      setError(errors[0]);
    }
  }, [errors])

  const handleBlur = (value) => {
    if (value.length === 11) {
      const input = document.querySelector('input[name="mobile"]');
      input.blur();
    }
  };

  const validate = (value) => {
    if (!String(value).startsWith("07")) {
      setError("Please provide a valid phone number starting with 07");
    } else if (value.length < 11) {
      setError("Please provide a valid phone number");
    } else {
      setError("");
      handleBlur(value);
    }
  };

  const setValue = (value) => {
    validate(value);
    setPhoneNumber(value);
  };

  return (
    <>
      <Row>
        <Typography.Title
          style={{
            display: "block",
            fontWeight: "600",
            fontSize: isMobile ? "24px" : "28px",
            marginBottom: "0",
          }}
        >
          { title || "Sign in"}
          <span
            style={{
              display: "block",
              fontWeight: "400",
              fontSize: isMobile ? "14px" : "16px",
              margin: isMobile ? "6px 0 24px" : "6px 0 32px",
              ...subtitleStyle
            }}
          >
            { subtitle || "(only for business loan customers)"}
          </span>
        </Typography.Title>
      </Row>
      <Row style={{ width: isMobile ? "90%" : "80%", display: "block" }}>
        <Label label="Your mobile number"></Label>
        <MobileNumber
          label="Your mobile number"
          name="mobile"
          setValue={setValue}
          data={{ otp: false }}
        ></MobileNumber>
        <Error errors={[error || ""]} isMobile={isMobile} />
      </Row>
      <Row
        justify={"space-between"}
        align={"middle"}
        style={{ width: "100%", marginTop: decreaseMargin ? "40px" : "100px" }}
      >
        <CTA
          type="text"
          style={{
            padding: 0,
            fontSize: isMobile ? "15px" : "clamp(17px, 1.9444vh, 21px)",
            color: COLORS.darkGrey,
          }}
          ctaEnabled={true}
          onClick={onCancel}
        >
          Cancel
        </CTA>
        <StepForwardButton
          enabled={phoneNumber && !error}
          onClick={() => onContinue(phoneNumber)}
        >
          Continue
        </StepForwardButton>
      </Row>
      <Row
        justify={isMobile ? "center" : "start"}
        style={{ width: "100%", marginTop: isMobile ? "55px" : "70px" }}
      >
        <Typography.Text>
          No account? Apply&#160;
        </Typography.Text>
        <Typography.Text
          style={{ textDecoration: "underline", color: COLORS.red, position: "relative", top: "-0.02em" }}
        >
          <a style={{ color: COLORS.red, fontWeight: 500 }} href={to || '/'}>
            here
          </a>
        </Typography.Text>
      </Row>
    </>
  );
};

export default MobileNumberStep;
