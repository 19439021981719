import { Input } from "antd";
import { useEffect, useState } from "react";
import {  makeHandleFocus } from "../../core/utils";

export default function DateOfBirth({ defaultValue, setValue, isMobile, placeholder, name }) {
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
    if (defaultValue && !displayValue) {
      handleSetValue(defaultValue.replace("/", " / "));
    }
  }, []);

  function checkValue(str, max) {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0) {
        return str;
      }
      if (num > max) {
        return String(max).length === 2 ? "01" : new Date().getFullYear();
      }

      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return str;
  }

  function append(values, str = " / ") {
    const parts = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + str : v;
    });

    return parts.join("").substr(0, 14);
  }

  function handleSetValue(newValue) {
    let input = newValue;

    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);

    let values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    let currentYear = new Date().getFullYear();
    if (values[0]) values[0] = checkValue(values[0], 31);
    if (values[1]) values[1] = checkValue(values[1], 12);
    if (values[2]) values[2] = checkValue(values[2], currentYear);

    const toDisplay = append(values, " / ");
    const propValue = append(values, "/");

    setValue(propValue);

    setDisplayValue(toDisplay);
  }

  function onChange(e) {
    handleSetValue(e.target.value);
  }

  return (
    <Input
      value={displayValue}
      defaultValue={defaultValue}
      maxLength={14}
      onChange={onChange}
      inputMode="numeric"
      onFocus={makeHandleFocus(isMobile)}
      placeholder={placeholder}
      name={name}
    ></Input>
  );
}
