export default (props) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="007-edit">
      <path
        id="Vector"
        d="M9.25002 15.0002V20.0002C9.25002 20.3317 9.38172 20.6496 9.61614 20.8841C9.85056 21.1185 10.1685 21.2502 10.5 21.2502H15.5C15.6645 21.2511 15.8276 21.2196 15.9799 21.1574C16.1322 21.0952 16.2707 21.0035 16.3875 20.8877L25.5 11.7877C26.2985 10.9783 26.7462 9.88711 26.7462 8.75017C26.7462 7.61324 26.2985 6.52202 25.5 5.71267L24.7875 5.00017C23.9662 4.22486 22.8795 3.79297 21.75 3.79297C20.6206 3.79297 19.5338 4.22486 18.7125 5.00017L9.61252 14.1127C9.49667 14.2295 9.40501 14.368 9.34281 14.5203C9.2806 14.6726 9.24907 14.8357 9.25002 15.0002ZM11.75 15.5127L20.5 6.76267C20.8385 6.44451 21.2855 6.26738 21.75 6.26738C22.2145 6.26738 22.6616 6.44451 23 6.76267L23.7 7.46267C24.0298 7.79512 24.2148 8.24441 24.2148 8.71267C24.2148 9.18093 24.0298 9.63022 23.7 9.96267L14.95 18.7127H11.75V15.5127Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M25.5 15C25.1685 15 24.8505 15.1317 24.6161 15.3661C24.3817 15.6005 24.25 15.9185 24.25 16.25V22.5C24.25 22.8315 24.1183 23.1495 23.8839 23.3839C23.6495 23.6183 23.3315 23.75 23 23.75H8C7.66848 23.75 7.35054 23.6183 7.11612 23.3839C6.8817 23.1495 6.75 22.8315 6.75 22.5V7.5C6.75 7.16848 6.8817 6.85054 7.11612 6.61612C7.35054 6.3817 7.66848 6.25 8 6.25H14.25C14.5815 6.25 14.8995 6.1183 15.1339 5.88388C15.3683 5.64946 15.5 5.33152 15.5 5C15.5 4.66848 15.3683 4.35054 15.1339 4.11612C14.8995 3.8817 14.5815 3.75 14.25 3.75H8C7.00544 3.75 6.05161 4.14509 5.34835 4.84835C4.64509 5.55161 4.25 6.50544 4.25 7.5V22.5C4.25 23.4946 4.64509 24.4484 5.34835 25.1517C6.05161 25.8549 7.00544 26.25 8 26.25H23C23.9946 26.25 24.9484 25.8549 25.6517 25.1517C26.3549 24.4484 26.75 23.4946 26.75 22.5V16.25C26.75 15.9185 26.6183 15.6005 26.3839 15.3661C26.1495 15.1317 25.8315 15 25.5 15Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
