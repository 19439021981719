import { headers } from "../../../core/constants";

export const getSignedUploadLink = async (code, newFile) => {
  const details = {
    code: code,
    filenames: newFile.name,
  };

  let formBody = [];
  for (const property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  try {
    const blob = await fetch(
      `/api/docs`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          ...headers,
        },
        credentials: 'include',
        body: formBody,
      }
    );

    const response = await blob.json();

    const urls = Object.values(response?.urls || {});
    if (response.ok && Array.isArray(urls) && urls) {
      return urls[0];
    }
  } catch (err) {
    console.log(err);
  }
};

export const finishUpload = (applicationData, uploadedFiles) => {
  let docs = [...applicationData.company.documents];
  uploadedFiles.forEach(([file, doc]) => {
    docs.push({
      name: file.name,
      key: doc.key,
      source: 'web'
    });
  })
  return fetch(
    `/api/leads/${applicationData?.application?.id}`,
    {
      method: "PATCH",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify({
        company: {
          documents: docs,
        },
      }),
    }
  );
};

export const removeFile = (applicationData, file) => {
  return fetch(
    `/api/leads/${applicationData?.application?.id}`,
    {
      method: "PATCH",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company: {
          documents: [
            ...applicationData.company.documents.filter(
              (doc) => doc.key !== file.key
            ),
          ],
        },
      }),
    }
  );
};

export const getS3FileUrl = async (applicationData, file) => {
  let key = file.key;
  let appId = applicationData.application.id;

  let params = new URLSearchParams()
  params.set("key", key);
  params.set("appId", appId);

  const res = await fetch(
    `/api/docs?` + params.toString(),
    {
      method: "GET",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: 'include'
    }
  );

  return await res.json();
};
