export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83951 6.60818V4.76018C7.83951 3.94336 8.16355 3.15981 8.74136 2.58323C9.31917 2.00543 10.1027 1.68018 10.9195 1.68018H17.0795C17.8963 1.68018 18.6786 2.00543 19.2564 2.58323C19.8343 3.15981 20.1595 3.94336 20.1595 4.76018V6.60818H23.2395C24.0563 6.60818 24.8386 6.93343 25.4164 7.51123C25.9943 8.08781 26.3195 8.87136 26.3195 9.68818V23.2402C26.3195 24.057 25.9943 24.8405 25.4164 25.4184C24.8386 25.9962 24.0563 26.3202 23.2395 26.3202C19.063 26.3202 8.93599 26.3202 4.7595 26.3202C3.94269 26.3202 3.15917 25.9962 2.58136 25.4184C2.00355 24.8405 1.6795 24.057 1.6795 23.2402V9.68818C1.6795 8.87136 2.00355 8.08781 2.58136 7.51123C3.15917 6.93343 3.94269 6.60818 4.7595 6.60818H7.83951ZM4.1435 14.4782V23.2402C4.1435 23.404 4.20756 23.5605 4.32337 23.6763C4.43918 23.7921 4.59565 23.8562 4.7595 23.8562H23.2395C23.4021 23.8562 23.5586 23.7921 23.6744 23.6763C23.7902 23.5605 23.8555 23.404 23.8555 23.2402V14.5644L14.379 17.637C14.1289 17.7171 13.8591 17.7171 13.609 17.6333L4.1435 14.4782ZM23.8555 11.9735V9.68818C23.8555 9.52555 23.7902 9.36909 23.6744 9.25328C23.5586 9.13747 23.4021 9.07218 23.2395 9.07218C19.063 9.07218 8.93599 9.07218 4.7595 9.07218C4.59565 9.07218 4.43918 9.13747 4.32337 9.25328C4.20756 9.36909 4.1435 9.52555 4.1435 9.68818V11.8811L14.0044 15.1681L23.8555 11.9735ZM17.6955 6.60818V4.76018C17.6955 4.59755 17.6302 4.44109 17.5144 4.32528C17.3986 4.20947 17.2421 4.14418 17.0795 4.14418C15.3177 4.14418 12.68 4.14418 10.9195 4.14418C10.7556 4.14418 10.5992 4.20947 10.4834 4.32528C10.3676 4.44109 10.3035 4.59755 10.3035 4.76018V6.60818H17.6955Z"
      fill="currentColor"
    />
  </svg>
);
