import { InfoCircleOutlined } from "@ant-design/icons";

import { COLORS } from "../../core/constants";

export default function Error({
  errors,
  size,
  iconSize,
  shortMargin,
  decreaseBottomSpacing,
  decreaseTopSpacing,
  isMobile,
  style,
}) {
  const mobileHeight = decreaseBottomSpacing && isMobile ? "45px" : "60px";

  const isErrorsEmpty =
    Array.isArray(errors) && errors.filter((err) => !!err).length === 0;

  return (
    <div
      style={{
        height: !(errors && errors[0]) && shortMargin ? "3.2vh" : mobileHeight,
        pointerEvents: "none"
      }}
    >
      <p
        style={{
          color: COLORS.red,
          opacity: 0.8,
          fontSize: size || isMobile ? "13px" : "clamp(13.6px, 1.388vh, 15px)",
          marginTop: decreaseTopSpacing ? "0px" : "10px",
          fontWeight: 500,
          display: "flex",
          alignItems: "flex-start",
          paddingLeft: "0.4px",
          textAlign: isMobile && "left",
          ...style,
        }}
      >
        {!isErrorsEmpty ? (
          <InfoCircleOutlined
            style={{
              fontSize: iconSize || isMobile ? "13px" : "15px",
              marginRight: "6px",
              marginTop: isMobile ? "1px" : "1px",
            }}
          />
        ) : (
          <></>
        )}{" "}
        {errors && errors[0]}
      </p>
    </div>
  );
}
