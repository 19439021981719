import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import MobileNumberComponent from "./MobileNumberComponent";

export default function MobileNumber({
  data,
  defaultValue,
  isMobile,
  ...props
}) {

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LerPm8qAAAAAC0MT_r6ys7Tg3IbLv2klK17UM47">
      <MobileNumberComponent
        data={data}
        defaultValue={defaultValue}
        isMobile={isMobile}
        {...props}
      />
    </GoogleReCaptchaProvider>
  );
}
