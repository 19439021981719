import { Row, Typography } from "antd";
import { COLORS } from "../core/constants";
import StepForwardButton from "./CustomerComponents/StepForwardButton";
import CTA from "./CustomerComponents/Cta";
import OTP from "./CustomerComponents/OTP";
import { LeftOutlined } from "@ant-design/icons";

const OTPStep = ({
  setValue,
  isMobile,
  onContinue,
  onCancel,
  data,
  onResend,
  isLoginEnabled,
  errors,
  title,
  submitText,
}) => {
  return (
    <>
      <Row>
        <Typography.Title
          style={{
            display: "block",
            fontWeight: "600",
            fontSize: isMobile ? "24px" : "28px",
            marginBottom: "0",
          }}
        >
          {title || 'Login by verifying your mobile number'}
        </Typography.Title>
        <div style={{ marginTop: "32px", marginBottom: "40px" }}>
          <OTP
            data={data}
            isMobile={isMobile}
            setValue={setValue}
            errors={errors}
            blockSubmit={true}
            onResend={onResend}
          />
        </div>
      </Row>
      <Row justify={"space-between"} align={"middle"} style={{ width: "100%" }}>
        <CTA
          type="text"
          style={{
            padding: 0,
            fontSize: isMobile ? "15px" : "clamp(17px, 1.9444vh, 21px)",
            color: COLORS.darkGrey,
          }}
          ctaEnabled={true}
          onClick={onCancel}
        >
          <LeftOutlined />
          Back
        </CTA>
        <StepForwardButton enabled={isLoginEnabled} onClick={onContinue}>
          {submitText || 'Login'}
        </StepForwardButton>
      </Row>
    </>
  );
};

export default OTPStep;
