import { Col, Row } from "antd";
import Label from "../../../components/CustomerComponents/Label";
import styles from "../style.module.css";
import useMediaQuery from "../../../core/hooks/useMediaQuery";
import { COLORS } from "../../../core/constants";
import { useState } from "react";
import Error from "../../../components/CustomerComponents/Error";

function groupObjects(input) {
  let result = [];
  let currentGroup = [];

  for (let i = 0; i < input.length; i++) {
    if (input[i].single === true) {
      if (currentGroup.length > 0) {
        result.push(currentGroup);
        currentGroup = [];
      }
      result.push([input[i]]);
    } else {
      currentGroup.push(input[i]);

      if (currentGroup.length === 2) {
        result.push(currentGroup);
        currentGroup = [];
      }
    }
  }

  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }

  return result;
}

const FieldRenderer = ({
  Component,
  label,
  name,
  mobileBreakpoint,
  isMobile,
  hideLabel,
  zIndex,
  errors,
  decreaseTopSpacing,
  onSelect,
  onSelectEnhancer,
  isEmpty,
  isValidated,
  ...props
}) => {
  const handleSelect = (...args) => {
    if (typeof onSelect === "function") {
      typeof onSelectEnhancer === "function" && onSelectEnhancer(name);
      onSelect(...args);
    }
  };

  const errorClassName = errors || isEmpty ? "error" : "";
  const validatedClassName = isValidated ? "validated" : "";

  return (
    <Col
      key={name}
      style={{
        flex: 1,
        maxWidth: !mobileBreakpoint && "calc(50% - 12px)",
        minWidth: mobileBreakpoint && "100%",
        position: "static",
      }}
    >
      <div
        className={["input-container", errorClassName, validatedClassName].join(
          " "
        )}
        style={
          mobileBreakpoint
            ? {
                maxHeight: "82px",
                zIndex: zIndex,
                position: "relative",
              }
            : {}
        }
      >
        {errors && (
          <div
            style={{
              paddingLeft: "4px",
              position: "absolute",
              bottom: "-4px",
              height: "0px",
              // top: decreaseTopSpacing ? "-8px" : "4px",
            }}
          >
            <Error
              errors={errors && [errors]}
              isMobile={isMobile}
              decreaseTopSpacing={true}
              iconSize={"12px"}
              style={{ fontSize: "12px" }}
            />
          </div>
        )}
        {!hideLabel && (
          <Label
            label={label}
            isMobile={isMobile}
            bg={isMobile ? "#F5F5F5" : "#FAFAFA"}
            labelStyle={{ color: undefined }}
            mobileBreakpoint={mobileBreakpoint}
          />
        )}
        <Component
          isMobile={isMobile}
          hideLabel={hideLabel}
          name={name}
          onSelect={handleSelect}
          {...props}
        />
      </div>
    </Col>
  );
};

const FieldListRenderer = ({
  isMobile,
  fieldList,
  onSelectEnhancer,
  isValidated,
}) => {
  const mobileBreakpoint = useMediaQuery("(max-width: 50em)");
  const prepareFields = (fields) => {
    const filteredFields = fields.filter((field) => field.visible !== false);

    const groupedFields = groupObjects(
      filteredFields.concat(
        filteredFields.length % 2 === 0
          ? []
          : [{ name: "spacer", hideLabel: true, Component: "div" }]
      )
    );
    return groupedFields;
  };

  return prepareFields(fieldList).map((pair, index) => (
    <Row
      justify={"start"}
      className={styles.formRow}
      style={{ zIndex: 9000 - index }}
      key={index}
    >
      {pair.map(
        (
          {
            Component,
            label,
            name,
            hideLabel,
            errors,
            decreaseTopSpacing,
            onSelect,
            ...props
          },
          pairItemIdx
        ) => (
          <FieldRenderer
            key={name}
            Component={Component}
            label={label}
            name={name}
            hideLabel={hideLabel}
            zIndex={100 - pairItemIdx}
            mobileBreakpoint={mobileBreakpoint}
            decreaseTopSpacing={decreaseTopSpacing}
            errors={errors}
            isMobile={isMobile}
            onSelect={onSelect}
            onSelectEnhancer={onSelectEnhancer}
            isValidated={isValidated}
            {...props}
          />
        )
      )}
    </Row>
  ));
};

export default FieldListRenderer;
