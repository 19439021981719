import FormPopup from "../../../../components/CustomerComponents/FormPopup";
import FormPopupLayout from "../../../../components/CustomerComponents/FormPopupLayout";
import Percentage from "../../../../components/CustomerComponents/Percentage";
import Label from "../../../../components/CustomerComponents/Label";
import StepSubTitle from "../../../../components/CustomerComponents/StepSubtitle";
import { Typography } from "antd";
import { useState, useEffect, useRef } from "react";
import Select from "../../../SinglePageForm/Components/Select";
import DateOfBirth from "../../../../components/CustomerComponents/DateOfBirth";
import StepForwardButton from "../../../../components/CustomerComponents/StepForwardButton";
import { Row, Input } from "antd";
import {
  getCompanyData,
  fetchLeadShareholders,
} from "../../../../core/services";
import SinglePageFormAddress from "../../../SinglePageForm/Components/SinglePageFormAddress";
import {
  dobValidation,
  ageValidation,
  percentageValidation,
  validateFieldRequired,
  validatePostcode,
} from "../../../SinglePageForm/utils";
import { forwardRef, useImperativeHandle } from "react";
import { submitShareholderDetails, deleteShareholder } from "../services";
import { COLORS } from "../../../../core/constants";
import Error from "../../../../components/CustomerComponents/Error";

const formatResidentialPostcode = (shareholder) => {
  if (shareholder.residentialAddress?.address) {
    return {
      residential_address: shareholder.residentialAddress,
      residential_address_manual: null,
    };
  } else {
    return {
      residential_address: JSON.stringify(shareholder.residentialAddress),
      residential_address_manual: {
        address: false,
        city: shareholder.residentialCity,
        county: shareholder.residentialCounty,
        house_number: shareholder.residentiaHouseNumber,
        postcode: shareholder.residential_postode_manual,
        street: shareholder.residentialStreet,
      },
    };
  }
};

const getResidentialPostcode = (s) => {
  if (s.residential_address_manual) {
    return {
      residentialCity: s.residential_address_manual?.city,
      residentialCounty: s.residential_address_manual?.county,
      residentiaHouseNumber: s.residential_address_manual?.house_number,
      residentialStreet: s.residential_address_manual?.street,
      residentialAddress: { address: false, postcode: "tw153dj" },
    };
  } else {
    return {
      residentialAddress: s.residential_address,
      residential_postode_manual: s.residential_address?.postcode,
    };
  }
};

const ShareholderDetails = forwardRef(
  (
    {
      isMobile,
      shareholder,
      setShareholder,
      directorOptions,
      removable,
      onRemove,
    },
    ref
  ) => {
    const [residentialAddressInputValue, setResidentialAddressInputValue] =
      useState("");

    const fields = [
      {
        name: "director",
        label: "Name",
        Component: Select,
        options: directorOptions,
        placeholder: "Select Name",
        selectedId: shareholder.director,
        onSelect: (id) => setShareholder({ ...shareholder, director: id }),
        single: true,
        defaultValue: shareholder.director,
        errors: validateFieldRequired(shareholder.director, true),
      },
      {
        name: "dob",
        label: "Date of birth",
        Component: DateOfBirth,
        placeholder: "dd/mm/yyyy",
        data: { loanAmount: undefined },
        setValue: (val) => setShareholder({ ...shareholder, dob: val }),
        value: shareholder.dob,
        single: true,
        errors: dobValidation(shareholder.dob, true),
        validation: dobValidation(shareholder.dob, false),
        defaultValue: shareholder.dob,
      },
      {
        name: "percentage",
        label: "Shareholding %",
        Component: Percentage,
        placeholder: "% of shares owned",
        data: { loanAmount: undefined },
        setValue: (val) => setShareholder({ ...shareholder, percentage: val }),
        value: shareholder.percentage,
        single: true,
        errors: percentageValidation(shareholder.percentage, true),
        validation: percentageValidation(shareholder.percentage, false),
      },
      {
        name: "residentialAddress",
        label: "Residential address",
        data: shareholder,
        Component: SinglePageFormAddress,
        placeholder: "Shareholder's postcode",
        setData: setShareholder,
        defaultValue: JSON.stringify(shareholder.residentialAddress),
        setValue: (val) => {
          setShareholder({
            ...shareholder,
            residentialAddress: JSON.parse(val),
          });
          // if (val !== "{}") {
          //   onSelectEnhancer("residentialAddress");
          // }
        },
        onInputValueChange: setResidentialAddressInputValue,
        errors:
          shareholder?.residentialAddress?.address === false
            ? validatePostcode(shareholder.residential_postode_manual, true)
            : validatePostcode(shareholder.residentialAddress?.postcode, true),
        validation:
          residentialAddressInputValue.length === 0
            ? null
            : shareholder?.residentialAddress?.address === false
            ? validatePostcode(shareholder.residential_postode_manual, true)
            : validatePostcode(shareholder.residential_postode_manual, true),
        setError: () => null,
        value: shareholder.residentialAddress,
        manualPostcodeName: "residential_postode_manual",
        defaultInputValue:
          shareholder?.residentialAddress?.address === false
            ? shareholder.residentialAddress.postcode
            : undefined,
      },
      {
        name: "residentiaHouseNumber",
        label: "House number/name",
        data: shareholder,
        Component: Input,
        placeholder: "",
        placeholder: "Shareholder's house number",
        onChange: (e) =>
          setShareholder({
            ...shareholder,
            residentiaHouseNumber: e.target.value,
          }),
        value: shareholder.residentiaHouseNumber,
        visible: shareholder?.residentialAddress?.address === false,
        errors: validateFieldRequired(shareholder.residentiaHouseNumber, true),
      },
      {
        name: "residentialStreet",
        label: "Street",
        data: shareholder,
        Component: Input,
        placeholder: "",
        placeholder: "Shareholder's street",
        onChange: (e) =>
          setShareholder({ ...shareholder, residentialStreet: e.target.value }),
        value: shareholder.residentialStreet,
        visible: shareholder?.residentialAddress?.address === false,
        errors: validateFieldRequired(shareholder.residentialStreet, true),
      },
      {
        name: "residentialCity",
        label: "City",
        data: shareholder,
        Component: Input,
        placeholder: "",
        placeholder: "Shareholder's city",
        onChange: (e) =>
          setShareholder({ ...shareholder, residentialCity: e.target.value }),
        value: shareholder.residentialCity,
        visible: shareholder?.residentialAddress?.address === false,
        errors: validateFieldRequired(shareholder.residentialCity, true),
      },
      {
        name: "residentialCounty",
        label: "County",
        data: shareholder,
        Component: Input,
        placeholder: "Shareholder's county",
        onChange: (e) =>
          setShareholder({ ...shareholder, residentialCounty: e.target.value }),
        value: shareholder.residentialCounty,
        visible: shareholder?.residentialAddress?.address === false,
        errors: validateFieldRequired(shareholder.residentialCounty, true),
      },
    ];

    const hasErrors = () => {
      const errors = fields
        .filter((f) => f.visible !== false)
        .map((f) => f.errors);
      return errors.some((err) => !!err);
    };

    useImperativeHandle(ref, () => ({
      hasErrors,
    }));

    return (
      <div style={{ maxWidth: "560px" }}>
        <Row
          style={{
            margin: "20px 0 0px",
          }}
          align={"middle"}
          justify={"space-between"}
        >
          <Typography.Text
            style={{
              fontWeight: "600",
              fontSize: isMobile ? "16px" : "18px",
              display: "block",
            }}
          >
            {removable
              ? "Additional shareholder"
              : "Add details of another shareholder"}
          </Typography.Text>
          {removable && (
            <div role="button" className="text-button" onClick={onRemove}>
              <span
                style={{
                  transform: "scale(1.4) translateY(-0.5px)",
                  display: "inline-block",
                  marginRight: "0.2em",
                }}
              >
                -
              </span>
              Remove
            </div>
          )}
        </Row>
        {fields.map(
          (
            { Component, label, visible, withSubtitle, validation, ...props },
            index
          ) =>
            visible !== false ? (
              <div
                style={{
                  maxHeight: "72.5px",
                  position: "relative",
                  zIndex: 1000 - index,
                  marginBottom: withSubtitle ? "40px" : "24px",
                  marginTop: "-4px",
                  maxWidth: "530px",
                  maxHeight: "82px",
                }}
              >
                {validation && (
                  <div style={{ position: "absolute", bottom: "-60px" }}>
                    <Error
                      errors={validation && [validation]}
                      isMobile={isMobile}
                      decreaseTopSpacing={true}
                      iconSize={"12px"}
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                )}
                <Label bg={"#fafafa"} isMobile={isMobile} label={label}></Label>
                <Component {...props} isMobile={isMobile}></Component>
              </div>
            ) : (
              <></>
            )
        )}
      </div>
    );
  }
);

const createShareholderObject = () => {
  return {
    director: "",
    dob: "",
    percentage: null,
    residentialAddress: null,
    residentiaHouseNumber: "",
    residentialStreet: "",
    residentialCity: "",
    residentialCounty: "",
  };
};

const ShareholderDetailsForm = ({
  visible,
  onClose,
  isMobile,
  companyId,
  onSubmit,
  leadId,
}) => {
  const [shareholders, setShareholders] = useState([createShareholderObject()]);
  const shareholderItemsRef = useRef([]);
  const [hasErrors, setHasErrors] = useState(true);
  const [isFirstShareholderFilled, setIsFirstShareholderFilled] =
    useState(false);
  const [directorOptions, setDirectorOptions] = useState([]);
  const [leadShareholdingInfo, setLeadShareholdingInfo] = useState(null);
  const [isSubmitting, setSubmit] = useState(false);
  const [insufficientShareholders, setInsufficientShareholders] =
    useState(false);

  const setShareholder = (shareholder, index) => {
    setShareholders([
      ...shareholders.slice(0, index),
      shareholder,
      ...shareholders.slice(index + 1),
    ]);
  };

  useEffect(() => {
    async function getData() {
      const s = await fetchLeadShareholders(leadId);
      setLeadShareholdingInfo(s.data);

      setDirectorOptions(
        s.data.psc.map((psc) => ({
          id: psc.id,
          text: psc.name,
        }))
      );

      if (s?.data?.shareholders && s?.data?.shareholders.length > 0) {
        const initialShareholders = s.data.shareholders.map((s) => ({
          dob: s.dob,
          director: s?.pscId,
          percentage: s?.shareholding,
          ...getResidentialPostcode(s),
        }));
        setShareholders(initialShareholders);
      }
    }
    if (leadId) getData();
  }, []);

  useEffect(() => {
    shareholderItemsRef.current = shareholderItemsRef.current.slice(
      0,
      shareholders.length
    );
  }, [shareholders.length]);

  useEffect(() => {
    let errors = false;
    for (let i = 0; i < shareholderItemsRef.current.length; i++) {
      const item = shareholderItemsRef.current[i];
      const hasErrors = item?.hasErrors;
      if (typeof hasErrors === "function") {
        const _err = hasErrors();
        if (i === 0) {
          setIsFirstShareholderFilled(!_err);
        }
        if (_err) {
          errors = true;
        }
      }
    }
    setHasErrors(errors);
  }, [shareholders]);

  const addNewShareholder = () => {
    if (isFirstShareholderFilled) {
      setShareholders([...shareholders, createShareholderObject()]);
    }
  };

  const removeShareHolder = (index) => {
    setShareholders(shareholders.filter((_, i) => i !== index));
  };

  const applicantShareholderPercentage =
    leadShareholdingInfo?.applicantShareholder?.shareholding || 0;

  const totalShareholderPercentage =
    shareholders.reduce(
      (acml, shareholder) => shareholder.percentage + acml,
      0
    ) + applicantShareholderPercentage;

  const allDirectorOptionsUsed = directorOptions.length === shareholders.length;

  const filterDirectorOptions = (shareholder, options) => {
    const hasSelectedOption = shareholder.director;
    const shareholdersIds = shareholders.map((s) => s.director);

    if (hasSelectedOption) {
      return [
        ...options.filter((o) => {
          return (
            !shareholdersIds.includes(o.id) || o.id === shareholder.director
          );
        }),
      ];
    }

    return options.filter((o) => !shareholdersIds.includes(o.id));
  };

  const handleSubmit = async () => {
    if (totalShareholderPercentage < 50) {
      setInsufficientShareholders(true);
      return console.log("error");
    }

    setSubmit(true);

    const shareholderPscIdList = shareholders.map((s) => s.director);
    const shareholdersToDeletelead = leadShareholdingInfo.shareholders
      .filter((s) => !shareholderPscIdList.includes(s.pscId))
      .map((s) => deleteShareholder(s.id, leadId));

    const updateShareholderRequestBody = shareholders
      .filter((s) => shareholderPscIdList.includes(s.director))
      .map((s) =>
        submitShareholderDetails(
          {
            dob: s.dob,
            pscId: s.director,
            postcode: s.residential_postode_manual,
            shareholding: s.percentage,
            ...formatResidentialPostcode(s),
          },
          leadId,
          leadShareholdingInfo.shareholders.find(
            (_s) => _s.pscId === s.director
          )?.id
        )
      );

    const responses = await Promise.all([
      ...updateShareholderRequestBody,
      ...shareholdersToDeletelead,
    ]);

    const finalResponse = responses[responses.length - 1];

    setLeadShareholdingInfo({
      ...leadShareholdingInfo,
      ...finalResponse.data,
    });

    onSubmit();
    setSubmit(false);
  };

  return (
    <FormPopup
      visible={visible}
      isMobile={isMobile}
      onClose={onClose}
      persistent={true}
      className={!isMobile && "scrollbar"}
    >
      <div
        className={[
          "single-page-form light",
          !isMobile ? "scrollbar" : "",
        ].join(" ")}
      >
        <Typography.Title
          style={{
            fontSize: isMobile ? "22px" : "clamp(24px, 2.461vh, 28px)",
            marginTop: isMobile && "4px",
            marginBottom: "30px",
            marginTop: "32px",
            paddingLeft: isMobile ? "8px" : "60px",
            fontWeight: 600,
          }}
        >
          Additional Details
        </Typography.Title>
        {insufficientShareholders && (
          <span
            style={{
              paddingLeft: isMobile ? "8px" : "60px",
              maxWidth: "530px",
              display: "block",
              color: COLORS.red,
              fontWeight: "500",
              fontSize: "14px",
              background: "#fafafa",
              position: "relative",
              top: "-20px",
            }}
          >
            Add more shareholders. We need details of all shareholder(s)
            together holding 50% of the company share
          </span>
        )}
        <div
          style={{
            maxHeight: isMobile ? "calc(100vh - 318px)" : "calc(100vh - 258px)",
            minWidth: !isMobile && "560px",
            overflow: "auto",
            paddingLeft: isMobile ? "8px" : "60px",
            marginRight: isMobile ? "0px" : "30px",
            paddingRight: isMobile ? "8px" : "0",
          }}
        >
          {shareholders.map((shareholder, index) => (
            <div
              style={{
                position: "relative",
                zIndex: 1000 - index,
                maxWidth: "530px",
                margin: isMobile && "0 auto",
              }}
              key={index}
            >
              <ShareholderDetails
                isMobile={isMobile}
                bg={"#fafafa"}
                ref={(el) => (shareholderItemsRef.current[index] = el)}
                companyId={companyId}
                shareholder={shareholder}
                onRemove={() => removeShareHolder(index)}
                removable={index >= 1}
                setShareholder={(s) => setShareholder(s, index)}
                directorOptions={filterDirectorOptions(
                  shareholder,
                  directorOptions
                )}
              />
              {index - 1 !== shareholders.length && (
                <div style={{ height: "12px" }}></div>
              )}
            </div>
          ))}
        </div>
        <Row
          style={{
            marginTop: isMobile && shareholders.length <= 1 ? "38px" : "24px",
            marginBottom: "24px",
            paddingLeft: isMobile ? "8px" : "60px",
            paddingRight: isMobile ? "8px" : "60px",
          }}
          justify={"space-between"}
          align={"middle"}
        >
          <div
            style={{
              display: "flex",
              alignItems: isMobile ? "flex-start" : "center",
              gap: "6px",
              flexDirection: isMobile && "column",
            }}
          >
            {isFirstShareholderFilled && !allDirectorOptionsUsed && (
              <div
                className={"text-button"}
                role="button"
                onClick={addNewShareholder}
              >
                <span
                  style={{
                    transform: "scale(1.4) translateY(-0.4px)",
                    display: "inline-block",
                    marginRight: "0.2em",
                  }}
                >
                  +
                </span>
                Add shareholder
              </div>
            )}
          </div>

          <StepForwardButton
            onClick={handleSubmit}
            enabled={
              !hasErrors &&
              !(insufficientShareholders && totalShareholderPercentage < 50)
            }
            loading={isSubmitting}
          >
            Submit
          </StepForwardButton>
        </Row>
      </div>
    </FormPopup>
  );
};

export default ShareholderDetailsForm;
