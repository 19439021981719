import { useState } from "react";
import Address from "./Address";
import { COLORS } from "../../../core/constants";

const SinglePageFormAddress = ({ onSelect, defaultInputValue, onInputValueChange, ...props }) => {
  const [hideOptions, setHideOptions] = useState(true);

  const handleSearch = (postcode) => {
    setHideOptions(false);
  };

  const handleSelect = () => {
    setHideOptions(true);
  };

  const onClear = () => {
    setHideOptions(true);
  }

  const handleFocus = () => {
    setHideOptions(false);
  };

  return (
    <Address
      onSearch={handleSearch}
      onSelect={handleSelect}
      onFocus={handleFocus}
      hideOptions={hideOptions}
      onClear={onClear}
      onInputValueChange={onInputValueChange}
      hideEmptyOption={true}
      appendOptions={true}
      noGap={true}
      defaultInputValue={defaultInputValue}
      emptyOptionStyle={{
        background: COLORS.white,
        boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
        padding: "10px 20px 10px",
        marginTop: 0,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        position: 'relative',
        top: "-12.5px",
        border: '1.5px solid #212121',
        borderTop: '0',
        color: '#212121',
      }}
      optionsStyle={{
        borderRadius: "0px",
      }}
      hasBoxShadow={true}
      {...props}
    />
  );
};

export default SinglePageFormAddress;
