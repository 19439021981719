export default function Label({ label, isMobile, disabled, fontSize, bg, labelStyle }) {
  return (
    <div
      style={{
        position: "relative",
        paddingLeft: "10px",
        width: "max-content",
        paddingRight: "10px",
        backgroundColor: bg || "#ffffff",
        top: "0.5em",
        left: "clamp(6px, 0.7047vh, 8px)",
        order: 0,
        zIndex: 98,
      }}
      className='field-label'
    >
      <span
        style={{
          color: disabled ? "#777" : "#C60029",
          fontWeight: 600,
          fontSize: isMobile
            ? "12px"
            : fontSize || "clamp(14px, 1.4814vh, 16px)",
          ...labelStyle
        }}
      >
        {label}
      </span>
    </div>
  );
}
