import { Col, Button, Row, Typography } from "antd";
import { Input } from "antd";
import { makeHandleFocus } from "../../core/utils";

import { useEffect, useRef, useState } from "react";

import Timer from "./Timer";
import StepSubTitle from "./StepSubtitle";
import Error from "./Error";

import "dayjs/locale/en-gb";

import { QuestionCircleOutlined } from "@ant-design/icons";

import { COLORS } from "../../core/constants";

export default function OTP(props) {
  const [sent, setSent] = useState(false);
  const [enableResend, setEnableResend] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const errors = props.errors;
  const cancelKeyup = useRef(0);

  const { data } = props;
  let refs = [];
  refs[0] = useRef();
  refs[1] = useRef();
  refs[2] = useRef();
  refs[3] = useRef();

  useEffect(() => {
    if (!sent) {
      setSent(true);
    }
  }, [props.data.mobile_number]);

  useEffect(() => {
    // Focus the first input element on mount
    if (refs[0] && refs[0].current) {
      refs[0].current.focus();
    }
  }, []);

  async function submit(parts) {
    const submittedOtp = parts.join("");
    props.setValue && props.setValue(submittedOtp);
  }

  function onKeyDown(event) {
    const allowedKeys = ['Backspace', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  
    const key = event.key || String.fromCharCode(event.keyCode);
  
    // Allow Ctrl+V, Ctrl+C, Ctrl+X by checking event.ctrlKey
    if (event.ctrlKey && ['v', 'c', 'x'].includes(key.toLowerCase())) {
      return; // Allow these shortcuts
    }
  
    // Prevent default behavior if the key is not in the allowed list
    if (!allowedKeys.includes(key) && !event.ctrlKey) {
      event.preventDefault();
    }
  }
  
  function onKeyUp(event, n) {
    const allowedKeys = ['Backspace', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const key = event.key || String.fromCharCode(event.keyCode);
  
    if (!allowedKeys.includes(key)) {
      return; // Exit if the key is not allowed
    }
  
    // Decrement cancelKeyup.current if greater than 0
    if (cancelKeyup.current > 0) {
      cancelKeyup.current -= 1;
      return;
    }
  
    // Move focus based on the key pressed
    if (key === 'Backspace' && n > 0) {
      setTimeout(() => refs[n - 1]?.current?.focus(), 10);
    } else if (key !== 'Backspace' && n < refs.length - 1) {
      setTimeout(() => refs[n + 1]?.current?.focus(), 10);
    }
  
    // Submit the form with the values from the refs
    submit(refs.map((r) => r.current?.input?.value || ""));
  }
  
  
  function resend() {
    typeof props.onResend === "function" &&
      props.onResend(props.data.mobile_number);
    setEnableResend(false);
    setTimerKey(timerKey + 1);
  }

  function handleFinishCountdown() {
    setEnableResend(true);
  }

  function handleChange(e, n) {
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const stringValue = String(e.target.value);

    if (stringValue.length === 4) {
      cancelKeyup.current = 2;
      refs[0].current.input.value = stringValue[0];
      refs[1].current.input.value = stringValue[1];
      refs[2].current.input.value = stringValue[2];
      refs[3].current.input.value = stringValue[3];
      submit(refs.map((r) => r.current?.input?.value || ""));
      setTimeout(() => {
        refs[0].current.input.value = stringValue[0];
        refs[1].current.input.value = stringValue[1];
        refs[2].current.input.value = stringValue[2];
        refs[3].current.input.value = stringValue[3];
        submit(refs.map((r) => r.current?.input?.value || ""));
      });
    }

    e.target.value = stringValue[0];

    setTimeout(() => {
      if (n === 3) {
        e.target.blur();
      }
    }, 250);
  }

  return (
    <Row>
      <Col span="24">
        <Row justify={"space-between"} align={"top"}>
          <Col>
            <StepSubTitle
              fontSize={
                props.isMobile ? "12.4px" : "clamp(13.6px, 1.388vh, 15px)"
              }
              lineHeight={"20px"}
            >
              Please enter the 4-digit code sent to{" "}
              <span
                style={{
                  color: COLORS.red,
                  fontWeight: 600,
                }}
              >
                {data?.mobile_number ?? ""}
              </span>
            </StepSubTitle>
          </Col>
        </Row>
      </Col>
      <Col
        span="24"
        style={{
          marginTop: props.isMobile ? "40px" : "clamp(70px, 7.407vh, 80px)",
          marginBottom: props.isMobile ? "40px" : "clamp(70px, 7.407vh, 80px)",
        }}
      >
        <Row gutter={20} justify="center" wrap={false}>
          {[0, 1, 2, 3].map((n) => {
            return (
              <Col key={n}>
                <Input
                  ref={refs[n]}
                  size="2"
                  placeholder=" "
                  className="otp-input"
                  inputMode="numeric"
                  style={{
                    height: props.isMobile
                      ? "52px"
                      : "clamp(59px, 6.39vh, 69px)",
                    width: props.isMobile
                      ? "52px"
                      : "clamp(59px, 6.39vh, 69px)",
                    textAlign: "center",
                    background: "white",
                  }}
                  onKeyDown={onKeyDown}
                  onInput={(e) => handleChange(e, n)}
                  onFocus={makeHandleFocus(props.isMobile)}
                  onKeyUp={(event) => onKeyUp(event, n)}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col span="24">
        <Row
          style={{ gap: "8px" }}
          align={errors.length ? "bottom" : "middle"}
          justify={"space-between"}
        >
          <Col span={24}>
            {errors.length ? (
              <Error
                errors={errors}
                iconSize={props.isMobile ? "14px" : "16px"}
              />
            ) : (
              ""
            )}

            <Row
              style={{
                color: "#777777",
                opacity: !enableResend ? 0 : 1,
                visibility: !enableResend ? "hidden" : "initial",
              }}
              align={"middle"}
              justify={"flex-start"}
              wrap={false}
            >
              <Row>
                <QuestionCircleOutlined
                  style={{
                    fontSize: props.isMobile ? "14px" : "16px",
                    marginBottom: "1px",
                    marginRight: "8px",
                  }}
                />
                <Typography.Text
                  style={{
                    color: "#777777",
                    fontWeight: 500,
                    fontSize: props.isMobile
                      ? "12.4px"
                      : "clamp(13.6px, 1.388vh, 15px)",
                  }}
                >
                  Didn't receive the code?
                </Typography.Text>
              </Row>

              <Button
                type="link"
                style={{
                  color: COLORS.red,
                  fontWeight: 600,
                  paddingLeft: "8px",
                  paddingRight: "0",
                  fontSize: props.isMobile
                    ? "12.4px"
                    : "clamp(13.6px, 1.388vh, 15px)",
                }}
                onClick={resend}
              >
                Resend
              </Button>
              {!enableResend && (
                <div
                  style={{
                    opacity: 0,
                    visibility: "hidden",
                    height: 0,
                    width: 0,
                  }}
                >
                  <Timer
                    onFinishCountdown={handleFinishCountdown}
                    key={timerKey}
                  />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
