import { useState } from "react";
import { Typography, Row, Input } from "antd";

import FormPopup from "./FormPopup";
import FormPopupLayout from "./FormPopupLayout";
import Label from "../../components/CustomerComponents/Label";
import WhatsAppIcon from "../icons/WhatsAppIcon";
import { InfoCircleOutlined } from "@ant-design/icons";

import { COLORS } from "../../core/constants";

const validatePhoneNumber = (value) => {
  if (value.length < 11) {
    return false;
  } else if (!String(value).startsWith("07")) {
    return false;
  } else {
    return true;
  }
};

const SendMobileDocumentPopup = ({
  visible,
  isMobile,
  onClose,
  onSubmit,
  number,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(number || "");
  const [whatsAppNumber, setWhatsAppNumber] = useState(null);

  const handleSubmit = (channel) => {
    onSubmit(channel, phoneNumber, whatsAppNumber);
    onClose();
  };

  return (
    <FormPopup visible={visible} onClose={onClose} isMobile={isMobile}>
      <FormPopupLayout
        onClose={onClose} 
        isMobile={isMobile}
        onCancel={onClose}
        submitEnabled={
          whatsAppNumber === null
            ? validatePhoneNumber(phoneNumber)
            : validatePhoneNumber(whatsAppNumber)
        }
        title={"Upload documents from mobile"}
        subtitles={[
          "To upload from WhatsApp, you'll receive a message from LoanTube on your WhatsApp and you can send us your documents there.",
          "To upload from mobile, you'll receive a text message with a link where you can upload your documents either by taking a photo from your phone or by selecting files from your device.",
        ].slice(0, isMobile ? 1 : 2)}
        submitText={"Send WhatsApp"}
        secondarySubmit={"Send SMS"}
        secondarSubmitEnabled={validatePhoneNumber(phoneNumber)}
        onSecondarySubmit={() => handleSubmit("sms")}
        onSubmit={() => handleSubmit("whatsapp")}
      >
        <Row>
          <Label label={"Mobile number"} isMobile={isMobile} />
          <Input
            style={{ width: "100%" }}
            onChange={(e) => setPhoneNumber(e.target.value)}
            maxLength={11}
            defaultValue={phoneNumber}
          ></Input>
        </Row>

        {whatsAppNumber !== null && (
          <>
            <Row style={{ marginTop: "8px" }}>
              <Label label={"WhatsApp number"} isMobile={isMobile} />
              <Input
                style={{ width: "100%" }}
                onChange={(e) => setWhatsAppNumber(e.target.value)}
                maxLength={11}
              ></Input>
            </Row>
            <Row justify={"end"}>
              <Typography.Text
                style={{
                  fontSize: "14px",
                  color: COLORS.red,
                  fontWeight: 500,
                  cursor: "pointer",
                  marginTop: "6.5px",
                }}
                onClick={() => setWhatsAppNumber(null)}
              >
                remove WhatsApp number
              </Typography.Text>
            </Row>
          </>
        )}

        {whatsAppNumber === null && (
          <Row
            align={"middle"}
            justify={"space-between"}
            style={{ marginTop: "65px" }}
          >
            <Row align={"middle"}>
              <InfoCircleOutlined
                style={{
                  fontSize: isMobile ? "14px" : "15px",
                  color: COLORS.darkGrey,
                  marginRight: "6px",
                }}
              />
              <Typography.Text
                style={{ fontSize: "14px", color: COLORS.darkGrey }}
              >
                Have a different number on WhatsApp?
              </Typography.Text>
            </Row>

            <Row
              align={"middle"}
              style={{ flexDirection: isMobile && "row-reverse" }}
            >
              <div
                style={{
                  position: "relative",
                  top: "0.1em",
                  cursor: "pointer",
                }}
                onClick={() => setWhatsAppNumber("")}
              >
                <WhatsAppIcon
                  width={isMobile ? "36px" : "32px"}
                  height={isMobile ? "36px" : "32px"}
                />
              </div>
              <div
                style={{ width: "4px", cursor: "pointer", height: "36px" }}
                onClick={() => setWhatsAppNumber("")}
              ></div>

              <Typography.Text
                style={{
                  fontSize: "14px",
                  color: COLORS.red,
                  fontWeight: 500,
                  cursor: "pointer",
                }}
                onClick={() => setWhatsAppNumber("")}
              >
                add WhatsApp number
              </Typography.Text>
            </Row>
          </Row>
        )}
      </FormPopupLayout>
    </FormPopup>
  );
};

export default SendMobileDocumentPopup;
