import { snakeCase, range, last, replace } from "lodash";
import { namesFuzzyMatch } from "person-name-match";
function stripSpecialChars(str) {
  if (!str) return "";
  return str.replace(/[^\w\s]/gi, '');
}
export function simpleNameMatch(name,name2){
  let dirName = name.toLowerCase();
  dirName = stripSpecialChars(dirName);
  const nameParts = dirName.split(" ");
  if (nameParts[0] === 'Mike') {
    nameParts[0] = 'Michael';
  }
  let inputObj = {
    "firstName": nameParts[0],
    "lastName": nameParts[nameParts.length - 1]
  };
  const threshold = 0.65;

  let companiesHouseString = name2;
    if(!companiesHouseString){
      return false;
    }
    let res = namesFuzzyMatch(inputObj, companiesHouseString);
    const matches = res.matches.filter((item) => {
      if (!item.match) return false
      return item.match.score > threshold
    });

    const isMatch = matches.length >= 2;
    return isMatch;
}
export function getName(props) {
  if (!props.name) {
    props.name = snakeCase(props.label);
  }

  return props.name;
}

export const formatCurrency = (amount) => {
  if (!amount) return;
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits : 0,
    maximumFractionDigits: 0
  }).format(amount);
};

export const formatDate = (dateString) => {
  if (!dateString) return;
  return new Intl.DateTimeFormat("en-UK").format(new Date(dateString));
};

export const makeHandleFocus = (isMobile, multiplier, callback) => () => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  if (isMobile && !isIOS) {
    const topRow = document.querySelector(".top-row");

    if (topRow) {
      const topRowHeight = topRow.clientHeight;

      const isFirefox = navigator.userAgent.search("Firefox") >= 0;

      if (isFirefox) {
        window.scrollTo(0, topRowHeight * (multiplier || 1.78));
      } else {
        window.scrollTo(0, topRowHeight);
      }
    }
  }
  typeof callback === 'function' && callback();
};

export const getLoanPurpose = (purpose) => {
  if (purpose === "business_growth") return "Business Growth";
  else if (purpose === "existing_loan_refinance") return "Refinance";
  else if (purpose === "stock_inventory_purchase") return "Inventory purchase";
  else if (purpose === "vehicle_purchase") return "Vehicle purchase";
  else if (purpose === "additional_cashflow") return "Additional Cashflow";
  else if (purpose === "plant_machinery_purchase")
    return "Plant & Machinery Purchase";
  else return "Other Purposes";
};

export const getResidentialStatus = (status) => {
  if (status === "Non Home Owner") {
    return "Non-homeowner";
  } else if (status === "Home Owner (Mortaged)") {
    return "Homeowner, with a mortgage";
  } else if (status === "Home Owner (Mortgaged)") {
    return "Homeowner, with a mortgage";
  } else if (status === "Home Owner (Mortage Free)") {
    return "Homeowner, without a mortgage";
  } else if (status === "Home Owner (Mortgage Free)") {
    return "Homeowner, without a mortgage";
  }
  return "";
};

export const getPostcode = (postcode, postcodeManual) => {
  if (postcode) return postcode;

  if (!postcodeManual) return ""

  return `${postcodeManual.house_number} ${postcodeManual.street}, ${postcodeManual.city} ${postcodeManual.county}`;
};

export const getInvoiceReceipts = (invoices) =>
  invoices ? "Yes, invoices are sent to customers" : "£0";

export const getTradingSince = (tradingSince) => {
  return "";
};

export const getCardReceipts = (accept, cardAmount, revenue) => {
  if (!accept || cardAmount === "0" || !cardAmount || !revenue) return "£0";

  const amount = parseFloat(cardAmount);
  const r = parseFloat(revenue);

  if (amount <= r * 0.25) return "up to 25% of sales";
  else if (amount <= r * 0.5) return "25% - 50% of sales";
  else if (amount <= r * 0.75) return "50% - 75% of sales";
  else return "more than 75% of sales";
};

export const getBusinessAge = (age) => {
  if (age === "1 - 2 years months") {
    return "1 - 2 years";
  } else if (age === "2 - 3 years months") {
    return "2 - 3 years";
  }
  return age;
};

export const handleOtpAutoFocus = () => {
 const fakeInput = document.createElement("input");
 fakeInput.setAttribute("type", "text");
 fakeInput.setAttribute("readonly", "true");
 fakeInput.style.position = "absolute";
 fakeInput.style.opacity = 0;
 fakeInput.style.height = 0;
 fakeInput.style.fontSize = "16px";

 document.body.prepend(fakeInput);

 // focus so that subsequent async focus will work
 fakeInput.focus();

 setTimeout(() => {
   const otpInput = document.querySelector(".otp-input");
   if (otpInput) {
     otpInput.focus();
   }
   fakeInput.remove();
 }, 350);
};