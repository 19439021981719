import { COLORS } from "../../core/constants";
import PhoneIcon from "./PhoneIcon";
import { Typography, Row } from "antd";

export default function PhoneNumberInfo({ isMobile, style, iconStyle, numberMarginTop }) {
  return (
    <a
      href={isMobile ? "tel:0208-088-5001" : "javascript:void(0)"}
      style={{
        color: "inherit",
        textDecoration: isMobile ? "underline" : "none",
        textDecorationColor: COLORS.red,
        textDecorationThickness: "1.5px",
        marginTop: numberMarginTop ?? "0px"
      }}
    >
      <Row align={"middle"}>
        <span
          style={{
            color: COLORS.red,
            marginRight: isMobile ? "6px" : "10px",
            width: isMobile ? "13px" : "clamp(14px, 1.85vh, 17px)",
            height: "min-content",
            overflow: "visible",
            position: "relative",
            top: isMobile ? "2px" : "1px",
            ...iconStyle,
          }}
        >
          <PhoneIcon />
        </span>{" "}
        <Typography.Text
          style={{
            fontSize: isMobile
              ? "clamp(13px, 1.895vh, 16px)"
              : "clamp(18px, 2.1vh, 23px)",
            fontFamily: "Poppins",
            ...style,
          }}
          strong
        >
          020 8088 5001
        </Typography.Text>
      </Row>
    </a>
  );
}
