import { Typography, Col, Row, Divider } from "antd";
import { useOutletContext } from "react-router-dom";
import MailIcon from "../../components/icons/Mail";
import PhoneIcon from "../../components/CustomerComponents/PhoneIcon";
import { COLORS } from "../../core/constants";
import {useEffect} from 'react';
const DashboardView = () => {
  const { data, isMobile, applicationData, isSidebarVisible } =
    useOutletContext();
    useEffect(() => {
      document.title = "LoanTube: Contact Us"
    }, [])
  return (
    <Col className="dashboard-layout">
      <Typography.Title
        style={{
          fontSize: isMobile ? "24px" : "30px",
          marginBottom: isMobile ? "24px" : "74px",
          marginTop: isMobile && "14px",
          paddingLeft: isMobile && "64px",
          opacity: !isSidebarVisible ? "0" : "1",
          transition: "opacity 100ms ease",
        }}
      >
        Contact us
      </Typography.Title>
      <Row justify={"start"} wrap={true} style={{ flexWrap: "wrap-reverse" }}>
        <Col
          span={!isMobile && "12"}
          style={{
            marginRight: !isMobile && "30px",
            minWidth: isMobile ? "100%" : "550px",
            marginBottom: "40px",
          }}
        >
          <Row align={"middle"} style={{ marginBottom: "21px" }}>
            <div
              style={{
                border: `3px solid ${COLORS.red}`,
                borderRadius: "10px",
                width: isMobile ? "45px" : "60px",
                height: isMobile ? "45px" : "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <img
                src="/assets/logo-art.svg"
                height={!isMobile ? 63 : 48.6}
                width={!isMobile ? 44 : 33.936}
              />
            </div>
            <Col>
              <Typography.Text
                style={{
                  color: COLORS.black,
                  fontSize: isMobile ? "20px" : "24px",
                  display: "block",
                }}
              >
                LoanTube is trading name of
              </Typography.Text>
              <Typography.Text
                style={{
                  color: COLORS.black,
                  fontSize: isMobile ? "20px" : "24px",
                  fontWeight: 600,
                }}
              >
                Tiger Lion Financial Limited
              </Typography.Text>
            </Col>
          </Row>

          <Typography.Text
            style={{
              color: "#8e8e8e",
              display: "block",
              fontStyle: "italic",
              marginBottom: "4px",
              fontSize: isMobile && "14px",
            }}
          >
            Authorised and regulated by Financial Conduct Authority (FCA)
          </Typography.Text>
          <Typography.Text
            style={{
              color: "#8e8e8e",
              display: "block",
              fontStyle: "italic",
              marginBottom: "4px",
              fontWeight: 600,
              fontSize: isMobile && "14px",
            }}
          >
            FCA Firm Reference Number:{" "}
            <span style={{ color: COLORS.red }}>753151</span>
          </Typography.Text>
          <Typography.Text
            style={{
              color: "#8e8e8e",
              display: "block",
              fontStyle: "italic",
              marginBottom: "4px",
              fontSize: isMobile && "14px",
            }}
          >
            Information Commissioners Office Registration Number:{" "}
            <span style={{ color: COLORS.red }}>ZA185613</span>
          </Typography.Text>

          <Divider></Divider>
          <Typography.Text
            style={{
              color: COLORS.black,
              display: "block",
              fontWeight: 600,
              fontSize: isMobile ? "22px" : "24px",
              marginBottom: isMobile ? "12px" : "20px",
            }}
          >
            Tiger Lion Financial Limited is registered {!isMobile && <br></br>}{" "}
            in England & Wales
          </Typography.Text>
          <Typography.Text
            style={{
              color: "#8e8e8e",
              display: "block",
              fontStyle: "italic",
              marginBottom: "4px",
              fontSize: isMobile && "14px",
            }}
          >
            Company Number: <span style={{ color: COLORS.red }}>10189367</span>
          </Typography.Text>
          <Typography.Text
            style={{
              color: COLORS.black,
              display: "block",
              fontStyle: "italic",
              marginBottom: "4px",
              fontWeight: 600,
              fontSize: isMobile && "14px",
            }}
          >
            Registered office address:
          </Typography.Text>
          <Typography.Text
            style={{
              color: "#8e8e8e",
              display: "block",
              fontStyle: "italic",
              marginBottom: "4px",
              fontSize: isMobile && "14px",
            }}
          >
            71-75 Shelton Street, Covent Garden, London, WC2H 9JQ
          </Typography.Text>
        </Col>
        <Col
          span={!isMobile && "10"}
          style={{
            minWidth: isMobile ? "100%" : "394px",
            margin: isMobile && "24px 0 32px",
          }}
        >
          <div
            style={{
              border: `3px dashed ${COLORS.red}`,
              padding: isMobile ? "28px" : "42px",
              borderRadius: "20px",
            }}
          >
            <Row
              style={{ marginBottom: isMobile ? "24px" : "40px" }}
              align={"middle"}
            >
              <div
                style={{
                  background: COLORS.red,
                  width: isMobile ? "36px" : "40px",
                  height: isMobile ? "36px" : "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  marginRight: isMobile ? "14px" : "20px",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    color: COLORS.white,
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <MailIcon />
                </div>
              </div>
              <span
                style={{
                  color: COLORS.red,
                  fontWeight: 600,
                  fontSize: isMobile ? "20px" : "24px",
                }}
              >
                info@loantube.com
              </span>
            </Row>
            <Row align={"middle"} style={{ marginBottom: "20px" }}>
              <a
                href={isMobile ? "tel:+4402080885001" : "javascript:void(0)"}
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: COLORS.red,
                    width: isMobile ? "36px" : "40px",
                    height: isMobile ? "36px" : "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginRight: isMobile ? "14px" : "20px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      color: COLORS.white,
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    <PhoneIcon />
                  </div>
                </div>
                <span
                  style={{
                    color: COLORS.red,
                    fontWeight: 600,
                    fontSize: isMobile ? "20px" : "24px",
                  }}
                >
                  020 8088 5001
                </span>
              </a>
            </Row>

            <Typography.Text
              style={{
                color: COLORS.black,
                display: "block",
                fontSize: isMobile ? "20px" : "24px",
                lineHeight: "38px",
                maxWidth: "300px",
              }}
            >
              We are available from:{" "}
              <span style={{ display: isMobile && "block" }}>
                <span style={{ fontWeight: 600 }}>Mon - Fri</span> 9 am to 6 pm
              </span>
              <span style={{ display: isMobile && "block" }}>
                {" "}
                <span style={{ fontWeight: 600 }}>Sat</span> 9 am to 2 pm
              </span>
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardView;
