import { useState, useEffect } from "react";
import { isValid as postcodeCheck } from "postcode";
import { URLS, COLORS } from "../../core/constants";

import { Typography } from "antd";

import SelectInput from "../../components/CustomerComponents/SelectInput";

const MY_ADDRESS_IS_NOT_LISTED_HERE = "My address is not listed here";

export default function Address({
  label,
  defaultValue,
  setError,
  setValue,
  data,
  name,
  setData,
  advanceToNextStep,
  value,
  isMobile,
  hideOptions,
  hideEmptyOption,
  emptyOptionStyle,
  appendOptions,
  hasBoxShadow,
  placeholder,
  manualPostcodeName,
  withValue,
  ...props
}) {
  const defaultItem = {
    label: MY_ADDRESS_IS_NOT_LISTED_HERE,
    value: MY_ADDRESS_IS_NOT_LISTED_HERE,
    address: false,
  };
  const [list, setList] = useState([defaultItem]);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [postcode, setPostCode] = useState();
  const [address, setAddress] = useState({});
  const [dv, setDV] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setLoading(true);
      const obj = JSON.parse(defaultValue);
      if (obj.address) {
        let addressList = [];

        addressList = [
          {
            label: obj.address.full_address,
            value: obj.address.full_address,
            address: obj.address,
          },
          defaultItem,
        ];

        setList(addressList);
        setDV(addressList[0].value);
        setAddress(addressList[0].address);
        setSelected(addressList[0].value);
      } else {
        setDV(defaultItem.value);
      }
      setPostCode(obj.postcode);
      setLoading(false);
    }
  }, []);

  async function onSelect(id, code) {
    const option = list.find((item) => item.value === id);

    setSelected(id);
    setAddress(option.address);
    setValue(
      JSON.stringify({
        postcode: code !== undefined ? code : postcode,
        address: option.address,
      })
    );
    typeof props.onSelect === "function" && props.onSelect();
  }

  async function onSelectEmpty(id, code) {
    await onSelect(id, code, true);

    // setTimeout(() => {
    //   advanceToNextStep(false);
    // }, 500);
  }

  async function onSearch(event, defaultValue = "") {
    const postcode = event?.target?.value || defaultValue;
    typeof props.onSearch === "function" && props.onSearch(postcode);

    const postcodeName = manualPostcodeName || 'manual_postcode'

    setData({
      ...data,
      [postcodeName]: postcode,
    });

    const valid = postcodeCheck(postcode);
    setPostCode(postcode);

    if (!valid) {
      setError(["Please enter a valid postcode"]);
      setList([]);
      return;
    }
    setError([]);

    setLoading(true);
    setList([defaultItem]);

    async function get_address() {
      const url = URLS.postcode + postcode;
      const res = await fetch(url);

      const addresses = await res.json();

      if (Array.isArray(addresses)) {
        const formatted = addresses.map((a) => {
          return {
            label: a.full_address,
            value: a.full_address,
            address: a,
          };
        });

        formatted.push(defaultItem);
        setList(formatted);
      }

      setLoading(false);
    }

    await get_address();
  }

  function formatOptionItem(item) {
    const address = item?.address?.full_address;

    return {
      id: item.value,
      header: address ? address : item.label,
    };
  }

  function getParsedValue(val) {
    const parsedDefaultValue = val && JSON.parse(val);
    return parsedDefaultValue;
  }

  const emptyOption = list.length ? list[list.length - 1] : null;

  const isEmptyOptionSelected =
    (emptyOption && emptyOption.value === selected) ||
    getParsedValue(data[name])?.address === false;

  const parsedDefaultValue = getParsedValue(defaultValue);

  return (
    <>
      <SelectInput
        loading={loading}
        titleColor={COLORS.black}
        defaultValue={
          parsedDefaultValue?.postcode !== MY_ADDRESS_IS_NOT_LISTED_HERE
            ? parsedDefaultValue?.postcode
            : undefined
        }
        maxHeight={
          isMobile
            ? "clamp(190.4px, 23.2vh, 251.2px)"
            : "clamp(238px, 29.07vh, 314px)"
        }
        value={withValue ? value?.address?.full_address || undefined : undefined}
        onSelect={onSelect}
        onSearch={onSearch}
        selectedOptionId={selected}
        isMobile={isMobile}
        options={list.slice(0, list.length - 1).map(formatOptionItem)}
        focusMultiplier={2.2}
        hideOptions={hideOptions}
        appendOptions={appendOptions}
        hasBoxShadow={hasBoxShadow}
        onFocus={() => {
          if (withValue && value?.address?.full_address) {
            setValue("{}");
          }
        }}
        onKeyDown={(e) => {
          const regex = /^[a-zA-Z0-9 ]$/;
          const isMatch =
            regex.test(e.key) || e.keyCode === 8 || e.keyCode === 9;
          if (!isMatch) e.preventDefault();
        }}
        placeholder={placeholder}
      />
      {!(list.length <= 1 && !isEmptyOptionSelected) &&
        (emptyOption || isEmptyOptionSelected) &&
        !(hideOptions && hideEmptyOption) && (
          <Typography.Text
            style={{
              color: isEmptyOptionSelected ? COLORS.red : COLORS.darkGrey,
              marginTop: "clamp(12px, 1.6667vh, 18px)",
              padding: "15px 20px 5px",
              display: "block",
              cursor: "pointer",
              fontWeight: "500",
              fontSize: "clamp(14px, 1.667vh, 18px)",
              ...emptyOptionStyle,
            }}
            onClick={() => onSelectEmpty(emptyOption.value, "")}
          >
            {emptyOption?.label || MY_ADDRESS_IS_NOT_LISTED_HERE}
          </Typography.Text>
        )}
    </>
  );
}
