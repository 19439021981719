import { last, replace } from "lodash";
import { Typography } from "antd";

import { useState, useEffect, useRef } from "react";
import { fetchDirectors } from "../../../core/services";
import { URLS, COLORS } from "../../../core/constants";
import { CloseCircleOutlined } from "@ant-design/icons";

import SelectInput from "./SelectInput";

const BUSINESS_NOT_LISTED = "My business is not listed here";

export default function BusinessData({
  setValue,
  defaultValue,
  data,
  name,
  value,
  advanceToNextStep,
  isMobile,
  setData,
  appendOptions,
  hasBoxShadow,
  emptyOptionStyle,
  optionsStyle,
}) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [companyData, setCompany] = useState({});
  const nextValue = useRef("");
  const [hideOptions, setHideOptions] = useState(true);
  const loadingTimeout = useRef(null);

  useEffect(() => {
    setDefaultValue();
  }, []);

  useEffect(() => {
    if (list.length && nextValue.current) {
      const selectedId = list.find(
        (v) => v.company.name === nextValue.current
      )?.value;
      nextValue.current = "";
      selectedId && onSelect(selectedId);
    }
  }, [list]);

  useEffect(() => {
    window.sessionStorage.setItem("company", companyData.id);
    let newData = { ...data };
    newData["company"] = companyData;
    setData(newData);
  }, [companyData]);

  async function setDefaultValue() {
    if (defaultValue && defaultValue !== BUSINESS_NOT_LISTED) {
      nextValue.current = defaultValue;
    }
  }

  async function onSelect(id) {
    setHideOptions(true);
    const option = list
      .filter((item) => item)
      .find((item) => item.value === id);

    setCompany(option.company);

    if (option?.company?.id) await fetchDirectors(option.company.id, false);

    if (option?.company) setValue(option.company.name);
  }

  async function onSelectEmpty(id) {
    await onSelect(id);

    setTimeout(() => {
      typeof advanceToNextStep === "function" && advanceToNextStep(false);
    }, 500);
  }

  const handleBlur = () => setHideOptions(true);

  const handleFocus = () => setHideOptions(false);

  async function onSearch(event, defaultValue = "") {
    let text = event?.target?.value || defaultValue;
    setLoading(true);
    setValue(text);
    setCompany("");
    setHideOptions(false);

    clearTimeout(loadingTimeout.current);

    loadingTimeout.current = setTimeout(async () => {
      async function getNames() {
        let url = URLS.company + encodeURIComponent(text);
        const res = await fetch(url);

        const responseData = await res.json();

        if (Array.isArray(responseData.companies)) {
          const formatted = responseData.companies.map((c) => {
            if (!c.address) {
              return;
            }
            let label = c.name + " " + c.address;
            let value = c.name;
            c.postcode = last(c.address.split(","));
            c.postcode = replace(c.postcode, " ", "");
            return { label, value, company: c };
          });

          formatted.push({
            label: BUSINESS_NOT_LISTED,
            value: BUSINESS_NOT_LISTED,
            company: {
              name: BUSINESS_NOT_LISTED,
            },
          });

          setList(formatted);
        }

        setLoading(false);
      }

      await getNames();
    }, 500);
  }

  function formatOptionItem(item) {
    if (!item) return null;
    return {
      id: item.value,
      header: item.company.name,
      text: item.company.address,
    };
  }

  function getValueByCompanyName(cName) {
    if (!cName) return false;
    return (
      list.filter((item) => item).find((item) => item.company.name === cName)
        ?.value || ""
    );
  }

  const emptyOption = list.length ? list[list.length - 1] : null;

  const isEmptyOptionSelected =
    (emptyOption &&
      getValueByCompanyName(companyData?.name) === emptyOption.company?.name) ||
    data[name] === BUSINESS_NOT_LISTED;

  const clear = () => {
    setValue("");
  };

  const emptyOptionVisible =
    !(!!companyData && !isEmptyOptionSelected) &&
    (emptyOption || isEmptyOptionSelected) &&
    !hideOptions;

  return (
    <>
      <SelectInput
        value={value === BUSINESS_NOT_LISTED ? "" : value}
        loading={loading}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder={
          value === BUSINESS_NOT_LISTED
            ? "My business is not listed here"
            : "Type your business name"
        }
        defaultValue={
          defaultValue !== BUSINESS_NOT_LISTED ? defaultValue : undefined
        }
        suffix={
          value && (
            <CloseCircleOutlined
              onClick={clear}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                paddingLeft: "3px",
              }}
            />
          )
        }
        hideOptions={hideOptions || !!companyData}
        hasIcon={true}
        selectedOptionId={getValueByCompanyName(companyData.name)}
        isMobile={isMobile}
        appendOptions={appendOptions}
        emptyOptionVisible={emptyOptionVisible}
        hasBoxShadow={hasBoxShadow}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        optionsStyle={optionsStyle}
        options={list
          .slice(0, list.length - 1)
          .map(formatOptionItem)
          .filter((item) => !!item)}
      />
      {!(!!companyData && !isEmptyOptionSelected) &&
        (emptyOption || isEmptyOptionSelected) &&
        !hideOptions && (
          <Typography.Text
            role="button"
            style={{
              color: isEmptyOptionSelected ? COLORS.red : COLORS.darkGrey,
              padding: "5px 20px",
              paddingTop:
                value === BUSINESS_NOT_LISTED
                  ? "21px"
                  : "clamp(12px, 1.6667vh, 18px)",
              display: "block",
              cursor: "pointer",
              fontSize: "clamp(14px, 1.667vh, 18px)",
              fontWeight: "500",
              ...emptyOptionStyle,
            }}
            onClick={() => onSelectEmpty(emptyOption.value)}
          >
            {emptyOption?.label || BUSINESS_NOT_LISTED}
          </Typography.Text>
        )}
    </>
  );
}
