import { headers } from "../../../core/constants";
import HyperDX from '@hyperdx/browser';

export const whoami = async (mobile) => {
  const response = await fetch(
    `/api/auth/whoami`,
    {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  const data = await response.json();
  if(HyperDX && (data?.user?.username || data?.user?.mobile)){
    HyperDX.setGlobalAttributes(data);
  }
 
  return data;
};

export const login = async (mobile) => {
  const response = await fetch(
    `/api/auth/otp`,
    {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        mobile: String(mobile),
      }),
    }
  );
  const data = await response.json();
  return data;
};

export const otpResend = async (mobile, token) => {
  const response = await fetch(
    `/api/auth/otp`,
    {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        mobile: String(mobile),
        token,
      }),
    }
  );
  const data = await response.json();

  return data?.data?.token;
};

export const otpVerify = async (mobile, token, otp) => {
  const response = await fetch(
    `/api/auth/verify`,
    {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: 'include',
      method: "POST",
      body: JSON.stringify({
        mobile: mobile,
        token: token,
        otp: otp,
      }),
    }
  );
  const data = await response.json();
  if(data.data.valid){
    window.localStorage.setItem("isLoggedIn", 1);
  }
  return data.data.valid;
};
