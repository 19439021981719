import { useState, useEffect } from "react";
import { Button, Typography } from "antd";

export default function Timer({ onFinishCountdown }) {
  const timerDuration = 30;

  const [timer, setTimer] = useState(timerDuration);
  const [reset, setReset] = useState(false);
  const [timerId, setTimerId] = useState(false);

  function startTimer() {
    if (timerId) {
      clearInterval(timerId);
    }

    setTimer(timerDuration);

    const id = setInterval(() => {
      setTimer((timer) => {
        if (timer - 1 < 1) onFinishCountdown();
        return timer - 1;
      });
    }, 1000);

    setTimerId(timerId);

    return () => {
      clearInterval(id);
    };
  }

  useEffect(() => {
    return startTimer();
  }, [reset]);

  return (
    <Typography.Text
      strong
      style={{
        fontSize: "clamp(13.6px, 1.388vh, 15px)",
      }}
    >
      00:{timer < 0 ? "00" : timer > 9 ? timer : `0${timer}`}
    </Typography.Text>
  );
}
