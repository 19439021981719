import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { headers } from "../../core/constants";

const validateLink = async (shortLinkId) => {
  const response = await fetch(`/api/link?linkId=${shortLinkId}`, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data?.data;
};

const ShortLinkRouteHandler = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function handleLink() {
      const data = await validateLink(params.shortLinkId);
      const source = data?.source || "";
      const qs = data?.qs || "";
      if (!data?.applicantName || !data?.leadId) {
        return navigate("/" + qs);
      }
      if(source === 'api'){
        return navigate("/application" + qs, {
          state: {
            applicantName: data.applicantName,
            leadId: data.leadId,
            shortLinkId: params.shortLinkId,
            qs: qs
          },
        });
      }
      return navigate("/verify" + qs, {
        state: {
          applicantName: data.applicantName,
          leadId: data.leadId,
          shortLinkId: params.shortLinkId,
          qs: qs
        },
      });
    }
    handleLink();
  });
  return <div></div>;
};

export default ShortLinkRouteHandler;
