import { ConfigProvider, Row } from "antd";
import { THEME, isMobileMediaQuery, headers } from "../../core/constants";
import useMediaQuery from "../../core/hooks/useMediaQuery";
import { useLocation, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import DashboardSidebar from "../../components/CustomerComponents/DashboardSidebard";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import useScrollToTop from "../../core/hooks/useScrollToTop";

const DashboardRoute = () => {
  const isMobile = useMediaQuery(isMobileMediaQuery);
  const [applicationData, setApplicationData] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    document.title = "LoanTube: Dashboard"
  }, [])
  async function fetchData() {
    try {
      let url = "/api/leads?limit=1&skip=0";
      if(searchParams.has('leadId')){
        url += `&appId=${searchParams.get('leadId')}`
      }
      const res = await fetch(url, {
        headers,
        credentials: "include",
      });

      if (res.status === 401) {
        return navigate("/login");
      }

      const data = await res.json();

      setApplicationData(data.data.data[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!applicationData) {
      // setTimeout(() => {
        fetchData();
      // }, 2000)
    }
  }, [location]);

  useScrollToTop();

  return (
    <ConfigProvider theme={THEME}>
      {applicationData ? (
        <Row>
          <DashboardSidebar
            isMobile={isMobile}
            visible={isSidebarVisible}
            onChange={(value) => setSidebarVisible(value)}
          />
          <Outlet
            context={{
              isMobile,
              isSidebarVisible,
              setApplicationData,
              applicationData: applicationData,
            }}
          />
        </Row>
      ) : !loading ? (
        <Navigate to="/" replace={true} />
      ) : (
        <></>
      )}
    </ConfigProvider>
  );
};

export default DashboardRoute;
