import { Col, Row, Menu } from "antd";
import { MenuOutlined, CloseOutlined, PoundOutlined } from "@ant-design/icons";
import { COLORS, headers } from "../../core/constants";
import Briefcase from "../icons/Briefcase";
import Folder from "../icons/Folder";
import Power from "../icons/Power";
import Pin from "../icons/Pin";
import User from "../icons/User";
import Home from "../icons/Home";
import DocumentIcon from "../icons/DocumentIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import useWindowSize from "../../core/hooks/useWindowSize";
import useClickOutside from "../../core/hooks/useClickOutside";

const DashboardSidebar = ({ isMobile, visible, onChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { height } = useWindowSize();
  const ref = useRef(null);
  useClickOutside(ref, () => onChange(true));
  const isFinanceOptions = location.pathname === "/dashboard/finance-options";

  const handleLogout = async () => {
    try {
      window.localStorage.clear();

      const res = await (
        await fetch(`/api/auth/logout`, {
          method: "POST",
          credentials: "include",
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        })
      ).json();
      if (res?.json?.ok) {
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const navigateAndCloseSidebar = (path) => {
    onChange(true);
    navigate(path);
  };

  return (
    <>
      {isMobile && (
        <div
          style={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            backgroundColor: COLORS.white,
            border: `2px solid ${COLORS.red}`,
            transform: visible ? undefined : "translateX(700%)",
            transition:
              "transform 350ms ease-out, color 100ms ease-out, background-color 100ms ease-out",
            marginInline: "16px",
            marginTop: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: COLORS.red,
            position: "fixed",
            zIndex: "99",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onChange(!visible);
          }}
        >
          {visible ? (
            <MenuOutlined style={{ fontSize: "22px" }} />
          ) : (
            <CloseOutlined style={{ fontSize: "22px" }} />
          )}
        </div>
      )}
      <Col className="dashboard-sidebar-spacer"></Col>
      <Col
        className={
          visible
            ? "dashboard-sidebar"
            : "dashboard-sidebar dashboard-sidebar-visible"
        }
        style={{ height: isMobile ? height - 24 : undefined }}
        ref={ref}
      >
        <div>
          <Row justify={"center"} style={{ margin: isMobile ? '24px 0 40px' : "24px 0 56px" }}>
            <img
              className="logo"
              src="/assets/logo.svg"
              alt="loantube logo"
              style={{ height: "38px" }}
            />
          </Row>
          <Row style={{ padding: "0 15px" }}>
            <Menu
              mode="inline"
              style={{ background: "inherit", borderInlineEnd: "none" }}
              selectedKeys={[location.pathname]}
              items={[
                {
                  key: "/dashboard",
                  icon: <Home />,
                  label: "Dashboard",
                  onClick: () => navigateAndCloseSidebar("/dashboard"),
                },
                {
                  key: "/dashboard/your-documents",
                  icon: <DocumentIcon />,
                  label: "Your Documents",
                  onClick: () =>
                    navigateAndCloseSidebar("/dashboard/your-documents"),
                },
                {
                  key: "/dashboard/finance-options",
                  icon: (
                    <div
                      style={{
                        border: `2px solid ${isFinanceOptions ? COLORS.red : COLORS.black}`,
                        borderRadius: "4px",
                        padding: "1px",
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ fontSize: "18px" }}
                      >
                        <path d="m5.553 19.105.764 1.843C7.498 20.555 9.422 20 10 20c.838 0 1.462.208 2.184.448.775.259 1.654.552 2.816.552 1.177 0 3.078-.921 3.447-1.105l-.895-1.789c-.721.36-2.031.894-2.552.894-.838 0-1.462-.208-2.184-.448C12.041 18.293 11.162 18 10 18c-.229 0-.526.037-.857.099C9.702 16.95 10 15.561 10 14h3v-2H9.626c-.042-.107-.084-.216-.125-.317C9.243 11.052 9 10.455 9 9c0-1.369.521-3 3-3 2.224 0 3.021 2.227 3.052 2.316l1.896-.633C16.898 7.533 15.679 4 12 4 8.313 4 7 6.583 7 9c0 1.491.234 2.35.478 3H5v2h3c0 2.467-.892 4.328-2.447 5.105z"></path>
                      </svg>
                    </div>
                  ),
                  label: "Finance Options",
                  onClick: () =>
                    navigateAndCloseSidebar("/dashboard/finance-options"),
                },
              ]}
            />
          </Row>
        </div>

        <div>
          <Row style={{ padding: "0 15px", marginBottom: isMobile ? '20px' : "36px" }}>
            <Menu
              mode="inline"
              style={{ background: "inherit", borderInlineEnd: "none" }}
              selectedKeys={[location.pathname]}
              items={[
                {
                  key: "/dashboard/business-details",
                  icon: <Briefcase />,
                  label: "Business Details",
                  onClick: () =>
                    navigateAndCloseSidebar("/dashboard/business-details"),
                },
                {
                  key: "/dashboard/your-details",
                  icon: <User />,
                  label: "Your Details",
                  onClick: () =>
                    navigateAndCloseSidebar("/dashboard/your-details"),
                },
                {
                  key: "/dashboard/contact-us",
                  icon: <Pin className="menu-item-icon" />,
                  label: "Contact us",
                  onClick: () =>
                    navigateAndCloseSidebar("/dashboard/contact-us"),
                },
                {
                  key: "2",
                  icon: <Power />,
                  label: "Sign out",
                  onClick: handleLogout,
                },
              ]}
            />
          </Row>
          <Row style={{ marginBottom: "38px", paddingLeft: "36px" }}>
            <img
              className="logo"
              src="/assets/logo-small.svg"
              alt="loantube logo"
              style={{ height: "14px" }}
            />
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                width: "100%",
                marginTop: "8px",
              }}
            >
              © 2023 All Rights Reserved
            </p>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default DashboardSidebar;
