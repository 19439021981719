import SinglePageForm from ".";
import { useState, useEffect } from "react";
import { whoami,affiliateLogin } from "./services";
import { useNavigate, useParams  } from "react-router-dom";

const SinglePageFormPrivate = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  useEffect(() => {
    async function call() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const authCode = params.get('authCode');
      let response = {}
      if (authCode) {
        let data = await affiliateLogin(authCode);
        if (data.isValid) {
          response = await whoami(authCode);
        }
      }
      else {
        response = await whoami();
      }
      setRole(response.role);
      if (response.role === 'agent' || response.role === 'admin' || response.role === 'affiliate') {
        setLoading(false);
      }
      else {
        navigate('/login');
      }
    }
    call();
  }, []);

  return loading ? <div></div> : <SinglePageForm role={role} variation />;
};

export default SinglePageFormPrivate;
