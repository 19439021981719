import { URLS, headers } from "./constants";
import { I_AM_NOT_ONE_OF_THESE_PEOPLE } from "./constants";

export async function validatePhone(mobile) {
  try {
    const res = await fetch(URLS.otp_validate, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        mobile,
      }),
    });

    const isValid = await res.json();

    return isValid;
  } catch (err) {
    console.log(err);
  }
}

export const fetchLeadShareholders = async (leadId) => {
  try {
    const blob = await fetch(`/api/leads/shareholders?leadId=${leadId}`, {
      method: "GET",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const response = await blob.json();

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const submitAdditionalQuestions = async (leadId, fields) => {
  try {
    const blob = await fetch(`/api/leads/additional/${leadId}`, {
      method: "PATCH",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(fields),
    });

    const response = await blob.json();

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getAdditionalInfo = async (leadId) => {
  try {
    const blob = await fetch(`/api/leads/additional/${leadId}`, {
      method: "GET",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const response = await blob.json();

    return response;
  } catch (err) {
    console.log(err);
  }
};

export async function fetchDirectors(cid, cached = true) {
  const key = "directors";

  if (!cid) {
    window.sessionStorage.removeItem(key);
  }

  if (cached) {
    let directors = window.sessionStorage.getItem(key);

    if (directors) {
      return JSON.parse(directors);
    }
  }

  const url = URLS.director + cid;

  const res = await fetch(url);

  const responseData = await res.json();

  window.sessionStorage.setItem(key, JSON.stringify(responseData));

  return responseData;
}

export async function updateLead(appId, data, currentData) {
  try {
    const res = await fetch(`/api/leads/${appId}`, {
      method: "PATCH",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        application: {
          company: {
            postcode: data?.business_postcode,
            address: formatBusinessAddress(
              data?.business_address,
              data?.postcode_manual
            ),
          },
          postcode_manual: data?.postcode_manual,
          dob: data?.dob,
          property_value: data?.property_value,
          residential_status: data?.residential_status,
          email: data?.email,
          director_name: data?.director_name,
          director_name_manual: data?.director_name_manual,
          job_position: formatJobPostion(data?.job_position),
          manual_postcode: data?.manual_postcode,
          residential_postcode: formatResidentialPostcode(
            data?.residential_postcode,
            data.residential_postcode_manual
          ),
          mobile_number: data?.mobile_number,
          revenue: data?.revenue,
          invoices: formatYesNoAttribute(data?.invoices),
          accept_card_payments: formatYesNoAttribute(
            data?.accept_card_payments
          ),
          residential_postcode_manual: data?.residential_postcode_manual,
          business_age: formatBusinessAge(data?.business_age),
          card_amount: formatCardAmount(
            data?.card_amount,
            data?.accept_card_payments,
            currentData
          ),
        },
      }),
    });

    const lead = await res.json();

    return lead;
  } catch (err) {
    console.log(err);
  }
}

function formatJobPostion(position) {
  if (!position) return;
  return position.charAt(0).toUpperCase() + position.slice(1);
}

function formatResidentialPostcode(postcode, isManual) {
  if (!postcode) return;
  if (isManual) {
    return null;
  }

  const parsedPostcode = JSON.parse(postcode);
  if (!parsedPostcode.address) return;

  return parsedPostcode;
}

function formatYesNoAttribute(invoices) {
  if (!invoices) return;
  if (invoices === "yes") return true;
  else if (invoices === "no") return false;
}

function formatBusinessAddress(businessAddress, isManual) {
  if (!businessAddress) return;
  if (isManual) return null;
  const obj = JSON.parse(businessAddress);
  return obj?.address?.full_address;
}

function formatBusinessAge(age) {
  if (age === "less_than_1_year") {
    return "Less than 1 year";
  } else if (age === "1_2_years") {
    return "1 - 2 years";
  } else if (age === "2_3_years") {
    return "2 - 3 years";
  } else if (age === "more_than_3_years") {
    return "More than 3 years";
  }
}

function formatCardAmount(cardAmount, acceptCardPayments, currentData) {
  if (!currentData) return;
  const revenue = parseInt(currentData.application.revenue);
  const acceptsCards = formatYesNoAttribute(acceptCardPayments);

  if (!acceptsCards) return;

  if (cardAmount === "0") {
    return revenue * 0;
  } else if (cardAmount === "up_to_25") {
    return revenue * 0.25;
  } else if (cardAmount === "25_50") {
    return revenue * 0.5;
  } else if (cardAmount === "50_75") {
    return revenue * 0.75;
  } else if (cardAmount === "more_than_75") {
    return revenue;
  }
}

export const getCompanyData = async (cid) => {
  if (!cid) {
    return;
  }

  const responseData = await fetchDirectors(cid, false);

  if (
    responseData &&
    responseData.active_directors &&
    Array.isArray(responseData.active_directors)
  ) {
    const formatted = responseData.active_directors.map((i) => {
      return { value: i.name, label: i.name, director: i };
    });

    formatted.push({
      value: I_AM_NOT_ONE_OF_THESE_PEOPLE,
      label: I_AM_NOT_ONE_OF_THESE_PEOPLE,
      director: false,
    });
    return formatted;
  }
};
