import StepForwardButton from "../../components/CustomerComponents/StepForwardButton";
import { COLORS } from "../../core/constants";
import PhoneIcon from "../../components/CustomerComponents/PhoneIcon";

const PhoneCallButton = ({ isMobile, size, borderRadius, fontWeight }) => {
  return (

    <StepForwardButton
      enabled={true}
      fontWeight={fontWeight || 600}
      size={size}
      borderRadius={borderRadius}
    >
      <span
        style={{
          color: COLORS.white,
          width: "clamp(14px, 1.8518vh, 20px)",
          marginRight: "12px",
          position: "relative",
          top: "1.5px",
        }}
      >
        <PhoneIcon />
      </span>
      <a
        href={isMobile ? "tel:02080885001" : "javascript:void(0)"}
        style={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        020 8088 5001
      </a>

    </StepForwardButton>
  );
};

export default PhoneCallButton;
