import BusinessData from "./BusinessData";
import { COLORS } from "../../../core/constants";

const SinglePageFormBusinessData = ({ ...props }) => {
  return (
    <BusinessData
      appendOptions={true}
      hasBoxShadow={true}
      emptyOptionStyle={{
        background: COLORS.white,
        boxShadow:
          "0 25px 50px -12px rgb(0 0 0 / 0.25)",
        padding: "10px 20px 10px",
        marginTop: 0,
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        position: "relative",
        position: 'relative',
        top: "-12.5px",
        border: '1.5px solid #212121',
        borderTop: '0',
        color: '#212121'
      }}
      optionsStyle={{
        borderRadius: "0px"
      }}
      {...props}
    />
  );
};

export default SinglePageFormBusinessData;
