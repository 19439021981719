export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.77 7.00007C7.53781 6.79613 7.2342 6.69265 6.92579 6.71233C6.61738 6.73202 6.32938 6.87326 6.12501 7.10507C4.78844 8.62059 3.91757 10.4896 3.61689 12.4878C3.31622 14.486 3.59852 16.5285 4.42991 18.3703C5.2613 20.212 6.60647 21.7747 8.304 22.8709C10.0015 23.9671 11.9793 24.5502 14 24.5502C16.0207 24.5502 17.9985 23.9671 19.696 22.8709C21.3935 21.7747 22.7387 20.212 23.5701 18.3703C24.4015 16.5285 24.6838 14.486 24.3831 12.4878C24.0824 10.4896 23.2116 8.62059 21.875 7.10507C21.6706 6.87326 21.3826 6.73202 21.0742 6.71233C20.7658 6.69265 20.4622 6.79613 20.23 7.00007C19.9982 7.20444 19.857 7.49244 19.8373 7.80085C19.8176 8.10926 19.9211 8.41287 20.125 8.64507C21.1646 9.82381 21.8419 11.2775 22.0758 12.8316C22.3096 14.3858 22.09 15.9744 21.4434 17.4069C20.7968 18.8393 19.7505 20.0548 18.4302 20.9074C17.1099 21.76 15.5717 22.2135 14 22.2135C12.4283 22.2135 10.8901 21.76 9.56978 20.9074C8.24948 20.0548 7.20323 18.8393 6.5566 17.4069C5.90996 15.9744 5.69039 14.3858 5.92425 12.8316C6.15811 11.2775 6.83545 9.82381 7.875 8.64507C8.07894 8.41287 8.18242 8.10926 8.16274 7.80085C8.14305 7.49244 8.00181 7.20444 7.77 7.00007Z"
      fill="currentColor"
    />
    <path
      d="M15.1666 14.0002V3.50016C15.1666 3.19074 15.0437 2.894 14.8249 2.6752C14.6061 2.45641 14.3094 2.3335 14 2.3335C13.6905 2.3335 13.3938 2.45641 13.175 2.6752C12.9562 2.894 12.8333 3.19074 12.8333 3.50016V14.0002C12.8333 14.3096 12.9562 14.6063 13.175 14.8251C13.3938 15.0439 13.6905 15.1668 14 15.1668C14.3094 15.1668 14.6061 15.0439 14.8249 14.8251C15.0437 14.6063 15.1666 14.3096 15.1666 14.0002Z"
      fill="currentColor"
    />
  </svg>
);
