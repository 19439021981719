import { useState, useRef } from "react";
import { Typography, Col, Row, Button, Input } from "antd";
import DocumentUploadIcon from "../icons/DocumentUpload";
import WhatsAppIcon from "../icons/WhatsAppIcon";

import { COLORS, headers } from "../../core/constants";
import SendEmailAddressPopup from "./SendEmailAddressPopup";
import SendMobileDocumentPopup from "./SendMobileDocumentPopup";

const sendUploadLink = (channel, appId, extra) => {
  return fetch(`/api/leads/communication?appId=${appId}&action=sendLink&channel=${channel}${extra}`, {
    headers,
    credentials: "include",
  });
};

const saveWhatsApNumber = async (appId, number) => {
  return fetch(`/api/leads/${appId}`, {
    method: 'PATCH',
    headers,
    credentials: "include",
    body: JSON.stringify({
      application: {
        mobile_number_whatsapp: number
      }
    }),
  });
};

const sendDocumentUploadLinkViaMobile = async (
  appId,
  channel,
  phoneNumber,
  wpNumber
) => {
  const number = channel === 'whatsapp' ? wpNumber || phoneNumber : phoneNumber
  if (channel === 'whatsapp') {
    await saveWhatsApNumber(appId, number)
  }
  return sendUploadLink(channel, appId, `&mobile_number=${number}`);
};

const sendDocumentUploadLinkViaEmail = (appId, emails) => {
  const secondEmailParam = emails.length === 2 && emails[1] ? `&email=${emails[1]}` : '';
  return sendUploadLink("email", appId, `&email=${emails[0]}${secondEmailParam}`);
};

const DocumentUpload = ({
  isMobile,
  onFilesUpload,
  applicationId,
  applicationData,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const [emailPopup, setEmailPopup] = useState(false);
  const [mobilePopup, setMobilePop] = useState(false);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      onFilesUpload(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFilesUpload(e.target.files);
    }
  };

  const handleClick = (ref) => {
    ref.current.click();
  };

  return (
    <Col
      style={{
        border: !dragActive && `3px dashed ${COLORS.red}`,
        borderRadius: "8px",
        padding: isMobile ? "16px" : "20px",
        position: "relative",
        marginTop: isMobile && "24px",
      }}
      onDragEnter={handleDrag}
    >
      <SendEmailAddressPopup
        visible={emailPopup}
        onClose={() => setEmailPopup(false)}
        isMobile={isMobile}
        email={applicationData?.application?.email}
        onSubmit={(emails) =>
          sendDocumentUploadLinkViaEmail(applicationId, emails)
        }
      />
      <SendMobileDocumentPopup
        visible={mobilePopup}
        onClose={() => setMobilePop(false)}
        isMobile={isMobile}
        onSubmit={(channel, phoneNumber, WhatsAppNumber) =>
          sendDocumentUploadLinkViaMobile(
            applicationId,
            channel,
            phoneNumber,
            WhatsAppNumber
          )
        }
        number={applicationData?.application?.mobile_number}
      />
      {dragActive && (
        <>
          <div className="drag-file-element">
            <Typography.Text
              style={{
                fontSize: "22px",
                color: COLORS.white,
                marginBottom: "24px",
                zIndex: "10000",
              }}
            >
              Drop your files here to upload
            </Typography.Text>
            <DocumentUploadIcon
              style={{
                height: "112px",
                color: COLORS.white,
              }}
            />
          </div>
          <div
            className="drop-file-container"
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        </>
      )}
      <Row justify={"center"}>
        {!isMobile && (
          <Typography.Text
            style={{
              fontSize: "18px",
              textAlign: isMobile && "center",
              color: "#777777",
            }}
          >
            Drag and drop your documents here
          </Typography.Text>
        )}
      </Row>
      <input
        ref={inputRef}
        type="file"
        className="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <input
        ref={cameraInputRef}
        type="file"
        className="input-file-upload"
        multiple={true}
        onChange={handleChange}
        accept="image/*"
        capture
      />
      <Row justify={"center"}>
        {!isMobile && (
          <Typography.Text
            style={{
              fontSize: "18px",
              marginBottom: "16px",
              color: "#777777",
            }}
          >
            or
          </Typography.Text>
        )}
      </Row>
      <Row justify={"center"} style={{ marginBottom: "8px" }}>

        <Button
          className="step-forward-button"
          style={{
            height: isMobile ? "64px" : "72px",
            background: COLORS.red,
            border: "none",
            paddingRight: "24px",
            paddingLeft: "24px",
            paddingTop: isMobile && "12px",
            width: isMobile && "218.9px",
            textAlign: 'center', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            marginTop: isMobile && "15px",
          }}
          onClick={() => handleClick(isMobile ? inputRef : cameraInputRef)}
        >

          <Row justify={"center"}>
            <Typography.Text
              style={{
                color: COLORS.white,
                fontWeight: 500,
                fontSize: 20,
              }}
            >
              Upload
            </Typography.Text>
          </Row>
          {isMobile ? (
            <Row justify={"center"}>
              <Typography.Text
                style={{
                  color: COLORS.offwhite,
                  fontSize: "14px",
                }}
              >
                from your phone
              </Typography.Text>
            </Row>
          ) : (
            <Row>
              <Typography.Text
                style={{
                  color: COLORS.offwhite,
                  fontSize: "14px",
                }}
              >
                from your device
              </Typography.Text>
            </Row>
          )}


        </Button>
      </Row>
      {/* <Row justify={"center"} align={"middle"} style={{ margin: "8px 0" }}>
        {isMobile && (
          <Typography.Text
            style={{
              fontSize: "18px",
              color: "#777777",
            }}
          >
            or
          </Typography.Text>
        )}
      </Row> */}

      {/* {isMobile && (
        <Row justify={"center"} style={{ marginBottom: "8px" }}>
          <Button
            style={{
              height: "64px",
              border: "none",
              paddingRight: "24px",
              paddingLeft: "24px",
              border: `1px solid ${COLORS.red}`,
              paddingTop: "3px",
              marginBottom: "8px",
            }}
            onClick={() => handleClick(inputRef)}
          >
            <Row justify={"center"}>
              <Typography.Text
                style={{
                  color: COLORS.red,
                  fontWeight: 500,
                  fontSize: "22px",
                }}
              >
                Select photos
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text
                style={{
                  color: COLORS.red,
                  fontSize: "14px",
                }}
              >
                from your phone gallery
              </Typography.Text>
            </Row>
          </Button>
        </Row>
      )} */}

      <Row justify={"center"} style={{ marginBottom: "8px" }}>
        <Typography.Text style={{ fontSize: "14px", color: COLORS.red, paddingTop: 10 }}>
          Maximum allowed file size is 10 MB
        </Typography.Text>
      </Row>

      <Row justify={"center"} style={{ marginBottom: "16px" }}></Row>
      <Row style={{ gap: "28px" }}>
        <Button
          className="button-with-hover button-with-hover-grey"
          style={{
            borderColor: "#777777",
            flex: "1 1 0px",
            width: "285.3px",
          }}
          onClick={() => setEmailPopup(true)}
        >
          <Typography.Text style={{ color: "#777777" }}>
            upload documents from email
          </Typography.Text>
        </Button>
        <Button
          className="button-with-hover button-with-hover-grey"
          style={{
            borderColor: "#777777",
            flex: "1 1 0px",
            width: "285.3px",
            padding: isMobile && "0",
          }}
          onClick={() => setMobilePop(true)}
        >
          <Row justify={"center"} align={"middle"}>
            <Typography.Text style={{ color: "#777777", marginRight: "4px" }}>
              {isMobile
                ? "upload from WhatsApp"
                : "upload documents from mobile"}
            </Typography.Text>
            {isMobile && <WhatsAppIcon height="36px" width="36px" />}
          </Row>
        </Button>
      </Row>
    </Col>
  );
};

export default DocumentUpload;
