export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 9.33317V23.3332C3.5 23.6426 3.62292 23.9393 3.84171 24.1581C4.0605 24.3769 4.35725 24.4998 4.66667 24.4998H10.5C10.8094 24.4998 11.1062 24.3769 11.325 24.1581C11.5437 23.9393 11.6667 23.6426 11.6667 23.3332V15.1665H16.3333V23.3332C16.3333 23.6426 16.4562 23.9393 16.675 24.1581C16.8938 24.3769 17.1906 24.4998 17.5 24.4998H23.3333C23.6428 24.4998 23.9395 24.3769 24.1583 24.1581C24.3771 23.9393 24.5 23.6426 24.5 23.3332V9.33317C24.5 9.15205 24.4578 8.97342 24.3768 8.81142C24.2958 8.64943 24.1782 8.50851 24.0333 8.39984L14.7 1.39984C14.4981 1.24838 14.2524 1.1665 14 1.1665C13.7476 1.1665 13.5019 1.24838 13.3 1.39984L3.96667 8.39984C3.82177 8.50851 3.70417 8.64943 3.62317 8.81142C3.54217 8.97342 3.5 9.15205 3.5 9.33317ZM5.83333 9.91651L14 3.7915L22.1667 9.91651V22.1665H18.6667V13.9998C18.6667 13.6904 18.5437 13.3937 18.325 13.1749C18.1062 12.9561 17.8094 12.8332 17.5 12.8332H10.5C10.1906 12.8332 9.89383 12.9561 9.67504 13.1749C9.45625 13.3937 9.33333 13.6904 9.33333 13.9998V22.1665H5.83333V9.91651Z"
      fill="currentColor"
    />
  </svg>
);
