import { getName, makeHandleFocus } from "../../core/utils";

import { InputNumber } from "antd";
import { useEffect, useRef } from "react";

export default function Number({
  label,
  name,
  prefix,
  suffix,
  valid,
  setValid,
  data,
  setData,
  setValue,
  fieldProps,
  maxLength,
  formatter,
  defaultValue,
  controls,
  onKeyDown,
  isMobile,
  value,
  min,
  max,
}) {
  const ref = useRef(null);
  name = getName({ label, name });

  const onChange = (e) => {
    if (setValue) {
      setValue(e);
    }
  };

  return (
    <InputNumber
      ref={ref}
      name={name}
      size="large"
      addonBefore={prefix}
      addonAfter={suffix}
      onChange={onChange}
      defaultValue={defaultValue || null}
      maxLength={maxLength}
      formatter={formatter}
      controls={controls}
      onKeyDown={onKeyDown}
      min={min}
      max={max}
      onFocus={makeHandleFocus(isMobile)}
      value={value}
      changeOnWheel={false}
      inputMode="numeric"
      style={{
        width: "100%",
      }}
    />
  );
}
