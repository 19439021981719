import Number from "./Number";

export default function Percentage(props) {
  const currencySimbol = "%";

  function formatter(value) {
    if (!value) return currencySimbol;

    const intlValue = new Intl.NumberFormat("en-UK").format(value);

    return `${currencySimbol} ${intlValue}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  }

  function parser(value) {
    if (!value) return "";

    return parseFloat(value.replace(/\£\s?|(,*)/g, ""));
  }

  const onKeyDown = (event) => {
    if (
      event.key === "Backspace" &&
      (event.target.selectionStart === 1 || event.target.selectionStart === 2)
    ) {
      event.nativeEvent.preventDefault();
    }

    if (event.key === "Backspace" && event.target.value === currencySimbol) {
      event.nativeEvent.preventDefault();
    }

    if (event.key.length === 1 && isNaN(event.key)) {
      event.nativeEvent.preventDefault();
    }
  };

  const defaultValue = props.defaultValue ? props.defaultValue : props.data[props.name];

  return (
    <Number
      {...props}
      controls={false}
      defaultValue={defaultValue ?? 0}
      step={false}
      formatter={formatter}
      parser={parser}
      onKeyDown={onKeyDown}
      maxLength={parseInt(props.maxLength) + 2 || 13}
    />
  );
}
