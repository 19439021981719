import { Typography, Col, Row } from "antd";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import LoanVendorRenderer from "./LoanVendorRenderer";
import { fetchData, fetchStatus } from "./services";
import PhoneNumberInfo from "../../../components/CustomerComponents/PhoneNumberInfo";
import { COLORS } from "../../../core/constants";
import PhoneCallButton from "../../../components/CustomerComponents/PhoneCallButton";
import { FinanceOptionsFormsManager } from "./FinanceOptionsForms/FinanceOptionsFormsManager";
import { useNavigate } from "react-router-dom";

const TIMEOUT_STATUS = 5500;
const TIMEOUT_OFFERS = 3500;

const FinanceOptionsView = () => {
  const { isMobile, applicationData, isSidebarVisible } = useOutletContext();
  const [isLoading, setIsloading] = useState(true);
  const [isLoadOffers, setLoadOffers] = useState(false);
  const [confirmedOffers, setConfirmedOffers] = useState([]);
  const [indicativeOffers, setIndicativeOffers] = useState([]);
  const [isLoadingFinished, setIsloadingFinished] = useState(false);
  const [offersStatus, setOffersStatus] = useState("");
  const [jobDone, setJobDone] = useState(false);
  const refreshStatusTimeout = useRef(null);
  const refreshOffersTimeout = useRef(null);
  const [offersDone, setOffersDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [leadId, setLeadId] = useState(
    searchParams.get("leadId") ?? applicationData?.application?.id
  );
  const [offerToSubmit, setOfferToSubmit] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    if (isLoadOffers) {
      const interval = setInterval(() => {
        loadOffers();
      }, 60000); 
  
      return () => clearInterval(interval);
    }
    
  }, [isLoadOffers]);
  

  const loadOffers = async () => {

    try {
      // const leadId = applicationData?.application?.id;
      const allOffers = await fetchData(leadId);
      setConfirmedOffers(allOffers.data.offers.confirmed ?? []);
      setIndicativeOffers(allOffers.data.offers.indicative ?? []);
      setOffersStatus(allOffers.data.status);
      setIsloading(false);
      if (allOffers.data.done) {
        setOffersDone(true);
      }
    } catch (err) {
      console.log(err);
      navigate('/login');
    }
  };

  const refreshStatus = async () => {
    // const leadId = applicationData.application.id;
    const status = await fetchStatus(leadId);
    setJobDone(status?.data?.done);
    if (status?.data?.done) {
      if (refreshStatusTimeout.value) clearTimeout(refreshStatusTimeout.value);
      if (refreshOffersTimeout.value) clearTimeout(refreshOffersTimeout.value);
      setIsloadingFinished(true);
      loadOffers();
    } else {
      if (!refreshOffersTimeout.value) {
        refreshOffersTimeout.value = setTimeout(() => {
          refreshOffers();
        }, TIMEOUT_OFFERS);
      }
      refreshStatusTimeout.value = setTimeout(() => {
        refreshStatus();
      }, TIMEOUT_STATUS);
      setLoadOffers(true)
    }

  };

  const refreshOffers = async () => {
    if (!isLoadingFinished) {
      await loadOffers();
      refreshOffersTimeout.value = setTimeout(() => {
        refreshOffers();
      }, TIMEOUT_OFFERS);
    }
  };

  useEffect(() => {
    refreshStatus();
    loadOffers();

    return () => {
      if (refreshStatusTimeout.value) clearTimeout(refreshStatusTimeout.value);
      if (refreshOffersTimeout.value) clearTimeout(refreshOffersTimeout.value);
    };
  }, []);

  return (
    <Col className="dashboard-layout">
      {leadId && jobDone && (
        <FinanceOptionsFormsManager
          leadId={leadId}
          isMobile={isMobile}
          offerToSubmit={offerToSubmit}
          setOfferToSubmit={setOfferToSubmit}
          vendor={offerToSubmit?.vendor || ""}
          businessAgeNumericApprox={applicationData.business_age_numeric_approx}
          applicationData={applicationData}
        />
      )}
      <Row
        align={"middle"}
        justify={"space-between"}
        style={{
          marginTop: isMobile ? "5px" : "20px",
          marginBottom: isMobile ? "34px" : "74px",
          alignItems: "center",
        }}
      >
        <Col
          style={{
            paddingLeft: isMobile && "64px",
            opacity: !isSidebarVisible ? "0" : "1",
            transition: "opacity 100ms ease",
          }}
        >
          <Typography.Title
            style={{
              fontSize: isMobile ? "24px" : "30px",
              margin: 0,
            }}
          >
            Finance options
          </Typography.Title>
          <Typography.Title
            style={{
              fontSize: isMobile ? "16px" : "20px",
              marginTop: "8px",
            }}
          >
            {applicationData.application.company.name}
          </Typography.Title>
        </Col>

        {!isMobile ? (
          <PhoneNumberInfo isMobile={false} numberMarginTop={"-40px"} />
        ) : (
          <div
            style={{
              opacity: !isSidebarVisible ? "0" : "1",
              transition: "opacity 100ms ease",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: `2px solid ${COLORS.red}`,
              borderRadius: "6px",
              height: "38px",
              width: "38px",
              position: "relative",
              top: "-1px",
            }}
          >
            <a
              href={isMobile ? "tel:02080885001" : "javascript:void(0)"}
              style={{
                color: "inherit",
                textDecoration: "none",
                height: "28px",
              }}
            >
              <svg
                stroke={COLORS.red}
                fill={COLORS.red}
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1.75em"
                width="1.75em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57a1.02 1.02 0 00-1.02.24l-2.2 2.2a15.045 15.045 0 01-6.59-6.59l2.2-2.21a.96.96 0 00.25-1A11.36 11.36 0 018.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2a9 9 0 00-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z"></path>
              </svg>
            </a>
          </div>
        )}
      </Row>

      {(isLoading || !isLoadingFinished) && (
        <Typography.Title
          style={{
            fontSize: isMobile ? "18px" : "24px",
            marginBottom: isMobile ? "16px" : "24px",
          }}
        >
          Retrieving{" "}
          {confirmedOffers.length === 0 && indicativeOffers.length === 0
            ? ""
            : "more"}{" "}
          offers...
        </Typography.Title>
      )}

      {jobDone &&
        confirmedOffers.length === 0 &&
        offersStatus === "declined" && (
          <Row justify={"center"}>
            <Col
              span="24"
              style={{
                background: isMobile ? "#f5f5f5" : COLORS.offwhite,
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                <span style={{ marginBottom: "24px", display: "block" }}>
                  Thank you. We’ve successfully received your loan application.
                </span>
                <span style={{ marginBottom: "24px", display: "block" }}>
                  We will contact you soon with the available loan options for
                  you.
                </span>
                <span>
                  Please contact our customer support in case you need anything
                  else at this stage.
                </span>
              </p>
              <Row
                align={"middle"}
                justify={"center"}
                style={{ marginBottom: "20px" }}
              >
                <Col style={{ marginTop: "1em" }}>
                  <PhoneCallButton isMobile={true} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      {jobDone && confirmedOffers.length === 0 && offersStatus === "error" && (
        <Row justify={"center"}>
          <Col
            span="24"
            style={{
              background: isMobile ? "#f5f5f5" : COLORS.offwhite,
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <p
              style={{ textAlign: "center", fontSize: "18px", fontWeight: 500 }}
            >
              Something went wrong with your application. Please contact
              customer support.
            </p>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "20px" }}
            >
              <Col style={{ marginTop: "1em" }}>
                <PhoneCallButton isMobile={isMobile} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {isLoading ? (
        [...Array(3)].map((_, index) => (
          <LoanVendorRenderer
            directorName={applicationData.application.director_name}
            key={index}
            isLoading={isLoading}
            isMobile={isMobile}
            vendorDetails={{
              usp: [],
              notes: [],
              mainOffers: [{}, {}],
            }}
            variation={"fully-approved"}
            leadId={leadId}
          />
        ))
      ) : (
        <>
          {confirmedOffers.map((offer, index) => (
            <LoanVendorRenderer
              directorName={applicationData.application.director_name}
              key={index}
              isMobile={isMobile}
              variation={"fully-approved"}
              highlight={offer.highlight}
              bannerType={
                offer.ribbon === "confirmed" ? "GUARANTEED" : "INSTANT"
              }
              offerType="confirmed"
              vendorDetails={offer}
              leadId={leadId}
              companyId={applicationData.application.company.id}
              onAccept={(o) =>
                setOfferToSubmit({
                  ...o,
                  documents_required: offer.documents_required,
                  vendor: offer.vendor,
                })
              }
              jobDone={jobDone}
              focusedOfferId={offerToSubmit?.offerId}
            />
          ))}
          {indicativeOffers.map((offer, index) => (
            <LoanVendorRenderer
              directorName={applicationData.application.director_name}
              key={index}
              isMobile={isMobile}
              variation={"fully-approved"}
              highlight={offer.highlight}
              bannerType={
                offer.ribbon === "confirmed" ? "GUARANTEED" : "INSTANT"
              }
              offerType="indicative"
              vendorDetails={offer}
              leadId={leadId}
              companyId={applicationData.application.company.id}
              onAccept={(o) =>
                setOfferToSubmit({
                  ...o,
                  documents_required: offer.documents_required,
                  vendor: offer.vendor,
                })
              }
              jobDone={jobDone}
              focusedOfferId={offerToSubmit?.offerId}
            />
          ))}
          {!isLoadingFinished && (
            <LoanVendorRenderer
              directorName={applicationData.application.director_name}
              isLoading={true}
              isMobile={isMobile}
              vendorDetails={{
                usp: [],
                notes: [],
                mainOffers: [{}, {}],
              }}
              variation={"fully-approved"}
              leadId={leadId}
            />
          )}
        </>
      )}
      {(confirmedOffers.length > 0 || indicativeOffers.length > 0) && (
        <footer>
          <Typography.Title style={{ fontSize: "18px" }}>
            Additional Information:
          </Typography.Title>
          <Typography.Title style={{ fontSize: "16px" }}>
            Pre-Approved Loan:
          </Typography.Title>
          <Typography.Text style={{ fontSize: "14px" }}>
            This loan is pre-approved for you, which means you’ll get this loan
            if all the details you’ve given us are correct. The loan offer is
            however still subject to affordability, fraud, anti-money laundering
            and other final checks by the lender.
          </Typography.Text>
          <Typography.Title style={{ fontSize: "16px" }}>
            Indicative offer:
          </Typography.Title>
          <Typography.Text style={{ fontSize: "14px" }}>
            These are the loan offers which are based on a preliminary
            assessment. The provided rates are for illustrative purposes only.
            The actual rates and offered amount may differ, subject to the final
            assessment by our lenders.
          </Typography.Text>
        </footer>
      )}

      <div style={{ minHeight: isMobile ? "20px" : "38px" }}></div>
    </Col>
  );
};

export default FinanceOptionsView;
