import { Input, Col, Row } from "antd";
import { useState, useEffect } from "react";
import Label from "./Label";
import { makeHandleFocus } from "../../core/utils";

export default function AddressManual({
  name,
  setValue,
  data: defaultData,
  isMobile,
  ...props
}) {
  const [data, setData] = useState({});
  const gap = "clamp(12px, 1.48vh, 16px)";

  useEffect(() => {
    // if (!loaded) {
    let obj = {};
    const keys = {
      postcode_manual: "postcode",
      residential_postcode_manual: "residential_postcode",
    };
    const dataKey = keys[name];
    obj = JSON.parse(defaultData[dataKey]);
    obj = Object.assign(defaultData[name] ?? {}, obj);

    setData({
      ...obj,
      postcode: obj.postcode || defaultData.manual_postcode,
    });
    // }
  }, []);

  useEffect(() => {
    if (data.address) {
      delete data.address;
    }
    setValue(data);
  }, [data]);

  return (
    <Row>
      <Col span="24" style={{ marginBottom: props.id === "postcode_manual" ? '0' : gap }}>
        <Row wrap={false} align={"bottom"} justify={"end"}>
          <div style={{ width: "50%", marginRight: gap }}>
            <Label label="Postcode" />
            <Input
              value={data.postcode ?? ""}
              className="ant-input-small"
              onChange={(e) => {
                setData(
                  Object.assign({ ...data }, { postcode: e.target.value })
                );
              }}
              onKeyPress={props.onKeyPress}
              onFocus={makeHandleFocus(isMobile)}
            />
          </div>
          {props.id === "postcode_manual" && (
            <div style={{ width: "50%" }}>
              <Label label="Building number/name" />
              <Input
                value={data.house_number ?? ""}
                className="ant-input-small"
                onChange={(e) => {
                  setData(
                    Object.assign({ ...data }, { house_number: e.target.value })
                  );
                }}
                onKeyPress={props.onKeyPress}
                onFocus={makeHandleFocus(isMobile)}
              />
            </div>
          )}
          {props.id === "residential_postcode_manual" && (
            <Input
              value={data.house_number ?? ""}
              placeholder="House Number"
              className="ant-input-small"
              style={{ width: "50%" }}
              onChange={(e) => {
                setData(
                  Object.assign({ ...data }, { house_number: e.target.value })
                );
              }}
              onKeyPress={props.onKeyPress}
              onFocus={makeHandleFocus(isMobile)}
            />
          )}
        </Row>
      </Col>
      <Col span="24" style={{ marginBottom: gap }}>
        {props.id === "residential_postcode_manual" && (
          <Row wrap={false} align={"bottom"} justify={"end"}>
            <Input
              value={data.house_name ?? ""}
              style={{ marginRight: gap }}
              placeholder="House Name"
              className="ant-input-small"
              onChange={(e) => {
                setData(
                  Object.assign({ ...data }, { house_name: e.target.value })
                );
              }}
              onKeyPress={props.onKeyPress}
            />
            <Input
              value={data.flat_number ?? ""}
              placeholder="Flat Number"
              className="ant-input-small"
              onChange={(e) => {
                setData(
                  Object.assign({ ...data }, { flat_number: e.target.value })
                );
              }}
              onKeyPress={props.onKeyPress}
            />
          </Row>
        )}
      </Col>
      <Col span="24" style={{ marginBottom: gap }}>
        <Input
          value={data.street ?? ""}
          placeholder="Street"
          className="ant-input-small"
          onChange={(e) => {
            setData(Object.assign({ ...data }, { street: e.target.value }));
          }}
          onKeyPress={props.onKeyPress}
        />
      </Col>
      <Col span="24" style={{ marginBottom: gap }}>
        <Input
          value={data.city ?? ""}
          placeholder="City"
          className="ant-input-small"
          onChange={(e) => {
            setData(Object.assign({ ...data }, { city: e.target.value }));
          }}
          onKeyPress={props.onKeyPress}
        />
      </Col>
      <Col span="24">
        <Input
          value={data.county ?? ""}
          placeholder="County"
          className="ant-input-small"
          onChange={(e) => {
            setData(Object.assign({ ...data }, { county: e.target.value }));
          }}
          onKeyPress={props.onKeyPress}
        />
      </Col>
    </Row>
  );
}
