import { headers, URLS } from "../../core/constants";
export const otpSend = async (mobile) => {
  const response = await fetch(`${URLS.otp_send}`, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    // credentials: "include",
    method: "POST",
    body: JSON.stringify({
      mobile: String(mobile),
    }),
  });
  const data = await response.json();

  return data?.data?.token;
};

export const otpResend = async (mobile, token) => {
  const response = await fetch(URLS.otp_send, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    // credentials: "include",
    method: "POST",
    body: JSON.stringify({
      mobile: String(mobile),
      token,
    }),
  });
  const data = await response.json();

  return data?.data?.token;
};

export const otpVerify = async (mobile, token, otp) => {
  const response = await fetch(`${URLS.otp_verify}`, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    method: "POST",
    // credentials: 'include',
    body: JSON.stringify({
      mobile: mobile,
      token: token,
      otp: otp,
    }),
  });
  const data = await response.json();

  return data.data.valid;
};
