export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.66672 11.6668C4.66672 19.1802 12.9734 25.2002 13.3234 25.4452C13.521 25.5858 13.7575 25.6614 14 25.6614C14.2426 25.6614 14.4791 25.5858 14.6767 25.4452C15.0267 25.2002 23.3334 19.1802 23.3334 11.6668C23.3334 9.19148 22.3501 6.81751 20.5997 5.06717C18.8494 3.31683 16.4754 2.3335 14 2.3335C11.5247 2.3335 9.15073 3.31683 7.40039 5.06717C5.65005 6.81751 4.66672 9.19148 4.66672 11.6668ZM21 11.6668C21 16.8468 15.8784 21.4902 14 23.0185C12.1217 21.4902 7.00005 16.8468 7.00005 11.6668C7.00005 9.81031 7.73755 8.02984 9.0503 6.71708C10.3631 5.40433 12.1435 4.66683 14 4.66683C15.8566 4.66683 17.637 5.40433 18.9498 6.71708C20.2626 8.02984 21 9.81031 21 11.6668Z"
      fill="currentColor"
    />
    <path
      d="M14 14.0002C15.2887 14.0002 16.3334 12.9555 16.3334 11.6668C16.3334 10.3782 15.2887 9.3335 14 9.3335C12.7114 9.3335 11.6667 10.3782 11.6667 11.6668C11.6667 12.9555 12.7114 14.0002 14 14.0002Z"
      fill="currentColor"
    />
  </svg>
);
