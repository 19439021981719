export default ({ props }) => {
  return (
    <svg
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="019-message">
        <path
          id="Vector"
          d="M32.1663 5H8.83301C5.99967 5 3.83301 7.16667 3.83301 10V30C3.83301 32.8333 5.99967 35 8.83301 35H32.1663C34.9997 35 37.1663 32.8333 37.1663 30V10C37.1663 7.16667 34.9997 5 32.1663 5ZM32.1663 8.33333C32.4997 8.33333 32.6663 8.5 32.833 8.5L20.4997 21L7.99967 8.5C8.33301 8.5 8.49967 8.33333 8.83301 8.33333H32.1663ZM33.833 30C33.833 31 33.1663 31.6667 32.1663 31.6667H8.83301C7.83301 31.6667 7.16634 31 7.16634 30V12.3333L19.333 24.5C19.9997 25.1667 20.9997 25.1667 21.6663 24.5L33.833 12.3333V30Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
