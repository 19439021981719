import { Typography, Col, Card, Row, Button, Input } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import Label from "../../components/CustomerComponents/Label";
import EditIcon from "../../components/icons/Edit";
import SuffixedInput from "../../components/CustomerComponents/SuffixedInput";
import { useRef, useState, useEffect} from "react";
import {
  formatCurrency,
  formatDate,
  getCardReceipts,
  getInvoiceReceipts,
  getBusinessAge,
} from "../../core/utils";
import CustomerFormPopup from "../../components/CustomerComponents/CustomerFormPopup";
import { updateLead } from "../../core/services";

const DashboardView = () => {
  const { data, isMobile, applicationData, isSidebarVisible, setApplicationData } =
    useOutletContext();
  const navigate = useNavigate();
  const [popup, setPop] = useState(false);
  const [customerForm, setCustomerForm] = useState({
    startFrom: null,
    finishAt: null,
  });
  useEffect(() => {
    document.title = "LoanTube: Business Details"
  }, [])
  const handleClick = (startFrom, finishAt) => {
    setCustomerForm({
      startFrom,
      finishAt,
    });
    setPop(true);
  };

  const onFinish = async (data) => {
    const lead = await updateLead(applicationData.application.id, {
      ...data,
      business_address: data?.postcode,
      business_postcode: data?.manual_postcode,
      postcode: undefined,
      manual_postcode: undefined,
    }, applicationData);
    setApplicationData(lead.data);
    setPop(false);
  };

  return (
    <Col className="dashboard-layout">
      <CustomerFormPopup
        onClose={() => setPop(false)}
        onFinish={onFinish}
        startFrom={customerForm.startFrom}
        finishAt={customerForm.finishAt}
        visible={popup}
        isMobile={isMobile}
      />
      <Typography.Title
        style={{
          fontSize: isMobile ? "24px" : "30px",
          marginBottom: isMobile ? "24px" : "74px",
          marginTop: isMobile && "14px",
          paddingLeft: isMobile && "64px",
          opacity: !isSidebarVisible ? "0" : "1",
          transition: "opacity 100ms ease",
        }}
      >
        Business details
      </Typography.Title>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Business name" disabled isMobile />
          <Input
            className="antd-inputd"
            style={{
              height: "clamp(40px, 5.55vh, 60px)",
            }}
            disabled
            value={applicationData?.application?.business_name}
          />
        </Col>
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Business type" disabled isMobile />
          <Input
            className="antd-inputd"
            style={{ height: "clamp(40px, 5.55vh, 60px)" }}
            disabled
            value="Limited Company"
          />
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
        wrap={isMobile}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Trading since" disabled isMobile />
          <SuffixedInput
            handleClick={() => handleClick("business_age", "mobile_number")}
            disabled
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            value={getBusinessAge(applicationData?.application?.business_age)}
          />
        </Col>
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Annual Revenue" disabled isMobile />
          <SuffixedInput
            handleClick={() => handleClick("revenue", "accept_card_payments")}
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            value={formatCurrency(applicationData?.application?.revenue)}
          />
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Card receipts" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            handleClick={() => handleClick("accept_card_payments", "invoices")}
            value={getCardReceipts(
              applicationData?.application?.accept_card_payments,
              applicationData?.application?.card_amount,
              applicationData?.application?.revenue
            )}
          />
        </Col>
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Invoice receipts" disabled isMobile />
          <SuffixedInput
            handleClick={() => handleClick("invoices", "postcode")}
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            value={getInvoiceReceipts(applicationData?.application?.invoices)}
          />
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{
          marginBottom: isMobile ? "14px" : "28px",
          gap: isMobile ? "14px" : "30px",
        }}
      >
        <Col style={{ flex: !isMobile && 1, width: isMobile && "100%" }}>
          <Label label="Business address" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
            handleClick={() =>
              handleClick("postcode", ["director_name", "director_name_manual"])
            }
            value={applicationData?.application?.company?.address}
          />
        </Col>
        <Col style={{ flex: 1, opacity: 0, visibility: "hidden" }}>
          <Label label="Business address" disabled isMobile />
          <SuffixedInput
            inputStyle={{ height: "clamp(40px, 5.55vh, 60px)" }}
            Suffix={EditIcon}
            disabled
          />
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardView;
