import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
// import './fonts/Montserrat/Montserrat-SemiBold.woff2';
// import './fonts/Montserrat/Montserrat-Regular.woff2';
// import './fonts/Montserrat/Montserrat-Medium.woff2';
import "./shared.css";
import reportWebVitals from "./reportWebVitals";
import Customer from "./routes/Customer/Customer";
import Dashboard from "./routes/Dashboard/Dashboard";
import FormSubmitSuccess from "./routes/Dashboard/FormSubmitSuccess";

import {
  BrowserRouter,
  Route,
  Routes,
  Redirect,
  Switch,
} from "react-router-dom";
import DashboardView from "./routes/Dashboard/DashboardView";
import BusinessDetailsView from "./routes/Dashboard/BusinessDetailsView";
import YourDetailsView from "./routes/Dashboard/YourDetailsView";
import ContactUsView from "./routes/Dashboard/ContactUsView";
import YourDocumentsView from "./routes/Dashboard/YourDocumentsView/View";
import LoginView from "./routes/Dashboard/LoginView/View";
import FinanceOptionsView from "./routes/Dashboard/FinanceOptionsView";
import Embed from "./routes/Embed/Embed";
import SinglePageForm from "./routes/SinglePageForm";
import SinglePageFormPrivate from "./routes/SinglePageForm/SinglePageFormPrivate";
import SinglePageFormAuthView from "./routes/SinglePageForm/SinglePageFormAuthView";
import ShortLinkRouteHandler from "./routes/SinglePageForm/ShortLinkRouteHandler";
import HyperDX from '@hyperdx/browser';

//setup hyperdx
//check domain name
const domain = window.location.hostname;
const domainName = 'bs.loantube.com';
if (domain === domainName) {
  HyperDX.init({
    apiKey: 'f72a60c6-66a6-41c9-a22f-e5dead3b0541',
    service: 'ltb-customer',
    tracePropagationTargets: [/bs.loantube.com\/api/i], // Set to link traces from frontend to backend requests
    consoleCapture: true, // Capture console logs (default false)
    advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

document.addEventListener("DOMContentLoaded", function () {
  setTimeout(function () {
    const mobileInputElement = document.querySelector('[name="mobile"]');
    if (mobileInputElement && window.location.pathname === "/verify") {
      mobileInputElement.focus();
    }
  }, 100);
});
function App() {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Customer />}></Route>
        <Route path="/embed/:sourceId?" element={<Embed />}></Route>
        <Route path="login" element={<LoginView />}></Route>
        <Route path="single-view-form" element={<SinglePageForm />}></Route>
        <Route
          path="single-view-form-agent"
          element={<SinglePageFormPrivate />}
        ></Route>
        <Route
          path="single-page-form-auth-view"
          element={<SinglePageFormAuthView />}
        ></Route>
        <Route path="/application" element={<SinglePageForm />}></Route>
        <Route path="/new-lead" element={<SinglePageFormPrivate />}></Route>
        <Route path="/affiliate" element={<SinglePageFormPrivate />}></Route>
        <Route path="/verify" element={<SinglePageFormAuthView />}></Route>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<DashboardView />}></Route>
          {/* <Route path="success" element={<FormSubmitSuccess />}></Route> */}
          <Route path="success" element={<FinanceOptionsView />}></Route>
          <Route
            path="business-details"
            element={<BusinessDetailsView />}
          ></Route>
          <Route
            path="finance-options"
            element={<FinanceOptionsView />}
          ></Route>
          <Route path="your-details" element={<YourDetailsView />}></Route>
          <Route path="your-documents" element={<YourDocumentsView />}></Route>
          <Route path="contact-us" element={<ContactUsView />}></Route>
        </Route>
        <Route path="/:shortLinkId" element={<ShortLinkRouteHandler />}></Route>
        <Route path="/internal" element={<Dashboard />}>
          <Route path="offers" element={<FinanceOptionsView />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
